import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import useShowGuideStorage from "./hooks/useShowGuideStorage";

import * as S from "./styles"

const GuideModal = ({
  enabled = true,
  autoShow = true,
  isClosable = false,
  storageKey = null,
  guideContents,
  expireInDays,
  previousButtonText = I18n.t("guide_modal.previous_button"),
  finishButtonText = I18n.t("guide_modal.next_button"),
  finishCallback = () => {},
}) => {
  const { isGuideAllowed, markAsViewed } = useShowGuideStorage(storageKey, expireInDays);

  const [showModal, setShowModal] = useState(false);
  const [currentSliderPos, setCurrentSliderPos] = useState(1);

  const selectedContent = guideContents[currentSliderPos - 1];
  const contentCount = guideContents.length;
  const isPreviousButtonDisabled = !isClosable && currentSliderPos === 1;

  useEffect(() => {
    setShowModal(isGuideAllowed && autoShow)
  }, [isGuideAllowed, autoShow]);

  const handleNext = async () => {
    if (currentSliderPos < contentCount) {
      return setCurrentSliderPos(currentSliderPos + 1);
    }

    await new Promise((resolve) => {
      markAsViewed();
      resolve();
    });

    setShowModal(false);
    finishCallback();
  }

  const handlePrevious = () => {
    if (currentSliderPos > 1) {
      return setCurrentSliderPos(currentSliderPos  - 1);
    }

    setShowModal(false);
  }

  const onHideModalCallback = () => {
    if (isClosable) {
      setShowModal(false);
    }
  }

  if (!enabled) {
    return <></>;
  }

  return (
    <Modal show={showModal} onHide={onHideModalCallback} centered size="lg">
      {isClosable ? <Modal.Header closeButton={isClosable} /> : null}

      <Modal.Body className="text-center">
        {selectedContent.media.type === "image" ? (
          <S.MainImage src={selectedContent.media.url} />
        ) : (
          <iframe
            width="100%"
            height="370"
            src={selectedContent.media.url}
            title="YouTube video player"
            allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;web-share"
            allowFullScreen
          />
        )}

        <div className="fs-3 fw-bolder mt-3 mb-2">{selectedContent.title}</div>
        <div dangerouslySetInnerHTML={{ __html: selectedContent.content }} />

        {contentCount > 1 && (
          <div className="mt-3 fs-3 text-primary fw-medium">
            Slide {currentSliderPos} de {contentCount}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        {!isPreviousButtonDisabled ? (
          <Button variant="light" className="me-auto" onClick={handlePrevious}>
            {currentSliderPos === 0 ? previousButtonText : I18n.t("guide_modal.back_button")}
          </Button>
        ) : null}

        <Button onClick={handleNext}>
          {currentSliderPos === contentCount ? finishButtonText : (
            <>
              {I18n.t("guide_modal.learn_more")}
              <i className="ti ti-arrow-right icon ms-1 me-0" />
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

GuideModal.propTypes = {
  storageKey: PropTypes.string,
  guideContents: PropTypes.arrayOf(Object).isRequired,
  previousButtonText: PropTypes.string,
  finishButtonText: PropTypes.string,
  autoShow: PropTypes.bool,
  isClosable: PropTypes.bool,
  expireInDays: PropTypes.number,
  finishCallback: PropTypes.func,
};

export default GuideModal;
