import React from "react";

import sweetAlert from "sweetalert";
import axios from "@/packs/axios";

const BackLimitedVersion = ({allowToBack}) => {
  const handleRevertAccountToWhatsApp = () => {
    if (!allowToBack) return;

    sweetAlert({
      title: I18n.t("whatsapp.back_limited_version.text_01"),
      text: I18n.t("whatsapp.back_limited_version.text_02"),
      icon: "warning",
      dangerMode: false,
      buttons: {
        cancel: { text: I18n.t("whatsapp.back_limited_version.text_03"), visible: true },
        confirm: { text: I18n.t("whatsapp.back_limited_version.text_04"), visible: true }
      }
    })
      .then(async (value) => {
        if (!value) return false;

        return await axios.post("/whatsapp-account/return-to-limited-account");
      })
      .then((response) => {
        if (response === false) return;

        if (response.status !== 200) {
          throw new Error('Request failed!');
        }

        sweetAlert({
          text: I18n.t("whatsapp.back_limited_version.text_05"),
          icon: "success",
          buttons: false,
        });

        setTimeout(() => { window.location = "/" }, 3000);
      })
      .catch(() => {
        sweetAlert(I18n.t("whatsapp.back_limited_version.text_07"), I18n.t("whatsapp.back_limited_version.text_06"), "error");
      });
  };

  return (
    <button type="button" className="btn btn-light" onClick={ handleRevertAccountToWhatsApp }>
      { I18n.t("whatsapp.back_limited_version.text_07") }
    </button>
  )
};

export default BackLimitedVersion;
