import React, { useCallback, useRef } from "react";

import ScriptCardBlock from "../ScriptCardBlock";
import ScriptCardFooter from "../ScriptCardFooter";
import PermissionsGate from '@/components/permissions/PermissionsGate';

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';
import { can } from "@/permissions-provider";

import swal from "sweetalert";
import parse from "html-react-parser";

import { captureException } from "@sentry/react";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const ScriptIntegrations = () => {
  const hasPermission = can(PERMISSION_SCOPE);

  const saveData = useContextSelector(ScriptContext, ({ saveData }) => saveData);
  const { companyIntegrations } = useContextSelector(ScriptContext, ({ state }) => state.additionalData);

  const cardBody = {
    title: I18n.t("views.manage_flows.script_editor.integrations"),
    icon: `ti ti-share`,
    description: I18n.t("views.manage_flows.script_editor.integrations_description"),
  };

  const cardFooter = {
    title: I18n.t("views.manage_flows.script_editor.get_the_leads_into_your_system"),
    description: I18n.t("views.manage_flows.script_editor.get_the_leads_into_your_system_description"),
  };

  const handleClickSaveCurentData = useCallback(async () => {
    if (!hasPermission) return;

    MixpanelService.track('script_access_integrations');

    const actionCode = await swal({
      title: "",
      text: I18n.t("views.manage_flows.script_editor.we_recommend_saving_your_stream"),
      icon: "warning",
      buttons: {
        cancel: false,
        redirect: {
          text: I18n.t("views.manage_flows.script_editor.exit_without_saving"),
          className: "btn btn-secondary",
          value: "REDIRECT",
        },
        saveAndRedirect: {
          text: I18n.t("views.manage_flows.script_editor.save_stream_before_accessing"),
          className: "btn btn-primary",
          value: "SAVE_AND_REDIRECT",
        },
      },
    });

    const redirectToIntegrations = (timeout = 2000) => {
      setTimeout(() => window.location.href = '/integrations', timeout);
    }

    switch (actionCode) {
      case 'REDIRECT':
        redirectToIntegrations(200);
      break;
      case 'SAVE_AND_REDIRECT':
        saveData().then(() => {
          swal("", I18n.t("views.manage_flows.script_editor.stream_updated_successfully"), "success");
          redirectToIntegrations();
        }).catch((error) => {
          captureException(error);
          swal("", I18n.t("views.manage_flows.script_editor.error_during_stream_update"), "error");
        });
      break;
    }
  }, [saveData]);

  return (
    <>
      <ScriptCardBlock
        title={cardBody.title}
        icon={cardBody.icon}
        description={cardBody.description}
      >
        <div className="row row-cards row-deck">
          <div className="col-12">
            <div className="card">
              <div className="card-header flex-md-row justify-content-md-between flex-column overflow-hidden">
                <h3 className="card-title">{I18n.t("views.manage_flows.script_editor.active_integrations")}</h3>
                <div className="card-options">
                  <PermissionsGate scope={PERMISSION_SCOPE} usePopover useLockIcon iconExtraSmall iconCentered>
                    <button
                      className="btn btn-primary btn-sm mt-2 mt-md-0"
                      onClick={handleClickSaveCurentData}
                    >
                      {I18n.t("views.manage_flows.script_editor.add_or_edit_integrations")}
                    </button>
                  </PermissionsGate>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-hover table-outline table-vcenter text-nowrap card-table">
                  <thead>
                    {companyIntegrations.length == 0 ? (
                      <tr>
                        <td className="text-center">
                          {I18n.t("views.manage_flows.script_editor.no_active_integration")}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <th>{I18n.t("views.manage_flows.script_editor.model")}</th>
                        <th>{I18n.t("views.manage_flows.script_editor.events")}</th>
                        <th>{I18n.t("views.manage_flows.script_editor.created_in")}</th>
                      </tr>
                    )}
                  </thead>

                  <tbody>
                    {companyIntegrations.map((integration) => {
                      return (
                        <tr key={integration.id}>
                          <td>
                            {integration.integration_blueprint.friendly_name}
                          </td>
                          <td>{integration.allowed_events.join(", ")}</td>
                          <td>{integration.created_at_in_words}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ScriptCardBlock>

      <ScriptCardFooter title={cardFooter.title}>
        <p className="mb-0 tracking-wide">{parse(cardFooter.description)}</p>
      </ScriptCardFooter>
    </>
  );
};

export default ScriptIntegrations;
