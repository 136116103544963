import React from "react";

import HireFeatureButton from "./HireFeatureButton";

import parse from "html-react-parser";

const TrialEndedAlert = ({ onDelete }) => {
  return (
    <div className="alert alert-danger mb-3">
      <div className="d-flex align-items-center">
        <div>
          <i className="ti ti-info-circle icon alert-icon fs-1" />
        </div>

        <div>
          <div className="alert-title mb-3">
            {I18n.t("views.manage_flows.script_editor.ia_interaction.alerts.title")}
            <span className="badge bg-danger-lt ms-2">
              {I18n.t("views.manage_flows.script_editor.ia_interaction.alerts.trial_ended.status")}
            </span>
          </div>

          <p className="text-muted">
            {parse(I18n.t("views.manage_flows.script_editor.ia_interaction.alerts.trial_ended.description_html"))}
          </p>

          <div className="btn-list">
            <HireFeatureButton />

            <button type="button" className="btn btn-outline-danger ms-2" onClick={onDelete}>
              <i className="ti ti-trash icon" />
              {I18n.t("views.manage_flows.script_editor.ia_interaction.alerts.btn_remove_block")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrialEndedAlert;
