import { useState } from "react";

import { useAssistantContext } from "@/contexts/AssistantContext";
import { useHistory } from "react-router-dom";

import axios from "@/packs/axios";

const useOnSubmitForm = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { assistant, setAssistant } = useAssistantContext();

  const onSubmit = async (data, setError) => {
    setIsLoading(true);

    try {
      const method = !!assistant ? "PUT" : "POST";
      const url = !!assistant ? `/ai/assistants/${assistant.id}` : "/ai/assistants";

      const response = await axios({ method, url, data });
      const newAssistant = response.data.assistant;

      setAssistant(newAssistant);
      history.push(`/ai/leadster/assistants/${newAssistant.id}/content`);
    } catch (err) {
      setIsLoading(false);

      if (_.get(err, "response.status") === 422) {
        const responseErrors = _.get(err.response, "data.errors", {});
        Object.entries(responseErrors).forEach(([fieldName, [firstErrorMessage]]) => {
          setError(fieldName, { type: "custom", message: firstErrorMessage });
        });

        return toastr.error(I18n.t("request_feedback_alert.validation_error.message"));
      }

      return toastr.error(I18n.t("request_feedback_alert.error.message"));
    }
  };

  return { isLoading, onSubmit };
};

export default useOnSubmitForm;
