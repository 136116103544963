import React from "react";
import PropTypes from "prop-types";

import useCurrentUserStore from "@/store/currentUserStore";

import axios from "@/packs/axios"
import swal from "sweetalert";
import parse from "html-react-parser";

const StartTrialAlert = ({ onDelete }) => {
  const setCurrentUser = useCurrentUserStore((state) => state.setCurrentUser);
  const { freeTrialDays } = useCurrentUserStore((state) => state.currentUser.plugins.calendarSchedule);

  const startFreeTrial = async () => {
    const willStart = await swal({
      title: I18n.t("views.manage_flows.scheduler_block.trial.start_free"),
      text: I18n.t("views.manage_flows.scheduler_block.trial.on_start_free_trial_count", { freeTrialDays: freeTrialDays }),
      icon: 'info',
      buttons: {
        cancel: { text: `${I18n.t("shared.actions.cancel")}`, visible: true },
        confirm: { text: `${I18n.t("views.manage_flows.scheduler_block.trial.start")}`, visible: true },
      },
    });

    if (willStart) {
      axios.post('/schedules/start-free-trial')
        .then(async () => {
          try {
            // TODO: Transformar refresh do store em hook
            const abilitiesResponse = await axios.get('/profile/abilities');
            setCurrentUser(abilitiesResponse.data);

            swal(
              I18n.t("views.manage_flows.scheduler_block.trial_success.title"),
              I18n.t("views.manage_flows.scheduler_block.trial_success.description"),
              "success"
            );
          } catch (error) {
            throw error;
          }
        }).catch(() => {
          swal(
            I18n.t("views.manage_flows.scheduler_block.trial_error.title"),
            I18n.t("views.manage_flows.scheduler_block.trial_error.description"),
            "error"
          );
        });
    }
  }

  return (
    <div className="alert alert-primary mb-3">
      <div className="d-flex align-items-center">
        <div>
          <i className="ti ti-info-circle icon alert-icon fs-1" />
        </div>

        <div>
        <div className="alert-title mb-2">
          {I18n.t("views.manage_flows.scheduler_block.meeting_scheduling")}
            <span className="badge bg-primary-lt ms-2">
              {I18n.t("views.manage_flows.scheduler_block.free_trial_available")}
            </span>
          </div>

          <p className="text-muted mb-2">
            {parse(I18n.t(
              "views.manage_flows.scheduler_block.start_trial_feature_information_html",
              { trial_days: freeTrialDays }
            ))}

            <a
              className='d-xl-block'
              href={I18n.t("shared.helpscout.google_calendar_scheduling")}
              target="_blank"
            >
              {I18n.t("views.manage_flows.scheduler_block.learn_more")} {" "}
              <i className="ti ti-external-link"></i>
            </a>
          </p>

          <div className="btn-list">
            <button type='button' className="btn btn-primary" onClick={startFreeTrial}>
              <i className="ti ti-rocket icon"></i>
              {I18n.t("views.manage_flows.scheduler_block.start_free_trial")}
            </button>

            <button type='button' className="btn btn-outline-danger" onClick={onDelete}>
              <i className="ti ti-trash icon"></i>
              {I18n.t("views.manage_flows.scheduler_block.remove_block")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

StartTrialAlert.propTypes = {
  freeTrialDays: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default StartTrialAlert;
