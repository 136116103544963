import React, { useState } from "react";

import PermissionsGate from '@/components/permissions/PermissionsGate';

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';
import { can } from "@/permissions-provider";

import { Tab } from "./styles";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const DesktopAndMobileTabs = ({
  sameLabel,
  diffLabel,
  label,
  customMobileStateKey,
  desktopComponent,
  mobileComponent,
  cardBodyClass
}) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);
  const hasCustomMobile = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion[customMobileStateKey]);

  const [customDesktopTabActive, setcustomDesktopTabActive] = useState(true);

  const setHasCustomMobile = (isCustomMobile) => {
    if (!hasPermission) return;

    dispatch({
      type: "SET_CUSTOM_MOBILE_BY_KEY",
      payload: { key: customMobileStateKey, value: isCustomMobile },
    });
  };

  return (
    <>
      <div className="row g-0">
        <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon usePopover>
          <Tab
            className="col-md-6"
            active={!hasCustomMobile}
            borderLeft={false}
            onClick={() => setHasCustomMobile(false)}
          >
            <div className="m-3 d-flex justify-content-sm-center">
              <label className="form-check form-switch mb-0">
                <input className="form-check-input cursor-pointer"
                  type="radio"
                  checked={!hasCustomMobile}
                  onChange={() => {}}
                />
                <span className="form-check-label cursor-pointer d-flex align-items-center">
                  <i className="ti ti-device-desktop"></i>
                  <i className="ti ti-equal mx-1"></i>
                  <i className="ti ti-device-mobile me-1"></i>
                  {sameLabel}
                </span>
              </label>
            </div>
          </Tab>
        </PermissionsGate>

        <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon usePopover>
          <Tab
            className="col-md-6"
            active={hasCustomMobile}
            borderLeft={true}
            onClick={() => setHasCustomMobile(true)}
          >
            <div className="m-3 d-flex justify-content-sm-center">
              <label className="form-check form-switch mb-0">
                <input className="form-check-input cursor-pointer"
                  type="radio"
                  checked={hasCustomMobile}
                  onChange={() => {}}
                />
                <span className="form-check-label cursor-pointer d-flex align-items-center">
                  <i className="ti ti-device-desktop"></i>
                  <i className="ti ti-equal-not mx-1"></i>
                  <i className="ti ti-device-mobile me-1"></i>
                  {diffLabel}
                </span>
              </label>
            </div>
          </Tab>
        </PermissionsGate>
      </div>

      {!hasCustomMobile ? (
        <div className={cardBodyClass}>{desktopComponent}</div>
      ) : (
        <div
          className={`card ${!hasCustomMobile ? "d-none" : ""} m-3`}
        >
          <div className="card-header row align-items stretch p-0 m-0">
            <Tab
              className="col-md-6"
              active={customDesktopTabActive}
              borderLeft={false}
              onClick={() => setcustomDesktopTabActive(true)}
            >
              <div className="m-3 d-flex justify-content-center align-content-center">
                <i className="ti ti-device-desktop icon me-2"></i>
                {label} {I18n.t("views.manage_flows.script_editor.for_desktop")}
              </div>
            </Tab>

            <Tab
              className="col-md-6"
              active={!customDesktopTabActive}
              borderLeft={true}
              onClick={() => setcustomDesktopTabActive(false)}
            >
              <div className="m-3 d-flex justify-content-center align-content-center">
                <i className="ti ti-device-mobile icon me-2"></i>
                {label} {I18n.t("views.manage_flows.script_editor.for_mobile")}
              </div>
            </Tab>
          </div>

          <div className={`${cardBodyClass}`}>
            {customDesktopTabActive ? (
              <div>{desktopComponent}</div>
            ) : (
              mobileComponent
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DesktopAndMobileTabs;
