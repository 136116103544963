export const ACTIONS = {
  UPDATE_SETTINGS: "UPDATE_SETTINGS",

  SET_ASSISTANTS_LIST: "SET_ASSISTANTS_LIST",
  SET_ASSISTANT: "SET_ASSISTANT",
  REMOVE_ASSISTANT: "REMOVE_ASSISTANT",

  ADD_ACTIVE_BASE: "ADD_ACTIVE_BASE",
  REMOVE_ACTIVE_BASE: "REMOVE_ACTIVE_BASE",
  ADD_PENDING_BASE: "ADD_PENDING_BASE",
  REMOVE_PENDING_BASE: "REMOVE_PENDING_BASE",
};
