import React, { useState, useEffect, useCallback } from "react";

import swal from "sweetalert";
import ScriptSwitchAvailability from "../../ScriptSwitchAvailability";
import PermissionsGate from '@/components/permissions/PermissionsGate';

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';

import parse from "html-react-parser";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const TopMenu = () => {
  const scriptId = useContextSelector(ScriptContext, ({ state }) => state.id);
  const scriptName = useContextSelector(ScriptContext, ({ state }) => state.name);
  const scriptUid = useContextSelector(ScriptContext, ({ state }) => state.uid);
  const scriptEnabled = useContextSelector(ScriptContext, ({ state }) => state.enabled);
  const companyId = useContextSelector(ScriptContext, ({ state }) => state.companyId);

  const saveData = useContextSelector(ScriptContext, ({ saveData }) => saveData);
  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);

  const [isLoading, setIsLoading] = useState(false);

  const setName = (value) => {
    dispatch({ type: "SET_NAME", payload: value })
  };

  const createStickyNavbarOptions = useCallback((e) => {
    const navbar = document.getElementById("navbar-options");

    if (window.pageYOffset > 140) {
      navbar.classList.add("sticky");
    } else {
      navbar.classList.remove("sticky");
    }
  }, []);

  const validateNameInput = useCallback(() => {
    let input = document.getElementById("name");

    if (input.value.trim() === '') {
      input.classList.add("is-invalid");
      throw I18n.t("manage_flow.script_editor.check_flow_data");
    }

    input.classList.remove("is-invalid");
  });

  useEffect(() => {
    window.onscroll = function () {
      createStickyNavbarOptions();
    };
  }, []);

  const handleSaveData = async () => {
    setIsLoading(true);

    try {
      validateNameInput();

      const response = await saveData();

      setIsLoading(false);
      swal("", I18n.t("manage_flow.script_editor.flow_successfully_updated"), "success")
        .then(() => {
          let data = response.data
          if (data.show_customize_tutorial) {
            userGuiding.previewGuide(95498)
          }
        })
    } catch (err) {
      setIsLoading(false);
      let errorMessage = I18n.t("manage_flow.script_editor.error_during_flow_update");

      if (!err.response) {
        errorMessage = err;
      }

      swal("", errorMessage, "error");
    }
  };

  return (
    <div>
      <div className="navbar navbar-light" id="navbar-options">
        <div className="container d-sm-flex flex-md-row flex-sm-column justify-content-between">
          <div className="d-flex align-items-center">
            <div className="p-0 mt-1 me-3">
              <label className="custom-switch custom-control-inline">
                <ScriptSwitchAvailability
                  isEnabled={scriptEnabled}
                  scriptId={scriptId}
                  alertDisabled={true}
                />
              </label>
            </div>

            <input
              id="name"
              type="text"
              className="form-control"
              value={scriptName}
              placeholder={I18n.t("manage_flow.script_editor.name")}
              onChange={(event) => setName(event.target.value)}
            />

            <div className="invalid-feedback ms-3">
              {parse(I18n.t("manage_flow.script_editor.fill_in_the_name_field"))}
            </div>
          </div>

          <div className="mt-3 mt-md-0 d-flex justify-content-center">
            <a href="/implementation" className="btn me-2" target="_blank">
              <span className="d-none d-lg-inline">
                {I18n.t("views.manage_flows.script_editor.activate_on_site")}
              </span>
              <span className="d-lg-none">
                <i className="ti ti-copy icon"></i>
                {I18n.t("manage_flow.script_editor.activate")}
              </span>
            </a>

            <a
              className="btn me-2"
              href={`/demo/?company=${companyId}&script=${scriptId}`}
              target="_blank"
            >
              <span className="d-none d-lg-inline">{I18n.t("views.manage_flows.script_editor.view_demo")}</span>
              <span className="d-lg-none">
                <i className="ti ti-eye icon"></i>
                {I18n.t("manage_flow.script_editor.test")}
              </span>
            </a>

            <PermissionsGate scope={PERMISSION_SCOPE} usePopover useLockIcon iconCentered iconSmall>
              <a href={`/capture/${scriptUid}`} className="btn me-2" target="_blank">
                <span className="d-none d-lg-inline">
                  {I18n.t("views.manage_flows.script_editor.capture_page")}
                </span>

                <span className="d-lg-none">
                  <i className="ti ti-eye icon"></i>
                  {I18n.t("manage_flow.script_editor.capture_pag")}
                </span>
              </a>
            </PermissionsGate>

            <button
              type="button"
              className={`btn btn-primary ${isLoading && "btn-loading btn-disabled"}`}
              onClick={() => handleSaveData()}
            >
              <span className="d-none d-lg-inline">{I18n.t("views.manage_flows.script_editor.save_flow")}</span>
              <span className="d-lg-none">{I18n.t("general.actions.save")}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopMenu;
