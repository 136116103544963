import React from "react";

import parse from "html-react-parser";

import { isRole, ROLE } from "@/permissions-provider";

import QualifiedScheduling from "./QualifiedScheduling";

import monitorTopImg from "./images/screen_top.png";
import monitorBottomImg from "./images/screen_bottom.png";

import useCurrentUserStore from "@/store/currentUserStore";

import { MainTitle, IframeDemo } from "./styles";

const PreviewOnScreen = (props) => {

  const { demoUrl, redirectUrl, qualifiedScheduling, dataLeadScheduling, scheduleDemo, trialDays } = props;
  const { applicationName, isResellerOwner } = useCurrentUserStore((state) => state.currentUser);

  return (
    <div className="ps-lg-4 ps-xl-5 mt-lg-4">
      <div className="row g-0">
        <div className="col-lg-5 p-lg-0 px-3 pe-lg-3 pe-xl-6">
        <MainTitle className="fw-bold mt-4 mt-xl-5 pt-lg-5 pt-xl-6">
            {isRole(ROLE.whatsapp) ? (
              <>
                {parse(I18n.t("views.onboard.hello.preview_on_screen.whatsapp.title_html"))}

                <p className="lead mb-2">
                  {I18n.t("views.onboard.hello.preview_on_screen.whatsapp.text_01")}
                </p>

                {!isResellerOwner && (
                  <p className="lead">
                    {parse(I18n.t("views.onboard.hello.preview_on_screen.whatsapp.text_02_html"))}
                  </p>
                )}

                <a href={redirectUrl} className="btn btn-primary btn-lg text-center px-xl-5 mb-3">
                  {I18n.t("views.onboard.hello.preview_on_screen.whatsapp.activate")}
                </a>
              </>
            ) : qualifiedScheduling ? (
              <QualifiedScheduling
                redirectUrl={redirectUrl}
                dataLeadScheduling={dataLeadScheduling}
                scheduleDemo={scheduleDemo}
                trialDays={trialDays}
              />
            ) : (
              <>
                {parse(I18n.t("views.onboard.hello.preview_on_screen.title_html", {application_name: applicationName }))}

                <p className="lead mb-2">
                  {I18n.t("views.onboard.hello.preview_on_screen.text_01")}
                </p>

                {!isResellerOwner && (
                  <p className="lead">
                    {parse(I18n.t("views.onboard.hello.preview_on_screen.text_02_html"))}
                  </p>
                )}

                <a href={redirectUrl} className="btn btn-primary btn-lg text-center px-xl-5 mb-3">
                  {I18n.t("views.onboard.hello.preview_on_screen.access_platform")}
                </a>

                {!isResellerOwner && (
                  <div className="text-muted fw-medium fs-3">
                    <i className="ti ti-clock align-text-bottom icon d-none d-xl-inline-block me-2" />
                    {I18n.t("views.onboard.hello.preview_on_screen.start_trial")}
                  </div>
                )}
              </>
            )}
          </MainTitle>
        </div>

        <div className="col-lg d-flex justify-content-end mt-4 mt-lg-0 ps-4 ps-lg-0">
          <div>
            <img src={monitorTopImg} className="d-block img-fluid" />
            <IframeDemo src={demoUrl} className="w-100 d-block" scrolling="no" />
            <img src={monitorBottomImg} className="d-block img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewOnScreen;
