import { Stack, Button } from "react-bootstrap";

import AddWebsiteLink from "./components/AddWebsiteLink";
import DocumentUpload from "./components/DocumentUpload";
import ContentList from "./components/ContentList";
import Alert from "./components/Alert";

import { useHistory } from "react-router-dom";
import { useAssistantContext } from "@/contexts/AssistantContext";
import useCreateYourAiAssistant from "@/hooks/useCreateYourAiAssistant";

import HelpPopover from "@/components/shared/HelpPopover";

import * as S from "./styles";

const AddContent = () => {
  useCreateYourAiAssistant("/content");

  const { assistant } = useAssistantContext();
  const history = useHistory();

  return (
    <>
      <Alert onCancel={() => window.location.reload()} />

      <Stack gap={3}>
        <div>
          <label className="form-label">
            <i className="icon ti ti-world-www text-primary me-2" />
            {I18n.t("views.leadster_ai.page.add_content.add_links.label")}
            <HelpPopover
              className="ms-1"
              content={I18n.t("views.leadster_ai.page.add_content.add_links.help_popover_html")}
            />
          </label>

          <AddWebsiteLink />
        </div>

        <S.Divisor />

        <div>
          <label className="form-label">
            <i className="icon ti ti-file-upload text-primary me-2" />
            {I18n.t("views.leadster_ai.page.add_content.add_file.label")}
            <HelpPopover
              className="ms-1"
              content={I18n.t("views.leadster_ai.page.add_content.add_file.help_popover_html")}
            />
          </label>

          <DocumentUpload />
        </div>

        {assistant.active_knowledge_bases.length ? (
          <>
            <S.Divisor />
            <ContentList />
          </>
        ) : null}
      </Stack>

      <div className="border-top mt-3 pt-3 text-end">
        <Button
          variant="primary"
          type="button"
          onClick={() => history.push(`/ai/leadster/assistants/${assistant.id}/test`)}
        >
          {I18n.t("shared.actions.advance")}
        </Button>
      </div>
    </>
  );
};

export default AddContent;
