export const buildWhatsAppLink = (number, message = null, type = 'web', mobile_device = null, ddi = 55) => {
  const whatsAppUrl = (type, mobile_device) => {
    if (mobile_device === true) { type = 'mobile' }


    if (type == 'api') {
      return 'https://api.whatsapp.com/send?phone=<:number>&text=<:message>';
    } else if (type == 'mobile') {
      return 'https://wa.me/<:number>?text=<:message>';
    } else {
      return 'https://web.whatsapp.com/send?phone=<:number>&text=<:message>';
    }
  }

  const whatsAppNumber = (number, ddi) => {
    const is_international = number.substring(0, 1) === '+';
    return is_international ? whatsAppInternationalFormat(number) : whatsAppNationalFormat(number, ddi);
  }

  const whatsAppMessage = (message) => {
    if (message === null) {
      return '';
    }

    return encodeURIComponent(message);
  }

  const whatsAppInternationalFormat = (number) => {
    return number.replace(/\D/g, '');
  }

  const whatsAppNationalFormat = (number, ddi) => {
    return `${ddi}${number.replace(/\D/g, '')}`;
  }

  let url = whatsAppUrl(type, mobile_device);
  url = url.replace('<:number>', whatsAppNumber(number, ddi));
  url = url.replace('<:message>', whatsAppMessage(message));

  return url;
}

