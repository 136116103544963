import { useEffect, useRef } from "react";

import { useAssistantContext } from "@/contexts/AssistantContext";

import axios from "@/packs/axios";

const useWatchPendingContentUrls = (onDone, onError) => {
  const { assistant } = useAssistantContext();
  const timeoutRef = useRef();

  const analysePendingContent = async () => {
    await axios
      .get(`/ai/assistants/multiple-url/${assistant?.id}/?ts=${new Date().getTime()}`)
      .then(({ data }) => {
        if (data.status_crawler && !data.status_pinecone) {
          clearInterval(timeoutRef?.current);
          if (typeof onDone === "function") onDone(data.urls);
        }
      })
      .catch((error) => {
        clearInterval(timeoutRef?.current);
        if (typeof onError === "function") onError(error);
      });
  };

  useEffect(() => {
    timeoutRef.current = setInterval(async () => {
      await analysePendingContent();
    }, 10000);

    return () => {
      clearInterval(timeoutRef.current);
    };
  }, [assistant]);

  useEffect(() => {
    analysePendingContent();
  }, []);
};

export default useWatchPendingContentUrls;
