import React from "react";

import ButtonAction from "@/components/alerts/components/ButtonAction";

import parse from "html-react-parser";
import dayjs from "@/packs/dayjs";

const STORAGE_KEY = "alert_block_account";

const AlertBlockedAccount = ({ link, tomorrow, candidateUpsell }) => {
  const canShowAlert = () => {
    return !Boolean(window.sessionStorage.getItem(STORAGE_KEY));
  };

  const onCloseAlertActions = () => {
    window.sessionStorage.setItem(STORAGE_KEY, "true");
  };

  if (!canShowAlert()) {
    return <></>;
  }

  return (
    <div className="container">
      <div className="alert-metrics shadow-lg">
        <div className="alert alert-warning mt-5 mb-3" role="alert">
          <div className="row row-close">
            <a className="btn-close" data-bs-dismiss="alert" aria-label="close" onClick={() => onCloseAlertActions()} />
          </div>

          <div className="clearfix" />

          <div className="row m-2">
            <div className="col-lg-5 col-md-12 text-center">
              <img
                src="https://cdn.leadster.com.br/images/shared/alerts/alert-blocked-account.png"
                className="img-fluid"
                alt={parse(I18n.t("views.shared.alerts.alert_blocked_account.img_title"))}
                title={parse(I18n.t("views.shared.alerts.alert_blocked_account.img_title"))}
              />
            </div>

            <div className="col-lg-7 col-md-12 mt-2 text-center">
              <div className="title">
                {parse(I18n.t("views.shared.alerts.alert_blocked_account.title"))}
              </div>

              <p className="content">
                {parse(I18n.t("views.shared.alerts.alert_blocked_account.description", {
                  tomorrow: dayjs(tomorrow).format( I18n.t("js.formats.default.date")),
                }))}
              </p>

              <ButtonAction link={link} candidateUpsell={candidateUpsell} />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertBlockedAccount;
