import styled from "styled-components";

export const Content = styled.div.attrs((props) => ({
  className: `alert ${props.typeAlert} alert-dismissible text-black d-flex align-items-center mb-3`,
  role: "alert",
}))``;

export const SeePages = styled.div.attrs({
  className: "btn btn-primary btn-sm",
})`
  margin-left: 0.5rem;
`;

export const Cancel = styled.div.attrs({
  className: "btn btn-secondary btn-sm",
})`
  margin-left: 0.5rem;
`;

export const Spinner = styled.div.attrs((props) => ({
  className: `spinner-border spinner-border-sm me-2 ${props.hidden ? "d-none" : ""}`,
}))``;
