import React, { useEffect, useState, useCallback } from 'react';
import axios from "packs/axios";

const CompanyBadges = ({ company_id }) => {
  const [badges, setBadges] = useState([]);

  const getBadges = useCallback(async () => {
    const response = await axios.get(`/owners/company-badges/${company_id}`);
    const { data } = response;

    if (Array.isArray(data) && (data.length === 0 || (data.length === 1 && Object.keys(data[0]).length === 0))) {
      setBadges([]);
    } else {
      setBadges(data);
    }
  }, [company_id]);

  const renderBadge = ({ value, color }) => {
    return (
      <span key={value} className={`mx-2 badge bg-${color}-lt`}>
        {value}
      </span>
    );
  };

  useEffect(() => {
    getBadges();
  }, [getBadges]);

  if (badges.length === 0) {
    return null;
  }

  return (
    <div>
      {badges.map(renderBadge)}
    </div>
  );
};

export default CompanyBadges;
