import React from "react";

import useSelectorBlocksFetcher from "./hooks/useSelectorBlocksFetcher";

import DonutChart from "./DonutChart";
import BarChart from "./BarChart";
import Filters from './Filters';
import NoAnalyticsAvaliableAlert from '../Alerts/NoAnalyticsAvaliableAlert';

import { classBinding } from '@/utils/helpers';

import dayjs from "@/packs/dayjs";

const QuestionQualification = () => {
  const {
    selectorBlocksAnalytics,
    period,
    setPeriod,
    channel,
    setChannel,
    isLoading,
  } = useSelectorBlocksFetcher();

  return (
    <>
      <div className={classBinding({ "content-loading": isLoading }, "card mb-4")}>
        <div className="card-body">
          <div className="row align-items-center">
            <div className="h2 mb-3">{I18n.t("views.analytics.option_list_analysis.title")}</div>
            <div className="card-subtitle text-muted mb-0">
              {I18n.t("views.analytics.option_list_analysis.graph_description")}
            </div>
          </div>
        </div>

        <div className="card-body">
          <Filters
            defaultPeriod={period}
            onChangePeriod={(period) => setPeriod(period)}
            defaultChannel={channel}
            onChangeChannel={(channel) => setChannel(channel)}
          />
        </div>
      </div>

      <div className="row row-deck row-cards">
        {(!isLoading && !selectorBlocksAnalytics.length) && (
          <NoAnalyticsAvaliableAlert />
        )}

        {selectorBlocksAnalytics.map((block) => (
          <div className="col-lg-6" key={block.input_key}>
            <div className={classBinding({ "content-loading": isLoading }, "card shadow-sm")}>
              <div className="card-header">
                <div className="card-title">
                  <div>
                  {I18n.t("views.analytics.field")}: <span className="text-primary">{block.input_key}</span>
                  </div>

                  <div>
                  {I18n.t("views.analytics.total_leads")}: <span className="text-primary">{block.leads_count}</span>
                  </div>
                </div>
              </div>

              {!!block.version_date && (
                <div className="bg-primary-lt fw-medium p-3">
                  <div className="d-flex">
                    <i className="ti ti-bulb icon me-2"></i>

                    <div className='text-body'>
                    {I18n.t("views.analytics.block_version_since")} {" "}
                      <strong>{dayjs(block.version_date).format(I18n.t("js.formats.default.date_time"))}</strong>
                    </div>
                  </div>
                </div>
              )}

              <div className="card-body">
                {block.is_multiple || period === "all_time" ? (
                  <BarChart data={block.options_conversion} />
                ) : (
                  <DonutChart data={block.options_conversion} />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default QuestionQualification;
