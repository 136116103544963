import React, { useState } from "react";
import PropTypes from "prop-types";

import HelpPopover from "@/components/shared/HelpPopover";

import axios from "@/packs/axios";
import swal from "sweetalert";
import parse from "html-react-parser";

const DefaultFlowCard = ({ pageUrl, callId, newConversionCall, suggestedConversionCall }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateDefaultScript = async () => {
    setIsLoading(true);

    const payload = {
      page_url: pageUrl,
      call_id: callId,
      new_conversion_call: newConversionCall,
      suggested_conversion_call: suggestedConversionCall,
    };

    axios.post("/page-ranks/create-script", payload)
      .then(({ data }) => {
        swal(I18n.t("views.page_rank.rank_table.data.modal_create.card_create_default_flow.success_create_flow"), "", "success");
        setTimeout(() => {
          window.location = `/scripts/${data.id}/edit`;
        }, 2000);
      })
      .catch(() => {
        swal(I18n.t("request_feedback_alert.error.title"), "Não foi possível realizar a operação. Por favor, tente novamente.", "error");
        setIsLoading(false)
      });
  };

  return (
    <div className="card">
      <div className="card-body text-center position-relative">
        <HelpPopover
          className="position-absolute"
          style={{ right: 20 }}
          content={I18n.t("views.page_rank.rank_table.data.modal_create.card_create_default_flow.help_popover")}
        />

        <div className="mb-2">
          <i className="ti ti-message display-5 text-primary" />
        </div>

        <div className="h3 mb-2">
          {I18n.t("views.page_rank.rank_table.data.modal_create.card_create_default_flow.title")}
        </div>

        <div className="text-muted mb-3">
          {parse(I18n.t("views.page_rank.rank_table.data.modal_create.card_create_default_flow.description_html"))}
        </div>

        <button
          type="button"
          className="btn btn-outline-primary w-100"
          onClick={handleCreateDefaultScript}
          disabled={isLoading}
        >
          {isLoading && <span className="spinner-border spinner-border-sm me-2" />}

          <i className="ti ti-plus icon"></i>
          {parse(I18n.t("views.page_rank.rank_table.data.modal_create.card_create_default_flow.btn_action"))}
        </button>
      </div>
    </div>
  );
};

DefaultFlowCard.propTypes = {
  pageUrl: PropTypes.string.isRequired,
  callId: PropTypes.number.isRequired,
  newConversionCall: PropTypes.string.isRequired,
  suggestedConversionCall: PropTypes.string.isRequired,
};

export default DefaultFlowCard;
