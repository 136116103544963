import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import ReactPortal from "@/components/ReactPortal";

import { Modal, CloseButton} from "react-bootstrap";

import axios from "@/packs/axios";

import * as S from "./styles";

const ScriptBySegmentModal = ({
  show = false,
  referrerAction = "",
  onClose = () => {},
}) => {
  const segmentScripts = I18n.t("shared.template_script_by_segment.template_script_description");
  const segmentNames = Object.keys(segmentScripts);

  const [activeSegment, setActiveSegment] = useState("all");
  const [scripts, setScripts] = useState([]);

  const handleClose = () => onClose();

  useEffect(() => {
    filterScripts();
  }, [activeSegment]);

  const filterScripts = () => {
    let filteredScripts = segmentScripts[activeSegment];

    if (activeSegment === "all") {
      filteredScripts = segmentNames.reduce((previousValue, segment) => {
        return previousValue.concat(segmentScripts[segment]);
      }, []);
    }

    setScripts(filteredScripts);
  };

  const createScript = (templateId = null) => {
    const isDefaultScript = !!templateId;
    const payload = { template_id: templateId };

    axios
      .post("/scripts", payload)
      .then((response) => {
        const { id: newScriptId } = response.data;
        const redirectURL = new URL(`/scripts/${newScriptId}/edit`, location.origin);

        MixpanelService.track("script_create_new", { "Fluxo Padrão?": isDefaultScript }, true);

        if (referrerAction) {
          redirectURL.searchParams.set("referrer_action", referrerAction);
        }

        window.location = redirectURL.toString();
      })
      .catch((e) => {
        console.error(e);
        swal(
          I18n.t("views.manage_flows.notification.unable_to_create_your_flow.title"),
          I18n.t("views.manage_flows.notification.unable_to_create_your_flow.description"),
          "error"
        );
      });
  };

  const handleClickCreateDefaultScript = async () => {
    const hasConfirmed = await swal({
      title: I18n.t("views.manage_flows.create_default.title"),
      icon: "info",
      buttons: true,
      dangerMode: false,
      buttons: {
        cancel: { text: I18n.t("shared.actions.cancel"), visible: true },
        confirm: {
          text: I18n.t("shared.actions.ok").toUpperCase(),
          visible: true,
        },
      },
    });

    if (hasConfirmed) {
      createScript();
    }
  };

  const handleClickCreateTemplateScript = async (script) => {
    const hasConfirmed = await swal({
      title: I18n.t("views.manage_flows.create_custom.title"),
      text: script.title,
      buttons: true,
      dangerMode: false,
      buttons: {
        cancel: { text: I18n.t("shared.actions.cancel"), visible: true },
        confirm: {
          text: I18n.t("shared.actions.ok").toUpperCase(),
          visible: true,
        },
      },
    });

    if (hasConfirmed) {
      createScript(script.id);
    }
  };

  return (
    <ReactPortal>
      <S.Modal show={show} className="modal-blur" size="xl" centered onHide={handleClose}
      >
        <Modal.Body className="p-0">
          <CloseButton onClick={handleClose} />

          <div className="row no-gutters h-100">
            <div className="col-4 col-sm-3 pt-3 pb-2 ps-4 pe-2 side-bar">
              <div className="h3">
                {I18n.t("views.manage_flows.create_flow.flow_models.title")}
              </div>

              <div className="divisor">
                {I18n.t("views.manage_flows.create_flow.flow_models.market_segment.title")}
              </div>

              <ul>
                <li
                  className={`${activeSegment == "all" && "active"}`}
                  onClick={() => setActiveSegment("all")}
                >
                  {I18n.t("shared.actions.all")}
                </li>

                {segmentNames.map((segment) => (
                  <li
                    className={`${activeSegment == segment && "active"}`}
                    onClick={() => setActiveSegment(segment)}
                    key={segment}
                  >
                    {I18n.t(`shared.segment.${segment}`)}
                  </li>
                ))}
              </ul>
            </div>

            <div className="col col-sm pt-3 pb-2 px-4">
              <div>
                <div className="h3">
                  {I18n.t("views.manage_flows.create_flow.title")}
                </div>

                <p>{I18n.t("views.manage_flows.create_flow.description")}</p>

                <button
                  type="button"
                  className="btn btn-light border"
                  onClick={handleClickCreateDefaultScript}
                >
                  <i className="ti ti-message-dots icon"></i>
                  {I18n.t("views.manage_flows.create_flow.button_new_default_flow")}
                </button>
              </div>

              <div className="mt-4">
                <div className="h3">
                  {I18n.t("views.manage_flows.create_flow.choose_a_model.title")}
                </div>

                <p>
                  {I18n.t("views.manage_flows.create_flow.choose_a_model.description")}
                </p>

                <div className={`pt-2 ${scripts.length > 3 ? "container-shadow" : ""}`}>
                  <div className="row row-deck gx-3 flow-container">
                    {scripts.map((script, index) => (
                      <div className="col-sm-6 col-md-4 mb-3" key={index}>
                        <div
                          className="card card-sm avaliable-flow"
                          onClick={() => handleClickCreateTemplateScript(script)}
                        >
                          <img className="card-img-top" src={script.image} />

                          <div className="card-body">
                            <div className="h5">{script.title}</div>
                            <div className="badge bg-muted-lt text-body">
                              {I18n.t(`shared.segment.${script.segment}`)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </S.Modal>
    </ReactPortal>
  );
};

PropTypes.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ScriptBySegmentModal;
