import React from "react";

import PermissionsGate from '@/components/permissions/PermissionsGate';

import axios from "packs/axios";
import swal from "sweetalert";

import {
  Container,
  ActivateButton,
  ActivateText,
  NotActivateLink,
  DescriptionText,
} from "./styles";

const PERMISSION_SCOPE = Object.freeze({
  action: "manage",
  subject: "dashboard_config",
});

const toolParamMap = {
  "Google": "google_ads",
  "Meta": "fb_ads",
  "LinkedIn": "linkedin_ads",
};

const AdsBanner = ({ borderColor, tool, companyId, imagePath, imageHeight }) => {
  const paramName = toolParamMap[tool];
  const url = `/companies/${companyId}/update_ads`;

  const handleClickDeactivate = async () => {
    const shouldNotActivate = await swal({
      text: I18n.t("views.dashboard.ads.dont_want_to_activate_ads").replace("%{tool}", tool),
      icon: "warning",
      buttons: true,
      dangerMode: true,
      buttons: {
        cancel: { text: I18n.t("shared.actions.cancel"), visible: true },
        confirm: { text: I18n.t("shared.actions.ok").toUpperCase(), visible: true },
      },
    });

    if (shouldNotActivate) {
      axios.post(url, { [paramName]: "inactive" })
        .then(() => {
          swal("", I18n.t("views.dashboard.ads.successfully_disabled").replace("%{tool}", tool), "success");
          setTimeout(() => window.location.reload(), 1500);
        })
        .catch(() => {
          swal("", I18n.t("shared.messages.block.insufficient_permissions"), "warning");
        });
    }
  };

  const handleActivate = (e) => {
    e.preventDefault();

    axios.post(url, { [paramName]: "active" })
      .then(() => {
        swal("", I18n.t("views.dashboard.ads.successfully_enabled").replace("%{tool}", tool), "success");
        setTimeout(() => window.location.reload(), 1500);
      })
      .catch(() => {
        swal("", I18n.t("shared.messages.insufficient_permissions"), "warning");
      });
  };

  return (
    <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon usePlaceholderError>
      <Container className="card h-100" borderColor={borderColor}>
        <div className="card-body d-flex flex-column justify-content-between align-content-between text-white">
          <div>
            <img src={imagePath} className="mb-3" height={imageHeight} />
          </div>

          <DescriptionText>
            {I18n.t("views.dashboard.ads.description", { tool: tool })}
          </DescriptionText>

          <div className="d-block mt-auto">
            <ActivateButton onClick={handleActivate}>
              <ActivateText>{I18n.t("views.dashboard.ads.activate")}</ActivateText>
            </ActivateButton>

            <NotActivateLink onClick={handleClickDeactivate}>
              {I18n.t("views.dashboard.ads.not_activate")}
            </NotActivateLink>
          </div>
        </div>
      </Container>
    </PermissionsGate>
  );
};

export default AdsBanner;
