import axios from "axios";

const currentToken = document.querySelector('[name="csrf-token"]');

const customAxios = axios.create({
  headers: {
    common: { "X-CSRF-Token": _.get(currentToken, "content", "no-csrf-token") },
  },
});

export default customAxios;
