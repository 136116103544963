import React, { useEffect } from "react";

import FirstStep from "./Items/Default/First";
import SecondStep from "./Items/Default/Second";
import ThirdStep from "./Items/Default/Third";
import FourthStep from "./Items/Default/Fourth";
import FirstWhatsAppStep from "./Items/WhatsApp/First";
import SecondWhatsAppStep from "./Items/WhatsApp/Second";
import ThirdWhatsAppStep from "./Items/WhatsApp/Third";
import Separator from "./Separator";

import Carousel from "react-elastic-carousel";

import { isRole, ROLE } from "@/permissions-provider";

import { Container } from "./styles";

const StepItens = ({ isCarousel = false, isCapturePage = false }) => {
  useEffect(() => {
    window.initJqueryPopover();
  }, []);

  return (
    <Container>
      {isCarousel ? (
        <Carousel
          itemPadding={[0, 10]}
          itemsToShow={1}
          showArrows={false}
          initialActiveIndex={2}
        >
          {isRole(ROLE.default) && <FirstStep />}
          {isRole(ROLE.default) && <SecondStep />}
          {isRole(ROLE.default) && <ThirdStep boostSize={false} isCapturePage={isCapturePage} />}
          {isRole(ROLE.default) && <FourthStep />}

          {isRole(ROLE.whatsapp) && <FirstWhatsAppStep />}
          {isRole(ROLE.whatsapp) && <SecondWhatsAppStep />}
          {isRole(ROLE.whatsapp) && <ThirdWhatsAppStep />}
        </Carousel>
      ) : (
        <div className="d-flex align-items-center">
          {isRole(ROLE.default) && (
            <>
              <FirstStep />
              <Separator primary />
              <SecondStep />
              <Separator primary />
              <ThirdStep isCapturePage={isCapturePage} />
              <Separator />
              <FourthStep />
            </>
          )}

          {isRole(ROLE.whatsapp) && (
            <>
              <FirstWhatsAppStep />
              <Separator primary />
              <SecondWhatsAppStep />
              <Separator />
              <ThirdWhatsAppStep />
            </>
          )}
        </div>
      )}
    </Container>
  );
};

export default StepItens;
