export const ASSISTANT_BEHAVIOR_OPTIONS = [
  {
    name: I18n.t("views.leadster_ai.page.create_assistant.assistente_type.qualify_leads.name"),
    value: "qualify_leads",
    description: I18n.t("views.leadster_ai.page.create_assistant.assistente_type.qualify_leads.description")
  },
  {
    name: I18n.t("views.leadster_ai.page.create_assistant.assistente_type.customer_support.name"),
    value: "customer_support",
    description: I18n.t("views.leadster_ai.page.create_assistant.assistente_type.customer_support.description")
  },
  {
    name: I18n.t("views.leadster_ai.page.create_assistant.assistente_type.e_commerce.name"),
    value: "e_commerce",
    description: I18n.t("views.leadster_ai.page.create_assistant.assistente_type.e_commerce.description")
  },
];
