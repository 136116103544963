import React, { useState, useRef, useEffect } from "react";

import { Form, Modal, Button } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";

import useWatchPendingContentUrls from "./hooks/useWatchPendingContentUrls";
import useCurrentUserStore from "@/store/currentUserStore";
import { useAssistantContext } from "@/contexts/AssistantContext";

import ReactPortal from "@/components/ReactPortal";
import GroupUrls from "./components/GroupUrls";
import TotalCharacters from "./components/TotalCharacters";
import UrlsUsed from "./components/UrlsUsed";

import parse from "html-react-parser";
import axios from "@/packs/axios";

const ListMultipleUrls = ({ show, onClose, onDone }) => {
  const { locale: userLocale } = useCurrentUserStore((state) => state.currentUser);
  const { assistant } = useAssistantContext();
  const [url] = useState(assistant?.pending_knowledge_base?.url || "");

  const [isLoaded, setIsLoaded] = useState(true);
  const [totalCharacters, setTotalCharacters] = useState(0);
  const [filteredUrls, setFilteredUrls] = useState(null);
  const [multipleUrls, setMultipleUrls] = useState(null);
  const [characterLimit, setCharacterLimit] = useState(0);
  const formElement = useRef(null);

  const handleLoadUrls = (urls) => {
    setIsLoaded(false);
    setMultipleUrls(urls);
    if (typeof onDone === "function") onDone();
  };

  useWatchPendingContentUrls(handleLoadUrls);

  const handleAddKnowledgeBase = () => {
    setIsLoaded(true);

    if (totalCharacters > characterLimit) {
      toastr.error(
        I18n.t("views.leadster_ai.page.add_content.add_links.alerts.error_limit", {
          limit: characterLimit?.toLocaleString(userLocale, { minimumFractionDigits: 0 }),
        })
      );
      setIsLoaded(false);
      return;
    }

    if (totalCharacters === 0) {
      toastr.error(I18n.t("views.leadster_ai.page.add_content.add_links.alerts.error_empty"));
      setIsLoaded(false);
      return;
    }

    const payload = { ai_multiple_url_process_data_ids: [...getSelectedIds()] };

    axios
      .post(`/ai/assistants/async-process-multiple-url/${assistant.id}`, payload)
      .then(() => toastr.success(I18n.t("views.leadster_ai.page.add_content.add_links.alerts.success_added")))
      .catch(() => toastr.error(I18n.t("request_feedback_alert.error.message")))
      .then(() => window.location.reload());
  };

  const getSelectedIds = () => {
    let ids = [];
    const urls = getSelectedUrls();

    multipleUrls?.urls.forEach((group) => {
      group.group_urls.forEach((group_urls) => {
        if (urls.includes(group_urls.url)) ids.push(group_urls.id);
      });
    });

    return ids;
  };

  const getSelectedUrls = () => {
    let urls = [];

    formElement?.current?.querySelectorAll("input[type=checkbox]").forEach((checkbox) => {
      if (!checkbox.checked) return;

      const value = checkbox.parentElement.querySelector("label").innerText;
      const isUrl = RegExp("^(http|https)://", "i").test(value);

      if (isUrl) urls.push(value);
    });

    return urls;
  };

  const getTotalCharacters = () => {
    let characters = 0;
    const urls = getSelectedUrls();

    urls.forEach((url) => {
      const group = multipleUrls?.urls.find((group) => group.group_urls.find((group_urls) => group_urls.url === url));
      const group_urls = group.group_urls.find((group_urls) => group_urls.url === url);

      characters += group_urls.characters;
    });

    return characters;
  };

  const handleFilter = (event) => {
    const value = event.target.value.toLowerCase();

    setFilteredUrls(
      multipleUrls?.urls.filter((group) =>
        group.group_urls.some((group_url) => {
          return group_url.url.toLowerCase().includes(value);
        })
      )
    );
  };

  useEffect(() => {
    setTotalCharacters(getTotalCharacters());
  }, [formElement?.current, filteredUrls]);

  useEffect(() => {
    setFilteredUrls(multipleUrls?.urls);
    setCharacterLimit(multipleUrls?.character_limit);
  }, [multipleUrls]);

  return (
    <>
      <ReactPortal>
        <Modal size="lg" show={show} style={{ display: "block" }}>
          <Modal.Header closeButton onClick={onClose}>
            <Modal.Title className="fw-bold">
              {I18n.t("views.leadster_ai.page.add_content.add_links.modal.title")}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form ref={formElement}>
              <Form.Group>
                <Form.Label>{parse(I18n.t("views.leadster_ai.page.add_content.add_links.modal.subtitle"))}</Form.Label>
                <div className="input-icon mt-3 mb-0">
                  <Form.Control
                    type="text"
                    placeholder={I18n.t("views.leadster_ai.page.add_content.add_links.modal.placeholder")}
                    onChange={handleFilter}
                  />
                  <span className="input-icon-addon">
                    <i className="ti ti-search" />
                  </span>
                </div>
                <hr className="my-3" />
                {isLoaded ? (
                  <div className="alert alert-warning text-black d-flex align-items-center mb-3">
                    <div className="spinner-border spinner-border-sm me-2"></div>
                    <div className="flex-grow-1 text-break">
                      {parse(I18n.t("views.leadster_ai.page.add_content.loading.url", { url: url }))}
                    </div>
                  </div>
                ) : (
                  <InfiniteScroll dataLength={1} hasMore={true} inverse={false} height={400} className="mt-0">
                    <div className="list-group list-group-flush">
                      {filteredUrls?.map((group, key) => (
                        <GroupUrls key={key} group={group} onChange={() => setTotalCharacters(getTotalCharacters())} />
                      ))}
                    </div>
                  </InfiniteScroll>
                )}
              </Form.Group>
            </Form>
          </Modal.Body>

          <TotalCharacters isLoaded={isLoaded} totalCharacters={totalCharacters} characterLimit={characterLimit} />
          <UrlsUsed isLoaded={isLoaded} urlsUsed={multipleUrls?.count_urls_used > 0} />

          <Modal.Footer className="d-flex justify-content-between mt-3">
            <Button variant="secondary" onClick={onClose}>
              {I18n.t("shared.actions.close")}
            </Button>
            <Button hidden={isLoaded} variant="primary" onClick={handleAddKnowledgeBase}>
              {I18n.t("views.leadster_ai.page.add_content.add_links.add_pages")}
            </Button>
          </Modal.Footer>
        </Modal>
      </ReactPortal>
    </>
  );
};

export default ListMultipleUrls;
