import React, { useState } from "react";

import useDinamycFields from "@/components/scripts/hooks/useDinamycFields";

import DeleteButton from '../ScriptEditorParts/DeleteButton';
import PermissionsGate from '@/components/permissions/PermissionsGate';
import RichTextEditor from "@/components/shared/RichTextEditor";

import { can } from "@/permissions-provider";
import setupEditor from "../ScriptEditorParts/setup-editor";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const ScriptBlockAutoRedirect = (props) => {
  const hasPermission = can(PERMISSION_SCOPE);
  const dynamicFields = useDinamycFields();

  const [botMessage, setBotMessage] = useState(props.definition.botMessage || "")
  const [redirectLink, setRedirectLink] = useState(props.definition.redirectLink || "")
  const [useCancelAutomaticRedirectTime, setUseCancelAutomaticRedirectTime] = useState(!!props.definition.useCancelAutomaticRedirectTime);

  const handleBotMessageChange = (msg, editor) => {
    if (!hasPermission) return;

    setBotMessage(msg);
    definition({ botMessage: editor.getContent({ format: "text" }) });
  }

  const handleChangeRedirectLink = (link, editor) => {
    if (!hasPermission) return;

    setRedirectLink(link);
    definition({ redirectLink: editor.getContent({ format: "text" }).trim() });
  }

  const definition = (def) => {
    const mergedDef = Object.assign(props.definition, def);
    props.setDefinition(mergedDef);
  }

  const isInvalidUrl = (url) => {
    const regexURL = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    return url !== "" && !String(url).match(regexURL);
  }

  const handleAutoRedirectChange = (e) => {
    setUseCancelAutomaticRedirectTime(e.target.checked);
    definition({ useCancelAutomaticRedirectTime: e.target.checked });
  }

  return (
    <>
      <div className="row gx-2 mb-3">
        <div className="col">
          <div className="input-group">
            <div className="input-group-text bg-blue text-white">
              <i className="ti ti-external-link me-2"></i>
              {I18n.t("views.manage_flows.auto_redirect_block.redirect")}
            </div>

            <PermissionsGate scope={PERMISSION_SCOPE}>
              <div className="form-control p-0 border-0">
                <RichTextEditor
                  value={botMessage}
                  inline={true}
                  disabled={!hasPermission}
                  init={{
                    menubar: false,
                    plugins: [],
                    toolbar: false,
                    placeholder: I18n.t("views.manage_flows.auto_redirect_block.type_a_message_here"),
                    toolbar: "autocompleteButton",
                    setup: (editor) => setupEditor(editor, dynamicFields, props.id),
                  }}
                  onEditorChange={handleBotMessageChange}
                />
              </div>
            </PermissionsGate>
          </div>
        </div>

        <div className="col-auto">
          <DeleteButton onDelete={props.onDelete} />
        </div>
      </div>

      <div>
        <label className="form-label">
          {I18n.t("views.manage_flows.auto_redirect_block.lead_will_be_redirected")}
        </label>

        <div className={`form-control p-0  ${isInvalidUrl(redirectLink) ? "is-invalid" : "border-0"}`}>
          <RichTextEditor
            value={redirectLink}
            inline={true}
            disabled={!hasPermission}
            init={{
              height: 120,
              menubar: false,
              plugins: [],
              toolbar: false,
              placeholder: I18n.t("views.manage_flows.auto_redirect_block.url_example"),
              toolbar: "autocompleteButton",
              setup: (editor) => setupEditor(editor, dynamicFields, props.id),
            }}
            onEditorChange={handleChangeRedirectLink}
          />
        </div>

        <div className="invalid-feedback">
          {I18n.t("views.manage_flows.auto_redirect_block.url_is_not_valid")}
        </div>
      </div>

      <div className="form-control mb-3 my-2">
        <div className="col-12">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="useCancelAutomaticRedirectTime"
              checked={useCancelAutomaticRedirectTime}
              onChange={(e) => handleAutoRedirectChange(e)}
              disabled={!hasPermission}
            />
            <span className="form-check-label fw-medium">
              {I18n.t("views.manage_flows.auto_redirect_block.redirection_cancellation_time")}
            </span>
            <div className="form-text text-body">
              {I18n.t("views.manage_flows.auto_redirect_block.redirection_cancellation_time_description")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(ScriptBlockAutoRedirect, () => true);
