import "./styles.scss"
import React, { useEffect, useState } from "react";

import Box from "../Box";
import RichTextEditor from '@/components/shared/RichTextEditor';

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';
import { can } from "@/permissions-provider";

import parse from "html-react-parser";

import segmentConversionCalls from "../../data/segment-conversion-calls";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const Modal = React.forwardRef(({ isMobile, updatePreviewSmartCall }, ref) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);
  const avatar = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.previewContent.avatar);

  const [selectedMenu, setSelectedMenu] = useState('all-segments');
  const [customCall, setCustomCall] = useState('');

  let notifications = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.desktopContent.notifications);
  let dispatchActionType = "SET_DESKTOP_CONVOSCRIPT_VALUE";

  if (isMobile) {
    dispatchActionType = "SET_MOBILE_CONVOSCRIPT_VALUE";
    notifications = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.mobileContent.notifications);
  }

  useEffect(() => {
    const handler = (e) => {
      if (e.target.closest(".tox-editor-header") !== null) {
        e.stopImmediatePropagation();
      }
    };

    document.addEventListener("focusin", handler);
    return () => document.removeEventListener("focusin", handler);
  }, []);

  const handleEditorChange = (value, editor) => {
    const currentTextValue = editor.getContent({ format: "text" });
    if (currentTextValue.length <= 105) {
      setCustomCall(value)
    }
  }

  const handleCreateSmartCall = () => {
    if (!hasPermission) return;

    const lastNotificationAdded = _.maxBy(notifications, notification => notification.id);
    const newId = _.get(lastNotificationAdded, 'id', 0) + 1;
    const newSmartCall = { id: newId, template: customCall };

    setScriptValue("notifications", [ ...notifications, newSmartCall ]);
    updatePreviewSmartCall(customCall);
    setCustomCall('');

    $("#smart-call-options").modal('hide');
  };

  const setScriptValue = (key, value) => {
    dispatch({ type: dispatchActionType, payload: { key, value } });
    dispatch({ type: "SET_PREVIEW_CONVOSCRIPT_VALUE", payload: { key, value }});
  };

  return (
    <div ref={ref} className="modal modal-blur fade" id="smart-call-options" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

          <div className="row gx-2">
            {/*Bloco esquerdo*/}
            <div className="col-4 links float-start">
              <div className="p-3">
                <div className="h3">{I18n.t("views.manage_flows.smart_call.title")}</div>
                <span className="subtitle">{I18n.t("views.manage_flows.smart_call.subtitle")}</span>

                <ul className="list-unstyled segment">
                  <li>
                    <a href="#"
                      className={`${selectedMenu == 'all-segments' && 'active'}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedMenu('all-segments')
                      }}
                    >
                      {I18n.t("shared.actions.everything")}
                    </a>
                  </li>

                  { Object.keys(segmentConversionCalls).map((item, index) => {
                      return (
                        <li key={index}>
                          <a href="#" className={`${selectedMenu == item && 'active'}`}
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedMenu(item);
                            }}
                          >
                            {I18n.t(`shared.segment.${segmentConversionCalls[item]['title']}`)}
                          </a>
                        </li>
                      )
                    })
                  }
                </ul>

                <span className="subtitle">{I18n.t("views.manage_flows.smart_call.how_does_it_work")}</span>
                <ul className="list-unstyled segment">
                  <li>
                    <a href="#"
                      className={`${selectedMenu == 'smart-call-help' && 'active'}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedMenu('smart-call-help')
                      }}
                    >
                      {I18n.t("views.manage_flows.smart_call.what_is_smart_calling")}
                    </a>
                  </li>
                </ul>

              </div>
            </div>

            {/*Bloco direito*/}
            <div className="col-8 py-3 px-2 float-start">
              { selectedMenu !== 'smart-call-help' ? (
                <div>
                  <div className="h3">{I18n.t("views.manage_flows.smart_call.create_your_call")}</div>
                  <p className="mb-0">{I18n.t("views.manage_flows.smart_call.write_from_scratch")}</p>

                  <div className="box mb-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="d-flex">
                          <img className="avatar rounded-circle me-3" src={avatar} alt="Avatar"/>

                          <div className="position-relative">
                            <div className="arrow-text" />
                            <div className="text-example">
                              <RichTextEditor
                                value={customCall}
                                inline={true}
                                init={{
                                  menubar: false,
                                  placeholder: I18n.t("views.manage_flows.smart_call.enter_your_personalized_call_here"),
                                  menubar: false,
                                  toolbar: "bold italic underline | emoticons",
                                  smart_paste: false,
                                }}
                                onEditorChange={handleEditorChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-4 d-flex justify-content-center align-items-center">
                        <button type="button" className="btn btn-primary fw-bold btn-rounded w-100" onClick={handleCreateSmartCall}>
                          <i className="ti ti-plus icon"></i>
                          {I18n.t("views.manage_flows.smart_call.create_call")}
                        </button>
                      </div>
                    </div>
                  </div>

                <div className="h3">
                  {I18n.t("views.manage_flows.smart_call.segment")}
                  {
                    (() => {
                      const segmentTitle = _.get(segmentConversionCalls, `${selectedMenu}.title`);
                      return segmentTitle !== undefined ? I18n.t(`shared.segment.${segmentTitle}`) : I18n.t("shared.actions.everything");
                    })()
                  }
                </div>

                  <span>{I18n.t("views.manage_flows.smart_call.use_our_recommended_calls")}</span>

                  <div className="box-container-shadow">
                    <div className="boxes-container">
                      { Object.keys(segmentConversionCalls).map((item) => {
                          const enabled = selectedMenu == item || selectedMenu === 'all-segments';
                          if (enabled) {
                            return <Box
                              key={item}
                              isMobile={isMobile}
                              calls={segmentConversionCalls[item]['calls']}
                              updatePreviewSmartCall={updatePreviewSmartCall}
                            />
                          }
                        })
                      }
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="h2">{I18n.t("views.manage_flows.smart_call.what_is_smart_calling")}</div>
                  <div className="info-body">
                    {parse(I18n.t("views.manage_flows.smart_call.description_html"))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Modal;
