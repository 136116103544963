import React, { useState } from "react";

import { Button, Form, InputGroup } from "react-bootstrap";

import { useForm } from "react-hook-form";
import { useAssistantContext } from "@/contexts/AssistantContext";
import useCurrentUserStore from "@/store/currentUserStore";
import { classBinding } from "@/utils/helpers";

import ListMultipleUrls from "./components/ListMultipleUrls";

import axios from "@/packs/axios";

const AddWebsiteLink = () => {
  const { isAdministrator } = useCurrentUserStore((state) => state.currentUser);

  const { addPendingBase, assistant } = useAssistantContext();
  const {
    register,
    reset,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isMultipleUrls, setIsMultipleUrls] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const pendingBase = assistant.pending_knowledge_base;
  const isDisabled = !!pendingBase || isLoading;
  const isInvalid = errors.hasOwnProperty("url");

  const handleSingleUrl = (data) => {
    const payload = {
      ...data,
      ai_assistant_id: assistant.id,
      base_type: "url",
    };

    axios
      .post("/ai/knowledge_bases", payload)
      .then(({ data }) => {
        addPendingBase(data.knowledge_base);
        reset();
      })
      .catch((err) => {
        if (_.get(err, "response.status") === 422) {
          const responseErrors = _.get(err.response, "data.errors", {});
          reset();

          Object.entries(responseErrors).forEach(([fieldName, [firstErrorMessage]]) => {
            setError(fieldName, { type: "custom", message: firstErrorMessage });
          });

          return toastr.error(I18n.t("request_feedback_alert.validation_error.message"));
        }

        return toastr.error(I18n.t("request_feedback_alert.error.message"));
      })
      .then(() => setIsLoading(false));
  };

  const handleMultipleUrl = (data) => {
    const payload = {
      ...data,
    };

    axios
      .post(`/ai/assistants/async-create-multiple-url/${assistant.id}`, payload)
      .then(({ data }) => {
        addPendingBase(data.knowledge_base);
        reset();
        setShowModal(true);
      })
      .catch((err) => {
        if (_.get(err, "response.status") === 422) {
          const responseErrors = _.get(err.response, "data.errors", {});
          reset();

          Object.entries(responseErrors).forEach(([fieldName, [firstErrorMessage]]) => {
            setError(fieldName, { type: "custom", message: firstErrorMessage });
          });

          return toastr.error(I18n.t("request_feedback_alert.validation_error.message"));
        }

        return toastr.error(I18n.t("request_feedback_alert.error.message"));
      })
      .then(() => setIsLoading(false));
  };

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  const handleDoneModal = () => {
    toastr.success(I18n.t("views.leadster_ai.page.add_content.add_links.alerts.success_processed"));
  };

  const onSubmit = (data) => {
    if (!!isDisabled) return;
    setIsLoading(true);
    MixpanelService.track("leadster_ai_add_url");

    if (isMultipleUrls) {
      handleMultipleUrl(data);
      return;
    }

    handleSingleUrl(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmit)}>
      <InputGroup className={classBinding({ "is-invalid": isInvalid })}>
        <Form.Control
          type="text"
          isInvalid={isInvalid}
          placeholder={I18n.t("views.leadster_ai.page.add_content.add_links.placeholder")}
          disabled={isDisabled}
          {...register("url")}
        />
        <Button type="submit" disabled={isDisabled}>
          <i className="icon ti ti-world-search me-2 fs-2" />
          {I18n.t("views.leadster_ai.page.add_content.add_links.add_page")}
        </Button>
      </InputGroup>

      {isAdministrator && (
        <InputGroup key="inline-radio" className="mt-2">
          <Form.Check
            inline
            label={I18n.t("views.leadster_ai.page.add_content.add_links.add_all_pages")}
            name="read-url"
            type="radio"
            onChange={() => setIsMultipleUrls(true)}
          />
          <Form.Check
            inline
            label={I18n.t("views.leadster_ai.page.add_content.add_links.add_url")}
            name="read-url"
            type="radio"
            defaultChecked
            onChange={() => setIsMultipleUrls(false)}
          />
        </InputGroup>
      )}

      {showModal && <ListMultipleUrls show={showModal} onClose={handleCloseModal} onDone={handleDoneModal} />}

      <Form.Control.Feedback type="invalid">{_.get(errors, "url.message")}</Form.Control.Feedback>
    </Form>
  );
};

export default AddWebsiteLink;
