import React from "react";

import ButtonAction from "@/components/alerts/components/ButtonAction";

import parse from "html-react-parser";

const STORAGE_KEY = "exceeded_level_one";

const ExceededLevelOne = ({ contracted, used, link, candidateUpsell }) => {
  const canShowAlert = () => {
    return !Boolean(window.sessionStorage.getItem(STORAGE_KEY));
  };

  const onCloseAlertActions = () => {
    window.sessionStorage.setItem(STORAGE_KEY, "true");
  };

  const messageDescription  = candidateUpsell
    ? "views.shared.alerts.exceeded_level_one.description_html"
    : "views.shared.alerts.exceeded_level_one.candidate_upsell.description_html";

  if (!canShowAlert()) {
    return <></>;
  }

  return (
    <div className="container">
      <div className="alert-metrics shadow-lg">
        <div className="alert alert-info mt-5 mb-3" role="alert">
          <div className="row row-close">
            <a className="btn-close" data-bs-dismiss="alert" aria-label="close" onClick={() => onCloseAlertActions()} />
          </div>

          <div className="clearfix" />

          <div className="row m-2">
            <div className="col-lg-5 col-md-12 text-center">
              <img
                src="https://cdn.leadster.com.br/images/shared/alerts/attention-exceeded.png"
                className="img-fluid"
                alt={parse(I18n.t("views.shared.alerts.exceeded_level_one.img_title"))}
                title={parse(I18n.t("views.shared.alerts.exceeded_level_one.img_title"))}
              />
            </div>

            <div className="col-lg-7 col-md-12 mt-2 text-center">
              <div className="title">
                {parse(I18n.t("views.shared.alerts.exceeded_level_one.title"))}
              </div>

              <p className="content">
                {parse( I18n.t(messageDescription, { contracted: contracted, used: used }))}
              </p>

              <ButtonAction link={link} candidateUpsell={candidateUpsell} />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExceededLevelOne;
