import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Modal from "react-bootstrap/Modal";

import ReactPortal from "@/components/ReactPortal";
import ScriptItem from "./ScriptItem";
import SkeletonLoader from "./SkeletonLoader";

import axios from "@/packs/axios";

const ScriptActivationModal = ({ show = false, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [scripts, setScripts] = useState([]);

  useEffect(() => {
    if (show) {
      fetchScripts();
    }
  }, [show]);

  const handleClose = () => {
    onClose();
  };

  const fetchScripts = () => {
    setIsLoading(true);

    axios.get(`/ai/scripts`)
      .then(({ data }) => setScripts(data.scripts))
      .catch(() => {
        swal(
          I18n.t("shared.messages.request_feedback_alert.error.title"),
          I18n.t("shared.messages.request_feedback_alert.error.message"),
          "error"
        );
      })
      .then(() => setIsLoading(false));
  };

  return (
    <ReactPortal>
      <Modal show={show} className="modal-blur" size="xl" centered onHide={handleClose}>
        <Modal.Header closeButton>
          <div className="py-2">
            <div className="modal-title mb-1">
              {I18n.t("views.leadster_ai.page.home.add_to_flow_modal.title")}
            </div>

            <div className="text-muted">
              {I18n.t("views.leadster_ai.page.home.add_to_flow_modal.subtitle")}
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className="overflow-auto" style={{ maxHeight: 500}}>
          {isLoading ? (
            <SkeletonLoader />
          ) : (
            scripts.map((script) => (
              <ScriptItem key={script.id} script={script} />
            ))
          )}
        </Modal.Body>

        <Modal.Footer className="border-top py-2">
          <button type="button" className="btn me-auto" onClick={handleClose}>
            {I18n.t("shared.actions.close")}
          </button>
        </Modal.Footer>
      </Modal>
    </ReactPortal>
  );
};

PropTypes.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ScriptActivationModal;
