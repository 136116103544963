import EmptyAlert from "./EmptyAlert";

import Chart from "react-apexcharts";

import { classBinding } from "@/utils/helpers";
import dayjs from "@/packs/dayjs";

import * as S from "./styles";

const DonutChart = ({ title, subtitle, isLoading, data }) => {
  const series = _.get(data, "series", []);
  const options = _.get(data, "options", {});
  const startAt = _.get(data, "start_at");
  const endAt = _.get(data, "end_at");

  return (
    <S.CardContainer>
      <div className="card-header flex-column align-items-start border-0 py-2">
        <div className="card-title mb-1">{title}</div>
        <div className="card-subtitle mb-1">{subtitle}</div>
      </div>

      <div
        className={classBinding(
          { "content-loading": isLoading },
          "card-body align-items-center d-flex flex-column align-items-center justify-content-center pt-0"
        )}
      >
        {series.length ? (
          <>
            <Chart
              options={options}
              series={series}
              type="donut"
              width="100%"
            />

            <div className="text-secondary small mt-3">
              {I18n.t('views.leadster_ai.page.analytics.dashboard.graph.date_reference', {
                start_at: dayjs(startAt).format(I18n.t("js.formats.default.date")),
                end_at: dayjs(endAt).format(I18n.t("js.formats.default.date")),
              })}
            </div>
          </>
        ) : (
          <EmptyAlert />
        )}
      </div>
    </S.CardContainer>
  );
};

export default DonutChart;
