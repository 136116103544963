import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Image from 'react-bootstrap/Image';
import ReactPortal from "@/components/ReactPortal";

import trialEndedImg from "@/../assets/images/ai_assistant/trial-ended.png";

import { buildWhatsAppLink } from "@/utils/whatsapp_helpers";
import useCurrentUserStore from "@/store/currentUserStore";
import useShowAlert from "./hooks/useShowAlert";

import parse from "html-react-parser";

const LeadsterAiTrialExpired = () => {
  const { isNational } = useCurrentUserStore((state) => state.currentUser);
  const { showAlert } = useShowAlert();

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showAlert)
  }, [showAlert])

  const handleClose = () => {
    setShow(false)
  };

  return (
    <ReactPortal>
      <Modal show={show} className="modal-blur" backdrop="static" size="lg" centered onHide={handleClose}>
        <Modal.Header closeButton />

        <Modal.Body className="text-center py-3">
          <div className="mb-3">
            <Image src={trialEndedImg} fluid />
          </div>

          <div className="fs-2 fw-bold mb-3">
            {I18n.t("views.leadster_ai.page.trial_alerts.modal_trial_ended.title")}
          </div>

          <p className="mb-0">
            {parse(I18n.t("views.leadster_ai.page.trial_alerts.modal_trial_ended.description_html"))}
          </p>
        </Modal.Body>

        <Modal.Footer className="border-top py-2">
          <Button className="me-auto btn btn-outline-secondary" onClick={handleClose}>
            {I18n.t("shared.actions.close")}
          </Button>

          {isNational ? (
            <a
              href={buildWhatsAppLink(
                "41991625792",
                I18n.t("views.leadster_ai.page.trial_alerts.contact_button.chat_default_message")
              )}
              target="_blank"
              className="btn btn-primary"
            >
              <i className="ti ti-brand-whatsapp icon" />
              {I18n.t("views.leadster_ai.page.trial_alerts.contact_button.value")}
            </a>
          ) : (
            <button
              type="button"
              className="btn btn-primary contact-tidio"
              tidio-message={I18n.t("views.leadster_ai.page.trial_alerts.contact_button.chat_default_message")}
            >
              <i className="ti ti-messages icon" />
              {I18n.t("views.leadster_ai.page.trial_alerts.contact_button.value")}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </ReactPortal>
  );
};

export default LeadsterAiTrialExpired;
