import React from "react";
import Cards from "react-credit-cards";
import axios from "packs/axios";
import swal from "sweetalert";

export default class PaymentCreditCard extends React.Component {
  state = {
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
    showLoader: false,
  };

  onlyNumber = (value) => {
    return value.replace(/\D/g, "");
  }

  componentDidMount(){
    $('.date').mask("00/00");
  }

  prepareVindiErrors(error_data) {
    let self = this;
    let message = "";

    error_data.map(function (el) {
      let vindi_field = self.translateVindiField(el.parameter);

      if(vindi_field){
        message += vindi_field + ": " + el.message + "\n";
      }
    });

    return message;
  }

  getVindiToken()  {
    return this.props.vindi_public_token;
  }

  getVindiUrl() {
    if (this.props.railsEnv !== "production") {
      return 'https://sandbox-app.vindi.com.br/api/v1/public/payment_profiles';
    }

    return 'https://app.vindi.com.br/api/v1/public/payment_profiles';
  }

  translateVindiField(field){
    const fields = {
      'card_number': I18n.t("billing.fields.card_number"),
      'holder_name': I18n.t("billing.fields.holder_name"),
      'registry_code': I18n.t("billing.fields.registry_code"),
      'card_expiration': I18n.t("billing.fields.card_expiration"),
      'card_cvv': I18n.t("billing.fields.card_cvv"),
      'payment_company_code': I18n.t("billing.fields.payment_company_code"),
    }

    if (fields[field] === undefined) {
      return field;
    }

    return fields[field];
  }

  getCardFlag(cardnumber){
    let number = cardnumber.replace(/[^0-9]+/g, '');

    let cards = {
      visa              : /^4[0-9]{12}(?:[0-9]{3})/,
      mastercard        : /^5[1-5][0-9]{14}/,
      diners_club       : /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      american_express  : /^3[47][0-9]{13}/,
      discover          : /^6(?:011|5[0-9]{2})[0-9]{12}/,
      hipercard         : /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      elo               : /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    };

    for (let flag in cards) {
      if(cards[flag].test(number)) {
        return flag;
      }
    }

    return false;
  }

  isValidCPF(cpf) {
    if (!cpf || cpf.length === 0 ) return true;
    if (typeof cpf !== 'string') return false;

    cpf = this.onlyNumber(cpf);

    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
    cpf = cpf.split('').map(el => +el);

    const rest = (count) => (cpf.slice(0, count-12)
      .reduce( (soma, el, index) => (soma + el * (count-index)), 0 )*10) % 11 % 10
    return rest(10) === cpf[9] && rest(11) === cpf[10]
  }

  handleInputFocus = (e, name) => {
    this.setState({ focus: name });
  }

  handleInputChange = (e, name) => {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  maskCc = (e) => {
    const el = e.target;
    let value = el.value;
    value = this.onlyNumber(value);

    if (value) {
      let group = value.match(/.{1,4}/g);
      el.value = group.join(' ');
    }
  }

  success_redirect = () => {
    let is_update = this.props.updatePayment

    setTimeout(function () {
      if (is_update) {
        return location.href = "/billing";
      }

      location.href = "/billing/success"
    }, 3000);
  }

  save_credit_card = (formData, payment_token) => {
    let url = "/payments/save_credit_card_data";

    if (this.props.updatePayment) {
      url = "/payments/change_credit_card_data";
    }

    axios.post(url, {
      magic_key: this.props.magic_key,
      company_id: this.props.companyId,
      registry_code: formData.get('registry_code'),
      payment_token: payment_token,
      plan_price_id: this.props.plan["id"],
    })
      .then(() => {
        swal("", I18n.t("billing.notification.payment_saved_successfully"), "success");

        this.success_redirect();
      })
      .catch((error) => {
        swal("", I18n.t("billing.notification.error_processing_payment") + " " +  error.response.data.error, "error");
      })
      .then(() => {
        this.setState({showLoader: false});
    });
  }

  create_or_update_signature = (formData) => {
    axios.post(this.getVindiUrl(), {
      "holder_name": formData.get("full_name"),
      "card_expiration": formData.get("expiration"),
      "allow_as_fallback": true,
      "card_number": this.onlyNumber(formData.get("number")),
      "registry_code": formData.get("registry_code"),
      "card_cvv": formData.get("verification_value"),
      "payment_company_code": this.getCardFlag((formData).get("number")),
      "payment_method_code": "credit_card",
    }, {
      headers: {"Authorization": `Basic ${btoa(this.getVindiToken())}`}
    }).then((response) => {
      let payment_token = response.data.payment_profile.gateway_token

      this.save_credit_card(formData, payment_token);
    }).catch((error) => {
      let error_msg = this.prepareVindiErrors(error.response.data.errors)

      if (error_msg === "") {
        error_msg = error.response.data.errors
      }

      swal("", I18n.t("billing.notification.unable_to_save_card").replace("%{error}", error_msg), "error");
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    let form = event.target;
    let formData = new FormData(form);

    if (!this.isValidCPF(formData.get("registry_code"))) {
      swal("", I18n.t("billing.notification.cpf_informed_is_invalid"), "error");

      return this.setState({showLoader: false});
    }

    if (this.state.showLoader) return;

    this.setState({showLoader: true});

    this.create_or_update_signature(formData);
  }

  render() {
    return (
      <div id="PaymentForm" className="container credit-card-form">
        <Cards
          cvc={this.state.cvc}
          expiry={this.state.expiry}
          focused={this.state.focus}
          name={this.state.name}
          number={this.state.number}
          acceptedCards={['visa', 'mastercard', 'elo', 'american_express', 'hipercard','diners_club']}
          placeholders={{name: I18n.t("billing.modal.payment.credit_card.name_print")}}
          locale={{valid: I18n.t("billing.modal.payment.credit_card.shelf_life")}}
        />
        <form id="payment-form" onSubmit={event => this.handleSubmit(event)}>
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <input
                autoComplete="off"
                type="tel"
                name="number"
                id="number"
                maxLength="19"
                className="form-control"
                placeholder={I18n.t("billing.modal.payment.credit_card.card_number")}
                onKeyUp={e => this.maskCc(e)}
                onChange={e => this.handleInputChange(e, 'number')}
                onFocus={e =>this.handleInputFocus(e, 'number')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <input
                type="tel"
                autoComplete="off"
                name="full_name"
                id="full_name"
                maxLength="40"
                className="form-control"
                placeholder={I18n.t("billing.modal.payment.credit_card.name")}
                onChange={e => this.handleInputChange(e, 'name')}
                onFocus={e =>this.handleInputFocus(e, 'name')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-7">
              <input
                type="tel"
                name="expiration"
                id="expiration"
                autoComplete="off"
                placeholder={I18n.t("billing.modal.payment.credit_card.shelf_life")}
                className="form-control date"
                onChange={e => this.handleInputChange(e,'expiry')}
                onFocus={e => this.handleInputFocus(e,'expiry')}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-5">
              <input
                type="tel"
                name="verification_value"
                autoComplete="off"
                id="verification_value"
                placeholder={I18n.t("billing.modal.payment.credit_card.cvv")}
                maxLength="4"
                className="form-control"
                onChange={e => this.handleInputChange(e,'cvc')}
                onFocus={e => this.handleInputFocus(e,'cvc')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <input
                autoComplete="off"
                type="tel"
                name="registry_code"
                id="registry_code"
                maxLength="14"
                className="form-control mask-cpf"
                placeholder={I18n.t("billing.modal.payment.credit_card.cpf")}
                onChange={e => this.handleInputChange(e, 'registry_code')}
                onFocus={e => this.handleInputFocus(e, 'registry_code')}
              />
            </div>
          </div>
          {!this.state.showLoader &&
          <button className='billing-btn' type='submit'>
            {this.props.updatePayment &&
            <span>{I18n.t("billing.modal.payment.credit_card.button_update")} <i className="fe fe-arrow-right"/></span>
            }
            {!this.props.updatePayment &&
            <span>{I18n.t("billing.modal.payment.credit_card.button_confirm")} <i className="fe fe-arrow-right"/></span>
            }
          </button>
          }
          {this.state.showLoader &&
            <div className='loader'/>
          }
        </form>
      </div>
    );
  }
}
