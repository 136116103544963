import React, { useEffect, useRef, useState } from "react";

import useIconByInputType from './hooks/useIconByInputType';

import DeleteButton from "../ScriptEditorParts/DeleteButton";
import PermissionsGate from "@/components/permissions/PermissionsGate";
import InputTypeSelector from "./InputTypeSelector";
import SelectPhoneDialCode from "@/components/shared/SelectPhoneDialCode";
import HelpPopover from "@/components/shared/HelpPopover";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from "use-context-selector";
import { can } from "@/permissions-provider";

import { selectAllLabels } from "../ScriptEditor/scriptFieldLabels";
import {
  nameToAttributes,
  attributesToPrefix,
  nameToSuffix,
  removeItemFromArray
} from "../ScriptEditorParts/script-utils";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const ScriptBlockSave = (props) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);
  const mobileRequiredFields = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.mobileContent.requiredFields);
  const desktopRequiredFields = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.desktopContent.requiredFields);

  const modalRef = useRef();
  const nameRef = useRef();
  const nosyncRef = useRef();
  const requiredRef = useRef();

  let dispatchActionType = "SET_DESKTOP_CONVOSCRIPT_VALUE";
  let requiredFields = (desktopRequiredFields || []).filter(Boolean);

  if (props.isMobile) {
    dispatchActionType = "SET_MOBILE_CONVOSCRIPT_VALUE";
    requiredFields = (mobileRequiredFields || []).filter(Boolean);
  }

  const [onlyweekdays, setOnlyWeekdays] = useState(props.definition.onlyweekdays || true);
  const [datePast, setDatePast] = useState(props.definition.datePast || true);
  const [formatDate, setFormatDate] = useState(props.definition.formatDate || "");

  const [currentName, setCurrentName] = useState(props.definition.save || "");
  const [tmpName, setTmpName] = useState(props.definition.save || "");

  const [type, setType] = useState(props.definition.type);
  const [labelAlreadyExist, setLabelAlreadyExist] = useState(false);
  const [isRequiredField, setIsRequiredField] = useState(
    requiredFields.indexOf(props.definition.save || "") >= 0
  );

  const [phoneDialCode, setPhoneDialCode] = useState(props.definition.phoneDialCode || null);

  const inputTypeIconClass = useIconByInputType(type);

  useEffect(() => {
    updateDynamicField(currentName);
  }, []);

  const updateDynamicField = (value) => {
    dispatch({
      type: "SET_DYNAMIC_FIELD",
      payload: {
        key: props.id.replace(/^\D+/g, ""),
        value: value,
      },
    });
  };

  const getCurrentName = () => {
    const attributes = nameToAttributes(tmpName);

    attributes["nosync"] = nosyncRef.current
      ? nosyncRef.current.checked
      : false;

    return attributesToPrefix(attributes) + nameToSuffix(tmpName);
  };

  const saveSettings = () => {
    if (!hasPermission) return;
    if (isInvalidName()) return;

    const newName = getCurrentName().trim();
    const isRequiredField = requiredRef.current.checked;

    let updatedRequiredFields = requiredFields.filter(
      (fieldName) => fieldName !== currentName
    );

    if (isRequiredField) {
      updatedRequiredFields.push(newName);
    }

    setTmpName(newName);
    setCurrentName(newName);
    updateDynamicField(newName);
    setIsRequiredField(isRequiredField);

    props.setDefinition({
      ...props.definition,
      save: newName,
      type: type,
      onlyweekdays: onlyweekdays,
      datePast: datePast,
      formatDate: formatDate,
      phoneDialCode: phoneDialCode,
    });

    props.globalKeySet('requiredFields', updatedRequiredFields);

    dispatch({
      type: dispatchActionType,
      payload: {
        key: 'requiredFields',
        value: updatedRequiredFields
      }
    });

    $(nameRef.current).val(newName);
    $(modalRef.current).modal("hide");
  };

  const onSelectType = (value) => {
    if (!hasPermission) return;

    const def = {
      ...props.definition,
      type: value,
      save: getCurrentName().trim()
    };

    props.setDefinition(def);
    setType(value);
  };

  const handleInputName = (value) => {
    if (!hasPermission) return;

    const replacedValue = value.replace(/[^a-zA-Z\s]/g, "").slice(0, 50);
    const labels = selectAllLabels();
    const previewLabels = removeItemFromArray(labels, currentName);

    setTmpName(replacedValue);
    setLabelAlreadyExist(previewLabels.includes(replacedValue.trim()));
  };

  const isEmptyName = () => {
    return tmpName === "";
  };

  const isInvalidName = () => {
    return isEmptyName() || labelAlreadyExist;
  };

  const inputClassName = () => {
    const isInvalid = isInvalidName() ? "is-invalid" : "";
    return `form-control mb-0 ${isInvalid}`;
  };

  const nosync = nameToAttributes(currentName)["nosync"];

  return (
    <>
      <div className="row gx-2">
        <div className="col">
          <div className="input-group">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>{I18n.t("views.manage_flows.script_editor.click_here_to_edit_settings")}</Tooltip>
              }
            >
              <button
                type="button"
                className="btn btn-success pe-1"
                onClick={() => $(modalRef.current).modal("show")}
              >
                <i className={`ti ti-${inputTypeIconClass} me-2`} />
                {_.truncate(nameToSuffix(currentName || I18n.t("views.manage_flows.script_editor.response")), { length: 50 })}
                {isRequiredField && (<i className="fa fa-asterisk ms-2 text-warning" />)}
                <i className="ti ti-dots-vertical ms-1" />
              </button>
            </OverlayTrigger>

            <PermissionsGate scope={PERMISSION_SCOPE}>
              <InputTypeSelector defaultType={type} onSelectType={onSelectType} />
            </PermissionsGate>
          </div>
        </div>

        <div className="col-auto">
          <DeleteButton onDelete={props.onDelete} />
        </div>
      </div>
      <div ref={modalRef} className="modal modal-blur fade" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <h5 className="modal-title">
              {I18n.t("views.manage_flows.script_editor.user_response_settings")}
              </h5>
            </div>

            <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon>
              <div className="modal-body py-3">
                <div>
                  <label className="form-label fw-medium">
                    {I18n.t("views.manage_flows.script_editor.field_name")}
                    <HelpPopover
                      className="ms-2"
                      content={I18n.t("views.manage_flows.script_editor.this_field_does_not_accept_accents")}
                    />
                  </label>

                  <input
                    type="text"
                    className={inputClassName()}
                    onChange={(e) => handleInputName(e.target.value)}
                    value={tmpName}
                    ref={nameRef}
                    maxLength="50"
                    placeholder={I18n.t("views.manage_flows.script_editor.name_of_this_field")}
                  />

                  <div className="invalid-feedback mt-2">
                    {isEmptyName() && (<div>{I18n.t("views.manage_flows.script_editor.field_name_cannot_be_blank")}</div>)}
                    {labelAlreadyExist && (<div>{I18n.t("views.manage_flows.script_editor.another_field_with_this_name_already_exists")}</div>)}
                  </div>
                </div>

                <div className="mt-3">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input form-check-green"
                      type="checkbox"
                      ref={requiredRef}
                      defaultChecked={isRequiredField}
                    />
                    <span className=" mb-3 input-group text-body fw-medium">
                      {I18n.t("views.manage_flows.script_editor.required_field")}
                    </span>
                  </div>

                  <div className="border rounded p-2 small">
                    {I18n.t("views.manage_flows.script_editor.leads_that_dont_answer_your_question")}
                  </div>
                </div>

                {type === "password" && (
                  <div className="mt-3">
                    <label className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        ref={nosyncRef}
                        defaultChecked={nosync}
                      />
                      <span className="form-check-label fw-medium">
                        {I18n.t("views.manage_flows.script_editor.do_not_store_this_information")}
                      </span>
                    </label>

                    <div className="form-text text-body">
                    {I18n.t("views.manage_flows.script_editor.by_choosing_not_to_store_this_information")}
                    </div>
                  </div>
                )}

                {type === "date" && (

                  <div className="mt-3">
                    <div className="col-12 col-md-12 fw-medium p-2">
                      <label className="form-label" htmlFor="formatDateSelect">
                        {I18n.t("views.manage_flows.script_editor.date.select.format.title")}
                        <span
                          className="form-help ms-2"
                          data-bs-toggle="popover"
                          data-bs-placement="top"
                          data-bs-trigger="hover"
                          data-bs-content={I18n.t("views.manage_flows.script_editor.date.select.format.popover")}
                        >
                          <i className="fa fa-info" />
                        </span>
                      </label>
                      <select
                        className="form-select fw-medium"
                        id="formatDateSelect"
                        value={formatDate}
                        onChange={(e) => setFormatDate(e.target.value)}
                      >
                        <option value="pt-BR">{I18n.t("views.manage_flows.script_editor.date.select.format.option_1")}</option>
                        <option value="en">{I18n.t("views.manage_flows.script_editor.date.select.format.option_2")}</option>
                      </select>
                    </div>

                    <div className="col-12 col-md-12 fw-medium p-2">
                      <label className="form-label" htmlFor="onlyweekdaysSelect">
                        {I18n.t("views.manage_flows.script_editor.date.select.weekdays.title")}
                        <span
                          className="form-help ms-2"
                          data-bs-toggle="popover"
                          data-bs-placement="top"
                          data-bs-trigger="hover"
                          data-bs-content={I18n.t("views.manage_flows.script_editor.date.select.weekdays.popover")}
                        >
                          <i className="fa fa-info" />
                        </span>
                      </label>
                      <select
                        className="form-select fw-medium"
                        id="onlyweekdaysSelect"
                        value={onlyweekdays}
                        onChange={(e) => setOnlyWeekdays(e.target.value === "true")}
                      >
                        <option value={false}>{I18n.t("views.manage_flows.script_editor.date.select.weekdays.option_1")}</option>
                        <option value={true}>{I18n.t("views.manage_flows.script_editor.date.select.weekdays.option_2")}</option>
                      </select>
                    </div>

                    <div className="col-12 col-md-12 fw-medium p-2">
                      <label className="form-label" htmlFor="datePastSelect">
                        {I18n.t("views.manage_flows.script_editor.date.select.datepast.title")}
                        <span
                          className="form-help ms-2"
                          data-bs-toggle="popover"
                          data-bs-placement="top"
                          data-bs-trigger="hover"
                          data-bs-content={I18n.t("views.manage_flows.script_editor.date.select.datepast.popover")}
                        >
                          <i className="fa fa-info" />
                        </span>
                      </label>
                      <select
                        className="form-select fw-medium"
                        id="datePastSelect"
                        value={datePast}
                        onChange={(e) => setDatePast(e.target.value === "true")}
                      >
                        <option value={false}>{I18n.t("views.manage_flows.script_editor.date.select.datepast.option_1")}</option>
                        <option value={true}>{I18n.t("views.manage_flows.script_editor.date.select.datepast.option_2")}</option>
                      </select>
                    </div>
                  </div>
                )}

                {
                  type === "intl_phone" ? (
                    <div className="mt-3">
                      <SelectPhoneDialCode initialValue={phoneDialCode} onSelectDialCode={setPhoneDialCode} />
                    </div>
                  ) : ( null )
                }
              </div>
            </PermissionsGate>

            <div className="modal-footer">
              <button
                type="button"
                className="btn me-auto"
                onClick={() => $(modalRef.current).modal("hide")}
              >
                {I18n.t("shared.actions.cancel")}
              </button>

              <PermissionsGate scope={PERMISSION_SCOPE}>
                <button
                  type="button"
                  className="btn btn-primary btn-rounded"
                  disabled={isInvalidName()}
                  onClick={saveSettings}
                >
                  {I18n.t("shared.actions.save")}
                </button>
              </PermissionsGate>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ScriptBlockSave, () => true);
