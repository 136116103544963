import React from "react";

import useCurrentUserStore from "@/store/currentUserStore";

const ProfileStore = (userData) => {
  const setCurrentUser = useCurrentUserStore((state) => state.setCurrentUser);

  setCurrentUser(userData);

  return <></>;
};

export default ProfileStore;
