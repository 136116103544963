import React, { useState } from "react";

import parse from "html-react-parser";

import Modal from "@/components/utils/Modal";
import ScheduleDemo from "@/components/implementation/FirstDemo/ScheduleDemo";

import {
  Button,
  ButtonGroup,
  Icon,
} from "@/components/implementation/FirstDemo/PreviewOnScreen/styles";

const QualifiedScheduling = ({ redirectUrl, dataLeadScheduling, scheduleDemo, trialDays }) => {

  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => {
    setShowModal(true);
  }

  const handleModalClose = () => {
    setShowModal(false);
  }

  return (
    <>
      {parse(I18n.t("views.onboard.hello.scheduling_demo.title_html" , { first_name: dataLeadScheduling.userName }))}

      <p className="lead mb-2">
        {
          parse(I18n.t("views.onboard.hello.scheduling_demo.text_html", {
            web_site: dataLeadScheduling.webSite,
            access: dataLeadScheduling.access,
            segment: dataLeadScheduling.segment,
          }))
        }
      </p>
      <div className="row mt-4">
        <ButtonGroup>
          <Button
            className="btn-primary"
            type="button"
            onClick={handleModalOpen}
          >
            <i className="ti ti-message-2-star" />
            {I18n.t("views.onboard.hello.scheduling_demo.btn_talk_specialist")}
          </Button>

          <Icon
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            data-bs-html="true"
            title={I18n.t("views.onboard.hello.scheduling_demo.tooltip_talk_specialist_html")}
          />
        </ButtonGroup>

        <ButtonGroup>
          <Button
            as="a"
            href={redirectUrl}
            type="button"
            className="text-muted"
          >
            <i className="ti ti-compass" />
            {I18n.t("views.onboard.hello.scheduling_demo.btn_explore_platform")}
          </Button>

          <Icon
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            data-bs-html="true"
            title={
              I18n.t("views.onboard.hello.scheduling_demo.tooltip_explore_platform_html", { days: trialDays })
            }
          />
        </ButtonGroup>
        <div className="mt-3 text-secondary fs-3">
          <i className="ti ti-bulb mr-2" />
          <span>{I18n.t("views.onboard.hello.scheduling_demo.text_end")}</span>
        </div>

        {showModal && (
          <Modal onClose={handleModalClose}>
            <ScheduleDemo dataLeadScheduling={dataLeadScheduling} scheduleDemo={scheduleDemo}/>
          </Modal>
        )}
      </div>
    </>
  )
}

export default QualifiedScheduling
