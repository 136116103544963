import { useEffect, useState } from 'react';
import axios from "packs/axios";

const useResponseTracker = () => {
  const [limitResponses, setLimitResponses] = useState(0);
  const [consumedResponses, setConsumedResponses] = useState(0);
  const [reachedLimit, setReachedLimit] = useState(true);

  useEffect(() => {
    const fetchResponseTrackerData = async () => {
      try {
        const response = await axios.get("/ai/leadster/total-responses")
        const { limit, consumed } = response.data;
        setLimitResponses(limit);
        setConsumedResponses(consumed);
        setReachedLimit(consumed >= limit);
      } catch (error) {
        toastr.error(I18n.t("views.mark_ia.page.response_limit.messages.error"));
      }
    };

    fetchResponseTrackerData();

  }, []);

  return {
    limitResponses,
    consumedResponses,
    reachedLimit,
  };
};

export default useResponseTracker;
