import create from "zustand";

const currentUserStore = create((set) => ({
  currentUser: {
    id: null,
    firstName: "",
    lastName: "",
    email: "",
    companyId: "",
    role: "default",
    locale: "pt-BR",
    timeZone: "America/Sao_Paulo",
    isInternalAccount: false,
    isNational: true,
    isTrialing: false,
    isPaid: false,
    isFree: false,
    isImplemented: false,
    isAdministrator: false,
    isResellerOwner: false,
    resellerWhiteLabelEnabled: false,
    resellerWhiteLabelLogo: "",
    applicationName: "Leadster",
    alreadyUsedTrial: false,
    plugins: {
      calendarSchedule: {
        isPurchased: false,
        maxRepresentativesAllowed: 0,
        freeTrialDays: 0,
        freeTrialEndAt: null,
        freeTrialStarted: false,
        freeTrialEnded: false,
      },
      leadsterAi: {
        isPurchased: false,
        isTrialStarted: false,
        isTrialing: false,
        isTrialEnded: false,
        isSignatureEnded: false,
        trialStartedAt: null,
        trialEndAt: null,
        freeTrialDays: 0,
        freeQuestions: 0,
      },
    },
    abilities: {},
  },

  setCurrentUser: (currentUser) => set({ currentUser }),
  applicationLogo: (defaultLogo) => {
    const { resellerWhiteLabelEnabled, resellerWhiteLabelLogo } = currentUserStore.getState().currentUser;

    return resellerWhiteLabelEnabled ? (resellerWhiteLabelLogo || defaultLogo) : defaultLogo;
  }
}));

export default currentUserStore;
