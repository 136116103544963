import React, { useState, useCallback } from 'react';
import CompanyAnalytics from '../CompanyAnalytics';
import CompanyBadges from '../CompanyBadges';
import swal from 'sweetalert';
import axios from "packs/axios";

const CompanyRow = ({ name, id, reseller_agency_owner = false }) => {
  const [stepOpened, setStepOpened] = useState(true);

  const updateOpened = () => {
    setStepOpened(!stepOpened);
  };

  const removeCompany = useCallback(async (e) => {

    const willDelete = await swal({
      title: I18n.t("owners.company_removal_modal.message.title"),
      text: I18n.t("owners.company_removal_modal.message.text"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (willDelete) {
      try {
        await axios.delete(`/owner/company/${id}`);
        window.location.reload();
      } catch(e) {
        swal(
          I18n.t("owners.company_removal_modal.error.title"),
          I18n.t("owners.company_removal_modal.error.text"),
          'error'
        );
      }
    }
  }, []);

  return (
    <div className="card mb-4">
      <div className="card-header d-flex">
        <div className="dropdown">
          <div id={`CompanyDropdownMenu${id}`} data-bs-toggle="dropdown" aria-expanded="false">
            <i className="icon ti ti-dots-vertical"></i>
          </div>
          <ul className="dropdown-menu" aria-labelledby={`CompanyDropdownMenu${id}`}>
            <li><a className="dropdown-item" onClick={removeCompany} href="#">Remover</a></li>
          </ul>
        </div>
        <h3 className="card-title">{name}</h3>
        <div className='me-auto'>
          <CompanyBadges company_id={id} />
        </div>
        {reseller_agency_owner ? (
          <a className="btn btn-primary ms-2" href={`/owners/switch/${id}`}>
            {I18n.t("devise.shared.links.get_in")}
          </a>
        ) : (
          <a className="btn btn-primary ms-2" href={`/owner/login_in_company/${id}`}>
            {I18n.t("devise.shared.links.get_in")}
          </a>
        )}
        <div className="px-3" onClick={updateOpened}>
          <i className={`float-ri ti ti-chevron-${stepOpened ? 'up' : 'down'}`}></i>
        </div>
      </div>
      <div className={`card-body ${stepOpened ? "d-block" : "d-none"}`} >
        <CompanyAnalytics company_id={id} isReseller={reseller_agency_owner} />
      </div>
    </div>
  )
}

export default CompanyRow
