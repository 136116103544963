import styled from "styled-components";

export const SelectorCheckbox = styled.div`
  transition: all 0.2s ease;

  background-color: ${({ isInvalid }) => isInvalid
    ? "rgba(var(--tblr-danger-rgb), 0.1)"
    : "rgba(var(--tblr-primary-rgb), 0.04)"
  };

  border-color: ${({ isInvalid }) => isInvalid
    ? "var(--tblr-danger)"
    : "var(--tblr-border-color)"
  };

  &:hover {
    cursor: pointer !important;
    background: ${({ isInvalid }) => isInvalid
      ? "rgba(var(--tblr-danger-rgb), 0.15)"
      : "rgba(var(--tblr-primary-rgb), 0.08)"
    };
  }

  &.selected {
    background-color: ${({ isInvalid }) => isInvalid
      ? "rgba(var(--tblr-danger-rgb), 0.05)"
      : "rgba(var(--tblr-primary-rgb), 0.05)"};

    border-color: ${({ isInvalid }) => isInvalid
      ? "var(--tblr-danger)"
      : "var(--tblr-primary)"
    };
  }
`;
