import React, { useState } from "react";
import PropTypes from "prop-types";

import axios from "@/packs/axios";
import swal from "sweetalert";

const ScriptToDuplicate = ({
  pageUrl,
  callId,
  script,
  newConversionCall,
  suggestedConversionCall,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDuplicateScript = async () => {
    setIsLoading(true);

    const payload = {
      script_id: script.id,
      page_url: pageUrl,
      call_id: callId,
      new_conversion_call: newConversionCall,
      suggested_conversion_call: suggestedConversionCall,
    };

    axios.post("/page-ranks/duplicate-script", payload)
      .then(({ data }) => {
        swal(I18n.t("views.page_rank.rank_table.data.modal_create.card_clone_flow_default.clone_modal.success_duplicate_flow"), "", "success");
        setTimeout(() => {
          window.location = `/scripts/${data.id}/edit?referrer_action=duplicate-script`;
        }, 1500);
      })
      .catch(() => {
        swal(I18n.t("request_feedback_alert.error.title"), I18n.t("request_feedback_alert.error.message"), "error");
        setIsLoading(false);
      })
  };

  return (
    <div className="card flow-item mb-3">
      <div className="card-body row row-cards">
        <div className="col-lg-auto d-flex align-items-center">
          <label className="form-check form-switch mb-0">
            <input className="form-check-input" type="checkbox" checked={script.enabled} onChange={() => { }} />
          </label>

          <div className="h3 mb-0 d-inline-block">
            <span
              className="badge bg-muted-lt text-body fw-medium p-2"
              data-bs-toggle="tooltip"
              title={script.name}
            >
              {_.truncate(script.name, { length: 25 })}
            </span>
          </div>
        </div>

        <div className="col-lg align-items-center">
          <div className="d-flex align-items-center">
            <div
              className="avatar avatar-sm avatar-rounded"
              style={{ backgroundImage: `url('${script.avatar}')` }}
            />
            <div className="text-muted ms-2 max-text-list">
              <div className="d-flex align-items-center">
                <i className="ti ti-devices me-1"></i>
                {script.conversion_call}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-auto text-center">
          <button
            type="button"
            className="btn btn-primary"
            disabled={isLoading}
            onClick={handleDuplicateScript}
          >
            {isLoading && <span className="spinner-border spinner-border-sm me-2" />}

            <i className="ti ti-copy icon" />
            {I18n.t("views.page_rank.rank_table.data.modal_create.card_clone_flow_default.clone_modal.duplicate_flow")}
          </button>
        </div>
      </div>
    </div>
  );
};

ScriptToDuplicate.propTypes = {
  pageUrl: PropTypes.string.isRequired,
  callId: PropTypes.number.isRequired,
  script: PropTypes.object.isRequired,
  newConversionCall: PropTypes.string.isRequired,
  suggestedConversionCall: PropTypes.string.isRequired,
};

export default ScriptToDuplicate;
