import React from "react";

import TidioButton from "./TidioButton";
import WhatsAppButton from "./WhatsAppButton";

import useCurrentUserStore from "@/store/currentUserStore";

const HireFeatureButton = () => {
  const { isNational } = useCurrentUserStore((state) => state.currentUser);

  const ContactButton = isNational ? WhatsAppButton : TidioButton;

  return (
    <ContactButton>
      <i className="ti ti-message icon"></i>
      {I18n.t("views.leadster_ai.page.trial_alerts.contact_button.value")}
    </ContactButton>
  );
};

export default HireFeatureButton;
