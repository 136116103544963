import React from "react";

import { Stack } from "react-bootstrap";

import Content from "./components/Content";

import { useAssistantContext } from "@/contexts/AssistantContext";

const ContentList = () => {
  const { assistant } = useAssistantContext();

  const activeKnowledgeBases = assistant.active_knowledge_bases;

  return (
    <Stack direction="vertical" gap={2}>
      <label className="form-label">
        {I18n.t("views.leadster_ai.page.add_content.content_list.title", {
          total: activeKnowledgeBases.length,
        })}
      </label>

      {activeKnowledgeBases?.map((knowLeadgeBase) => {
        return (
          <Content key={knowLeadgeBase.id} knowLeadgeBase={knowLeadgeBase} />
        );
      })}
    </Stack>
  );
};

export default ContentList;
