import React, { useRef, useState } from "react";

import ReactPortal from '@/components/ReactPortal';

import swal from "sweetalert";
import axios from "@/packs/axios"

import { classBinding } from '@/utils/helpers';

const StartTrialButton = ({
  buttonText,
  allowedSegments = [],
  allowedBusinessModels = [],
  aditionalClass = ""
}) => {
  const modalRef = useRef();

  const [selectedSegment, setSelectedSegment] = useState("");
  const [selectedBusinessModel, setSelectedBusinessModel] = useState("");
  const [hasError, setHasError] = useState(false);

  const openModal = () => $(modalRef.current).modal("show");

  const handleStartTrial = () => {
    setHasError(false);

    if (!allowedSegments.includes(selectedSegment)) {
      return setHasError(true);
    }

    if (!allowedBusinessModels.includes(selectedBusinessModel)) {
      return setHasError(true);
    }

    const payload = {
      segment: selectedSegment,
      business_model: selectedBusinessModel,
    };

    axios.post("/whatsapp-account/start-free-trial", payload)
      .then(() => {
        swal("Trial ativado com sucesso!", "Você acaba de ativar seus 14 dias de teste grátis!", "success");
        setTimeout(() => {
          document.location.href = "/";
        }, 1000);
      })
      .catch(() => {
        setButtonDisabled(false);
        swal("Ops!", "Ocorreu um erro ao realizar a operação. Por favor, tente novamente.", "warning");
      });
  };

  return (
    <>
      <button
        type="button"
        className={`btn btn-lg btn-primary ${aditionalClass}`}
        onClick={openModal}
      >
        {buttonText}
      </button>

      <ReactPortal>
        <div className="modal modal-blur fade" ref={modalRef} role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

              <div className="modal-body text-center">
                <img src="/images/subscriptions/select-segment.png" alt="Segmentos" className="img-fluid" />

                <div className="h1">
                  Só um passo para você iniciar seu Teste Grátis
                </div>

                <p className="lead">
                  Para iniciar o seu teste grátis com a Leadster, selecione o modelo de negócio e o segmento da sua empresa.
                </p>

                <div className="row">
                  <div className="form-group col-md-5 mb-3">
                    <select
                      className={classBinding(
                        { "is-invalid": hasError && !selectedBusinessModel },
                        "form-select text-center text-md-start"
                      )}
                      value={selectedBusinessModel}
                      onChange={(e) => setSelectedBusinessModel(e.target.value)}
                    >
                      <option value="">- Para quem você vende? -</option>
                      <option value="B2B">Vendo para Empresas</option>
                      <option value="B2C">Vendo para Pessoa Física</option>
                    </select>

                    {(hasError && !selectedBusinessModel) && (
                      <div className="text-danger text-sm mt-2">
                        Por favor, selecione um modelo de negócio.
                      </div>
                    )}
                  </div>

                  <div className="form-group col-md-7 mb-3">
                    <select
                      className={classBinding(
                        { "is-invalid": hasError && !selectedSegment },
                        "form-select text-center text-md-start"
                      )}
                      value={selectedSegment}
                      onChange={(e) => setSelectedSegment(e.target.value)}
                    >
                      <option value="">- Segmento da sua empresa -</option>
                      {allowedSegments.map((segment) => {
                        return <option key={segment} value={segment}>{segment}</option>
                      })}
                    </select>

                    {(hasError && !selectedSegment) && (
                      <div className="text-danger text-sm mt-2">
                        Por favor, selecione um segmento.
                      </div>
                    )}
                  </div>

                </div>


                <button
                  type="button"
                  className="btn btn-success py-2 fs-3 w-100"
                  onClick={handleStartTrial}
                >
                  Iniciar o teste grátis!
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReactPortal>
    </>
  );
};

export default StartTrialButton;
