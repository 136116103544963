import React, { useState, useEffect, useRef } from "react";

import DeleteButton from '../ScriptEditorParts/DeleteButton';
import PermissionsGate from '@/components/permissions/PermissionsGate';
import AvailabilitySchedule from '../AvailabilitySchedule';
import SellersSelector from './SellersSelector';
import AlertHandler from './AlertHandler';
import SettingsModal from './SettingsModal';
import RichTextEditor from "@/components/shared/RichTextEditor";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from "use-context-selector";
import { can } from "@/permissions-provider";

import axios from "@/packs/axios";
import parse from "html-react-parser";

const MAX_DESCRIPTION_SIZE = 1000;

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'calendar_schedule_plugin'
});

const ScriptBlockScheduler = (props) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);

  const [selectedRepresentativesIds, setSelectedRepresentativesIds] = useState(props.definition.representatives);
  const [subject, setSubject] = useState(props.definition.subject);
  const [emailField, setEmailField] = useState(props.definition.email_field);
  const [useCustomSchedule, setUseCustomSchedule] = useState(!!props.definition.use_custom_schedule);
  const [description, setDescription] = useState(props.definition.description);

  const [maxBookingDays, setMaxBookingDays] = useState(props.definition.max_booking_days);
  const [durationTime, setDurationTime] = useState(props.definition.duration_time);
  const [incrementTime, setIncrementTime] = useState(props.definition.increment_time);
  const [toleranceTime, setToleranceTime] = useState(props.definition.tolerance_time);
  const [useBreakBefore, setUseBreakBefore] = useState(props.definition.use_break_before);
  const [timeBreakBefore, setTimeBreakBefore] = useState(props.definition.time_break_before);
  const [useBreakAfter, setUseBreakAfter] = useState(props.definition.use_break_after);
  const [timeBreakAfter, setTimeBreakAfter] = useState(props.definition.time_break_after);

  const [label, setLabel] = useState(props.definition.label);
  const [introductionText, setIntroductionText] = useState(props.definition.introduction_text);
  const modalSettingsRef = useRef();

  const scriptEmailFields = [];
  props.mapAttributesName(props.getFullTree(), scriptEmailFields, 'email');
  props.mapAttributesName(props.getFullTree(), scriptEmailFields, 'email_corp');

  const findRepresentatives = () => {
    if (!hasPermission) return;

    axios.get('/representatives/list-of-connected')
      .then(response => {
        const representatives = response.data.representatives;
        const alreadyConnected = response.data.already_connected;

        dispatch({ type: "SET_REPRESENTATIVES", payload: representatives });
        dispatch({ type: "SET_ALLOW_CONNECT_GOOGLE", payload: !alreadyConnected });
      })
  }

  const setDefinition = (newDefinition) => {
    if (!hasPermission) return;

    const mergedDefinition = Object.assign(props.definition, newDefinition);
    props.setDefinition(mergedDefinition);
  }

  const handleChangeField = (
    definitionKey,
    newStateValue,
    setStateAction = undefined
  ) => {
    if (!hasPermission) return;

    const newDefinitionValue = {};
    newDefinitionValue[definitionKey] = newStateValue;

    if (!setStateAction) {
      const dynamicSetterFunction = `set${definitionKey.charAt(0).toUpperCase()}${definitionKey.slice(1)}`;
      if (eval(`typeof ${dynamicSetterFunction} === 'function'`)) {
        setStateAction = eval(dynamicSetterFunction);
        setStateAction(newStateValue);
      }
    }

    if (typeof setStateAction !== 'function') {
      throw Error(`Could not find setter function for ${definitionKey}`);
    }

    setStateAction(newStateValue);
    setDefinition(newDefinitionValue);
  };

  const handleChangeDescription = (description, editor) => {
    if (!hasPermission) return;

    const descriptionRawValue = editor.getContent({ format: "text" });
    if (descriptionRawValue.length <= MAX_DESCRIPTION_SIZE) {
      handleChangeField('description', description, setDescription)
    }
  }

  useEffect(() => {
    findRepresentatives();
  }, [hasPermission]);

  useEffect(() => {
    window.initJqueryTooltip();
  }, [selectedRepresentativesIds]);

  useEffect(() => {
    if (emailField && !scriptEmailFields.includes(emailField)){
      setEmailField("");
      setDefinition({ email_field: "" });
    }
  }, [scriptEmailFields])

  return (
    <>
      <AlertHandler
        hasPermission={hasPermission}
        onDelete={props.onDelete}
      />

      <PermissionsGate scope={PERMISSION_SCOPE}>
        <div>
          <div className="row gx-2 mb-3">
            <div className="col">
              <div className="input-group input-group-flat">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>{I18n.t("views.manage_flows.script_editor.click_here_to_edit_settings")}</Tooltip>}
                >
                  <button
                    type="button"
                    className="btn btn-success pe-1"
                    onClick={() => $(modalSettingsRef.current).modal("show")}
                  >
                    <i className="ti ti-calendar-event me-2" />
                    {label}
                    <i className="ti ti-dots-vertical ms-2" />
                  </button>
                </OverlayTrigger>

                <input
                  type="text"
                  className="form-control"
                  value={subject}
                  maxLength={255}
                  onChange={(e) => handleChangeField('subject', e.target.value, setSubject)}
                  placeholder={I18n.t("views.manage_flows.scheduler_block.placeholder_subject")}
                />

                <SettingsModal
                  label={label}
                  introductionText={introductionText}
                  setIntroductionText={setIntroductionText}
                  setLabel={setLabel}
                  setDefinition={setDefinition}
                  hasPermission={hasPermission}
                  ref={modalSettingsRef}
                />
              </div>
            </div>

            <div className="col-auto">
              <DeleteButton
                onDelete={props.onDelete}
                permissionScope={PERMISSION_SCOPE}
              />
            </div>
          </div>

          <div>
            <SellersSelector
              selectedRepresentativesIds={selectedRepresentativesIds}
              setDefinition={setDefinition}
              findRepresentatives={findRepresentatives}
              setSelectedRepresentativesIds={setSelectedRepresentativesIds}
            />

            <div className="row g-3 mb-3">
              <div className='col-12 col-md-6 form-group'>
                <div className="form-label">
                  {I18n.t("views.manage_flows.scheduler_block.lead_email_label")}
                  <span className="form-help ms-2 d-none d-md-inline-flex" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover"
                    data-bs-content={I18n.t("views.manage_flows.scheduler_block.lead_email_hint")}
                  >?</span>
                </div>

                <select
                  className="form-select"
                  value={emailField}
                  onChange={(e) => handleChangeField('email_field', e.target.value, setEmailField)}
                >
                  <option value="">
                    {I18n.t(`views.manage_flows.scheduler_block.${scriptEmailFields.length ? 'not_selected' : 'empty_lead_email'}`)}
                  </option>
                  {scriptEmailFields.map((fieldName) => {
                    return <option value={fieldName} key={fieldName}>{fieldName}</option>
                  })}
                </select>
              </div>

              <div className='form-group col-md-6'>
                <div className="form-label">
                  {I18n.t("views.manage_flows.scheduler_block.duration_label")}
                  <span className="form-help ms-2 d-none d-md-inline-flex" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover"
                    data-bs-content={I18n.t("views.manage_flows.scheduler_block.duration_hint")}
                  >?</span>
                </div>

                <select
                  className="form-select"
                  value={durationTime}
                  onChange={(e) => handleChangeField('duration_time', e.target.value, setDurationTime)}
                >
                  {[15, 30, 45, 60, 90, 120, 150, 180].map((minutes) => {
                    return (
                      <option value={minutes} key={minutes}>
                        {minutes} {I18n.t("views.manage_flows.scheduler_block.minutes")}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>

            <div className='form-group mb-3'>
              <div className="form-label">
                {I18n.t("views.manage_flows.scheduler_block.availability_label")}
              </div>

              <div className="form-selectgroup form-selectgroup-boxes d-flex">
                <label className="form-selectgroup-item flex-fill">
                  <input
                    type="radio"
                    checked={!useCustomSchedule}
                    onChange={(e) => {
                      e.target.checked && handleChangeField('use_custom_schedule', false, setUseCustomSchedule)
                    }}
                    className="form-selectgroup-input"
                  />

                  <div className="form-selectgroup-label d-flex align-items-center p-3">
                    <div className="me-3">
                      <span className="form-selectgroup-check"></span>
                    </div>
                    <div>
                      {parse(I18n.t("views.manage_flows.scheduler_block.use_existing_schedule"))}
                      <div className="text-muted">
                        {I18n.t("views.manage_flows.scheduler_block.use_existing_schedule_hint")}
                      </div>
                    </div>
                  </div>
                </label>

                <label className="form-selectgroup-item flex-fill">
                  <input
                    type="radio"
                    checked={useCustomSchedule}
                    onChange={(e) => {
                      e.target.checked && handleChangeField('use_custom_schedule', true, setUseCustomSchedule)
                    }}
                    className="form-selectgroup-input"
                  />

                  <div className="form-selectgroup-label d-flex align-items-center p-3">
                    <div className="me-3">
                      <span className="form-selectgroup-check"></span>
                    </div>
                    <div>
                      {parse(I18n.t("views.manage_flows.scheduler_block.use_custom_schedule"))}
                      <div className="text-muted">
                        {I18n.t("views.manage_flows.scheduler_block.use_custom_schedule_hint")}
                      </div>
                    </div>
                  </div>
                </label>
              </div>

              {useCustomSchedule && (
                <AvailabilitySchedule
                  initialSchedules={props.definition.schedules || []}
                  setDefinition={(schedules) => setDefinition({ schedules })}
                />
              )}
            </div>

            <div className='form-group mb-3'>
              <div className="form-label">
                {I18n.t("views.manage_flows.scheduler_block.description_label")}
                <span className="form-help ms-2 d-none d-md-inline-flex" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover"
                  data-bs-content={I18n.t("views.manage_flows.scheduler_block.description_hint")}
                >?</span>
              </div>

              <RichTextEditor
                value={description}
                disabled={!hasPermission}
                inline={false}
                init={{
                  height: 150,
                  menubar: false,
                  placeholder: I18n.t("views.manage_flows.scheduler_block.description_placeholder"),
                  toolbar: 'bold italic underline',
                  force_br_newlines : false,
                  forced_root_block : 'div'
                }}
                onEditorChange={handleChangeDescription}
              />
            </div>

            <fieldset className="form-fieldset mt-3">
              <div className="row g-3 mb-3">
                <div className='form-group col-lg-4 col-md-6'>
                  <div className="form-label">
                    {I18n.t("views.manage_flows.scheduler_block.available_times_frequency")}
                    <span className="form-help ms-2 d-none d-md-inline-flex" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover"
                      data-bs-content={I18n.t("views.manage_flows.scheduler_block.availability_start_intervals")}
                    >?</span>
                  </div>

                  <select
                    className="form-select"
                    value={incrementTime}
                    onChange={(e) => handleChangeField('increment_time', e.target.value, setIncrementTime)}
                  >
                    {[15, 20, 30, 45, 60].map((minutes) => {
                      return (
                        <option value={minutes} key={minutes}>
                          {minutes} {I18n.t("views.manage_flows.scheduler_block.minutes")}
                        </option>
                      )
                    })}
                  </select>
                </div>

                <div className='form-group col-lg-4 col-md-6'>
                  <div className="form-label">
                    {I18n.t("views.manage_flows.scheduler_block.max_booking_days_label")}
                    <span className="form-help ms-2 d-none d-md-inline-flex" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover"
                      data-bs-content={I18n.t("views.manage_flows.scheduler_block.max_booking_days_hint")}
                    >?</span>
                  </div>

                  <select
                    className="form-select"
                    value={maxBookingDays}
                    onChange={(e) => handleChangeField('max_booking_days', e.target.value, setMaxBookingDays)}
                  >
                    {[7, 14, 30, 45, 60, 90].map((days) => {
                      return (
                        <option value={days} key={days}>
                          {days} {I18n.t("views.manage_flows.scheduler_block.days")}
                        </option>
                      )
                    })}
                  </select>
                </div>

                <div className='form-group col-lg-4 col-md-6'>
                  <div className="form-label">
                    {I18n.t("views.manage_flows.scheduler_block.tolerance_label")}
                    <span className="form-help ms-2 d-none d-md-inline-flex" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover"
                      data-bs-content={I18n.t("views.manage_flows.scheduler_block.tolerance_hint")}
                    >?</span>
                  </div>

                  <select
                    className="form-select"
                    value={toleranceTime}
                    onChange={(e) => handleChangeField('tolerance_time', e.target.value, setToleranceTime)}
                  >
                    {[15, 30, 45].map((minutes) => {
                      return <option value={minutes} key={minutes}>{minutes} {I18n.t("views.manage_flows.scheduler_block.minutes")}</option>
                    })}

                    {[60, 120, 240, 480, 720].map((minutes) => {
                      return <option value={minutes} key={minutes}>{(minutes / 60)} {I18n.t("views.manage_flows.scheduler_block.hours")}</option>
                    })}

                    {[1440, 2880].map((minutes) => {
                      return <option value={minutes} key={minutes}>{((minutes / 60) / 24)} {I18n.t("views.manage_flows.scheduler_block.days")}</option>
                    })}
                  </select>
                </div>
              </div>

              <div className="row g-3">
                <div className='form-group'>
                  <div className="form-label">
                    {I18n.t("views.manage_flows.scheduler_block.add_time_before_after_events")}
                    <span className="form-help ms-2 d-none d-md-inline-flex" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover"
                      data-bs-content={I18n.t("views.manage_flows.scheduler_block.block_time_around_events")}
                    >?</span>
                  </div>

                  <div className="row g-3">
                    <div className="col-md-4">
                      <label className="form-check form-switch d-flex">
                        <input
                          className="form-check-input me-2"
                          type="checkbox"
                          checked={useBreakBefore}
                          onChange={(e) => handleChangeField('use_break_before', e.target.checked, setUseBreakBefore)}
                        />

                        <span className="form-check-label">
                          {I18n.t("views.manage_flows.scheduler_block.before_event")}
                        </span>
                      </label>

                      <select
                        className="form-select"
                        value={timeBreakBefore}
                        disabled={!useBreakBefore}
                        onChange={(e) => handleChangeField('time_break_before', e.target.value, setTimeBreakBefore)}
                      >
                        {[5, 10, 15, 30, 45, 60].map((minutes) => {
                          return (
                            <option value={minutes} key={minutes}>
                              {minutes} {I18n.t("views.manage_flows.scheduler_block.minutes")}
                            </option>
                          )
                        })}
                      </select>
                    </div>

                    <div className="col-md-4">
                      <label className="form-check form-switch d-flex">
                        <input
                          className="form-check-input me-2"
                          type="checkbox"
                          checked={useBreakAfter}
                          onChange={(e) => handleChangeField('use_break_after', e.target.checked, setUseBreakAfter)}
                        />

                        <span className="form-check-label">
                          {I18n.t("views.manage_flows.scheduler_block.after_event")}
                        </span>
                      </label>

                      <select
                        className="form-select"
                        value={timeBreakAfter}
                        disabled={!useBreakAfter}
                        onChange={(e) => handleChangeField('time_break_after', e.target.value, setTimeBreakAfter)}
                      >
                        {[5, 10, 15, 30, 45, 60].map((minutes) => {
                          return (
                            <option value={minutes} key={minutes}>
                              {minutes} {I18n.t("views.manage_flows.scheduler_block.minutes")}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </PermissionsGate>
    </>
  );
}

export default ScriptBlockScheduler;
