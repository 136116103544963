import React from "react";

import DeleteButton from "../ScriptEditorParts/DeleteButton";
import PermissionsGate from '@/components/permissions/PermissionsGate';

import AlertHandler from "./AlertHandler";
import AssistantSelector from "./AssistantSelector";
import AssistantSettings from "./AssistantSettings";
import AssistantInputMessage from "./AssistantInputMessage";

import GuideModal from "@/components/shared/GuideModal";
import guideContents from "./guideContents";

import { can } from "@/permissions-provider"

const PERMISSION_SCOPE = Object.freeze({
  action: "manage",
  subject: "leadster_ai_plugin",
});

const ScriptBlockIaInteraction = (props) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const definition = (def) => {
    const mergedDef = Object.assign(props.definition, def);
    props.setDefinition(mergedDef);
  };

  return (
    <>
      <GuideModal
        storageKey="leadster-ai-block-info"
        guideContents={guideContents}
        finishButtonText={I18n.t('guide_modal.alright_button')}
      />

      <AlertHandler onDelete={props.onDelete} />

      <PermissionsGate scope={PERMISSION_SCOPE}>
        <div>
          <div className="row gx-2 mb-3">
            <div className="col">
              <div className="input-group">
                <span className="input-group-text bg-blue text-white">
                  <i className="ti ti-message-chatbot me-2"></i>
                  {props.definition.label}
                </span>

                <AssistantInputMessage
                  onDefinition={definition}
                  hasPermission={hasPermission}
                  conversationId={props.id}
                  say={props.definition.say}
                />
              </div>
            </div>

            <div className="col-auto">
              <DeleteButton onDelete={props.onDelete} />
            </div>
          </div>

          <AssistantSelector
            onDefinition={definition}
            hasPermission={hasPermission}
            assistantId={props.definition.botId}
          />

          <AssistantSettings
            onDefinition={definition}
            hasPermission={hasPermission}
            blockDefinitions={props.definition}
          />
        </div>
      </PermissionsGate>
    </>
  );
};

export default ScriptBlockIaInteraction;
