import React, { useState } from 'react';
import axios from "packs/axios";
import swal from 'sweetalert'
import parse from "html-react-parser";

import {
  StepNumber,
  StepChecked,
  Card,
  CardBody,
  Label,
  Video,
  Description,
  CheckedButton,
  NotCheckedButton,
} from './styles';

const OptimizationStep = ({
  label,
  id,
  name,
  number,
  iframeUrl = '',
  description = '',
  nextStep = '',
  checked = false,
  opened = false,
  componentDescription = null
}) => {
  const [stepChecked, setStepCheked] = useState(checked);
  const [stepOpened, setStepOpened] = useState(opened);

  const divId = name;
  const divCheckId = `check-${divId}`;


  const toggleStep = () => {
    try {
      const data = { [`${name}`]: true };
      const url = `/optimization/${id}`;
      axios.put(url, data);

      hideCurrentStep();

      if (!!nextStep) {
        hideNextStep();
      }
    } catch (_) {
      swal("", I18n.t("customer_success.optimization_container.optimization_step.an_error_has_occurred"), 'error');
    }
  }

  const hideCurrentStep = () => {
    $(`#${divId}`).attr('class', 'd-none');
    $(`#${divCheckId}`).attr('class', 'd-none');
  };

  const hideNextStep = () => {
    $(`#${nextStep}`).attr('class', 'd-block');
    $(`#check-${nextStep}`).attr('class', 'd-block');
  };

  const updateOpened = () => {
    if (number === 1) return;

    if (!stepOpened) {
      MixpanelService.track('performance_track_expanded_step_to_read', { step: number })
    }

    setStepOpened(!stepOpened);
  };

  return (
    <Card className="card col-12 col-md-7 mx-auto">
      <CardBody className="card-body text-center">
        {stepChecked && <StepChecked><img src='/images/check-icon.svg'
        style={{
          width: "20px",
          paddingBottom: "inherit",
          }}/></StepChecked>}
        {!stepChecked && <StepNumber>{number}</StepNumber>}

        <Label
          className="text-dark d-flex justify-content-center"
          onClick={() => updateOpened()}>
          <span style={{ width: '80%' }}>
            {label}
          </span>

          {number > 1 && (
            <i className={`ti ti-chevron-${stepOpened ? 'down' : 'right'}`}/>
          )}
        </Label>

        <div className={!!stepOpened ? 'd-block' : 'd-none'} id={divId}>
          {!!description && <Description>{parse(String(description))}</Description>}

          {componentDescription}

          {!!iframeUrl && (
            <div className="embed-responsive embed-responsive-16by9 mb-5">
              <Video
                height="315"
                src={iframeUrl}
                title="YouTube video player"
                className="embed-responsive-item w-100"
                frameBorder="0"
                allow="accelerometer;
                       autoplay;
                       clipboard-write;
                       encrypted-media;
                       gyroscope;
                       picture-in-picture"
                allowFullScreen>
              </Video>
            </div>
          )}

          {stepChecked && <CheckedButton onClick={toggleStep}>
            <img src='/images/check-icon.svg' className="me-1" width={20} />
            {I18n.t("customer_success.optimization_container.optimization_step.you_enabled_this_feature")}
            </CheckedButton>}

          {!stepChecked && <NotCheckedButton  onClick={toggleStep}>
            <img src='/images/loader.gif' className="me-1" width={30} />
            {I18n.t("customer_success.optimization_container.optimization_step.waiting_for_you_to_activate_this_functionality")}
            </NotCheckedButton>}
        </div>
      </CardBody>
    </Card>
  );
};

export default OptimizationStep;
