import React, { useRef, useState } from "react";

import ReactPortal from '@/components/ReactPortal';
import MapLeadModal from "@/components/lead/MapLeadModal";
import LinkWhatsApp from "@/components/whatsapp/Link";
import IntegrationBlueprintsIcon from "@/components/lead/IntegrationBlueprintsIcon";

import LeadService from '@/components/lead/services/leadService';
import util from '@/components/lead/utils';

import { classBinding } from '@/utils/helpers';
import dayjs from '@/packs/dayjs';

import swal from "sweetalert";

import * as S from './styles';

const ModalLeadView = ({ id, size, icon, className, disabled }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setLead] = useState({});

  const modal = useRef();

  const openModal = () => $(modal.current).modal('show');

  const handleClickOpenLead = () => {
    if (disabled) return;

    openModal();
    getLead(id);
  }

  const getLead = id => {
    LeadService.getLead(id)
      .then(response => {
        setLead(response.data);
        setIsLoading(false);
        setLeadAsClickedViewData(id);
      })
      .catch(() => swal("", I18n.t("views.leads.filter.datatable.notification.error"), "error"))
      .then(() => setIsLoading(false));
  };

  const setLeadAsClickedViewData = id => {
    LeadService.setLeadAsClickedViewData(id)
      .then(response => {
        $(`#${id}`).css("backgroundColor","#F2F5F5CC");
      })
      .catch(e => {
        swal("", I18n.t("views.leads.filter.datatable.notification.updated"), "error");
      });
  };

  return (
    <>
      <S.Button className={className} size={size} icon={icon} onClick={handleClickOpenLead} disabled={disabled}>
        <i className={`ti ti-${icon} me-2`} />
        {I18n.t("views.leads.filter.datatable.actions.see_data")}
      </S.Button>

      <ReactPortal>
        <div className="modal modal-blur fade" ref={modal} tabIndex="-1">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className={classBinding({ "content-loading": isLoading }, "modal-content")}>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />

              <div className="card">
                <ul className="nav nav-tabs" data-bs-toggle="tabs" id={`myTab-${id}`} role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      type="button"
                      className="fs-2 nav-link active"
                      data-bs-toggle="tab"
                      data-bs-target={`#lead-${id}`}
                    >
                      {I18n.t("views.leads.modal.data.title")}
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      type="button"
                      className="fs-2 nav-link"
                      data-bs-toggle="tab"
                      data-bs-target={`#lead-map-${id}`}
                    >
                      {I18n.t("views.leads.modal.localization.title")}
                    </button>
                  </li>
                </ul>

                <div className="modal-body overflow-auto" style={{ maxHeight: 660 }}>
                  <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id={`lead-${id}`} role="tabpanel" aria-labelledby="lead-tab">
                      { isLoading ? (
                        <div className='text-center fw-medium fs-3 my-3'>
                          {I18n.t("shared.actions.loading")}
                        </div>
                      ) : (
                        <div>
                          <dl className="row row-cols-2 mb-0">
                            <div>
                              <dt className="mb-1">{I18n.t("views.leads.modal.data.status")}</dt>
                              <dd className="mb-3">{util.leadStatus(data.lead.complete)}</dd>
                            </div>
                            <div>
                              <dt className="mb-1">{I18n.t("views.leads.modal.data.convention_date")}</dt>
                              <dd className="mb-3">{dayjs(data.lead.created_at).format('LLL')}</dd>
                            </div>
                            <div>
                              <dt className="mb-1">{I18n.t("views.leads.modal.data.score")}</dt>
                              <dd className="mb-3">{util.leadScore(data.lead.score)}</dd>
                            </div>
                            <div>
                              <dt className="mb-1">{I18n.t("views.leads.modal.data.integrated")}</dt>
                              <dd className="mb-3">
                                {data.lead.integration_events.map((event, index) =>
                                  <IntegrationBlueprintsIcon key={index} friendlyName={event["friendly_name"]} iconUrl={event["icon_url"]} />
                                )}
                              </dd>
                            </div>

                            { data.lead.roulette_whatsapp &&
                              <div className="mb-3">
                                <dt className="mb-1">{I18n.t("views.leads.modal.data.roulette_whatsapp")}</dt>
                                {data.lead.roulette_whatsapp.map((phone, index) =>
                                  <dd className="mb-0" key={index}>{phone}
                                    <LinkWhatsApp key={index} phone={phone} />
                                  </dd>
                                )}
                              </div>
                            }

                            { data.lead.is_scheduled &&
                              <div className="mb-3">
                                <dt className="mb-1">{I18n.t("views.leads.modal.data.calendar_schedule")}</dt>
                                {data.lead.calendar_schedules.map((schedule, index) =>
                                  <dd className="mb-0" key={index}>{schedule.humanized_start_at}</dd>
                                )}
                              </div>
                            }
                          </dl>

                          <dl className="row">
                            <dt>{I18n.t("views.leads.modal.data.referrer_url")}
                              <button
                                className="btn-copy btn-link text-decoration-none"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={I18n.t("views.leads.modal.shared.buttons.copy_url")}
                                onClick={() => navigator.clipboard.writeText(data.lead.referrer)}
                              >
                                <i className="ti ti-copy icon" />
                              </button>
                            </dt>

                            <dd>
                              <span data-toggle="tooltip" data-placement="top" title={data.lead.referrer} >
                                {data.lead.referrer || I18n.t("views.leads.modal.data.referrer_url_null")}
                              </span>
                            </dd>

                            <dt>{I18n.t("views.leads.modal.data.conversion_url")}
                              <button
                                className="btn-copy btn-link text-decoration-none"
                                data-toggle="tooltip"
                                data-placement="top"
                                title={I18n.t("views.leads.modal.shared.buttons.copy_url")}
                                onClick={() => navigator.clipboard.writeText(data.lead.location)}
                              >
                                <i className="ti ti-copy icon" />
                              </button>
                            </dt>

                            <dd>
                              <span data-toggle="tooltip" data-placement="top" title={data.lead.location} >
                                {data.lead.location}
                              </span>
                            </dd>
                          </dl>

                          <dl className="row row-cols-2 mb-0">
                            { data.lead.utm_medium &&
                              <div>
                                <dt className="mb-1">{I18n.t("views.leads.modal.data.utm_medium")}
                                  { data.lead.utm_medium &&
                                    <button className="btn-copy btn-link text-decoration-none"
                                      data-toggle="tooltip" data-placement="top" title="Copiar utm_medium"
                                      onClick={() => navigator.clipboard.writeText(data.lead.utm_medium)} >
                                      <i className="ti ti-copy icon" />
                                    </button>
                                  }
                                </dt>
                                <dd className="mb-3 text-truncate">{data.lead.utm_medium}</dd>
                              </div>
                            }

                            { data.lead.utm_source &&
                              <div>
                                <dt className="mb-1">{I18n.t("views.leads.modal.data.utm_source")}
                                  { data.lead.utm_source &&
                                    <button className="btn-copy btn-link text-decoration-none"
                                      data-toggle="tooltip" data-placement="top" title="Copiar utm_source"
                                      onClick={() => navigator.clipboard.writeText(data.lead.utm_source)} >
                                      <i className="ti ti-copy icon" />
                                    </button>
                                  }
                                </dt>
                                <dd className="mb-3 text-truncate">{data.lead.utm_source}</dd>
                              </div>
                            }

                            { data.lead.utm_campaign &&
                              <div>
                                <dt className="mb-1">{I18n.t("views.leads.modal.data.utm_campaign")}
                                  { data.lead.utm_campaign &&
                                    <button className="btn-copy btn-link text-decoration-none"
                                      data-toggle="tooltip" data-placement="top" title="Copiar utm_campaign"
                                      onClick={() => navigator.clipboard.writeText(data.lead.utm_campaign)} >
                                      <i className="ti ti-copy icon" />
                                    </button>
                                  }
                                </dt>
                                <dd className="mb-3 text-truncate">{data.lead.utm_campaign}</dd>
                              </div>
                            }

                            { data.lead.utm_term &&
                              <div>
                                <dt className="mb-1">{I18n.t("views.leads.modal.data.utm_term")}
                                  { data.lead.utm_term &&
                                    <button className="btn-copy btn-link text-decoration-none"
                                      data-toggle="tooltip" data-placement="top" title="Copiar utm_term"
                                      onClick={() => navigator.clipboard.writeText(data.lead.utm_term)} >
                                      <i className="ti ti-copy icon" />
                                    </button>
                                  }
                                </dt>
                                <dd className="mb-3 text-truncate">{data.lead.utm_term}</dd>
                              </div>
                            }
                          </dl>

                          <div className="fw-bold fs-2 mt-0 mb-2">
                            {I18n.t("views.leads.modal.data.conversation")}
                          </div>

                          <div>
                            {_.get(data, 'lead.lead_data', []).map((item, key) =>
                              <div key={key}>
                                <dt className="mb-1">{util.formartNameField(item.key)}</dt>
                                <dd className="mb-3">{item.value}</dd>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                      }
                    </div>

                    { !isLoading && _.get(data, "geolocation.data") != null &&
                      <div className="tab-pane fade" id={`lead-map-${id}`}>
                        <div className="row">
                          <div className="col-4">
                            <dl>
                              <dt>{I18n.t("views.leads.modal.localization.city")}</dt>
                              <dd>{data.geolocation.data.city}</dd>
                            </dl>
                          </div>
                          <div className="col-4">
                            <dl>
                              <dt>{I18n.t("views.leads.modal.localization.state")}</dt>
                              <dd>{data.geolocation.data.region}</dd>
                            </dl>
                          </div>
                        </div>

                        <div className="row">
                          <MapLeadModal
                            lat={data.geolocation.data.latitude}
                            lng={data.geolocation.data.longitude}
                            width="100%"
                            height={335}
                          />

                          <div className="alert alert-important bg-warning-lt alert-warning text-center mb-0 rounded-0 shadow-sm p-3" id="geolocation-ip-message">
                            <div className="d-flex justify-content-center align-items-center fw-medium text-black-yellow">
                              {I18n.t("views.leads.modal.localization.map_message")}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <p className="fw-bold my-4">{I18n.t("views.leads.modal.localization.description.title")}</p>
                          <span>
                            <i className="ti ti-arrow-narrow-right icon text-blue me-2"></i>
                            <span dangerouslySetInnerHTML={{
                              __html: I18n.t("views.leads.modal.localization.description.item_1")
                            }} />
                          </span>
                          <span><i className="ti ti-arrow-narrow-right icon text-blue me-2"></i>
                            <span dangerouslySetInnerHTML={{
                              __html: I18n.t("views.leads.modal.localization.description.item_2")
                            }} />
                          </span>
                          <span><i className="ti ti-arrow-narrow-right icon text-blue me-2"></i>
                            <span dangerouslySetInnerHTML={{
                              __html: I18n.t("views.leads.modal.localization.description.item_3")
                            }} />
                          </span>
                        </div>
                      </div>
                    } {
                      <div className="tab-pane fade" id={`lead-map-${id}`} role="tabpanel">
                        <div className="text-center">
                          <svg xmlns="http://www.w3.org/2000/svg"
                            className="icon-tabler icon-tabler-alert-circle text-warning mb-2"
                            width="80" height="80" viewBox="0 0 24 24"
                            strokeWidth="1" stroke="currentColor"
                            fill="none" strokeLinecap="round"
                            strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <circle cx="12" cy="12" r="9"></circle>
                            <line x1="12" y1="8" x2="12" y2="14"></line>
                            <line x1="12" y1="16" x2="12.01" y2="16"></line>
                          </svg>
                          <div className="fw-medium text-muted">
                            {I18n.t("views.leads.modal.localization.no_geolocation")}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactPortal>
    </>
  );
}

export default ModalLeadView;
