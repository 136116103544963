import * as Sentry from "@sentry/react";

const ALLOWED_SENTRY_ENVS = ["production", "staging"];
const SENTRY_DSN = ALLOWED_SENTRY_ENVS.includes(process.env.NODE_ENV)
  ? "https://4f97479954e19e629c913f2af8b620ab@o295214.ingest.sentry.io/4506112481951744"
  : undefined;

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});

const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");

ReactRailsUJS.useContext(componentRequireContext);
