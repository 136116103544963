import React from "react";

import { ASSISTANT_BEHAVIOR_OPTIONS } from "./constants";

import { useForm } from "react-hook-form";
import { useAssistantContext } from "@/contexts/AssistantContext";
import useCreateYourAiAssistant from "@/hooks/useCreateYourAiAssistant";
import useOnSubmitForm from "./hooks/useOnSubmitForm";

import { Container, Card, Col, Form, Row, Button } from "react-bootstrap";

import GuideModal from "@/components/shared/GuideModal";
import guideContents from './guideContents';

import * as S from "./styles";

const SetAssistant = () => {
  useCreateYourAiAssistant();

  const { assistant } = useAssistantContext();
  const { isLoading, onSubmit } = useOnSubmitForm();

  const { register, handleSubmit, getValues, setValue, setError, formState: { errors } } = useForm({
    defaultValues: {
      name: _.get(assistant, "name", ""),
      type_of_assistant: _.get(assistant, "type_of_assistant", ""),
      default_message: _.get(assistant, "default_message", ""),
    },
  });

  return (
    <>
      <GuideModal
        storageKey="leadster-ai-onboard"
        guideContents={guideContents}
        finishButtonText={I18n.t('guide_modal.alright_button')}
      />

      <Form onSubmit={handleSubmit((payload) => onSubmit(payload, setError))}>
        <Form.Group className="mb-3">
          <Form.Label>
            <i className="ti ti-message-chatbot icon text-primary me-1" />
            {I18n.t("views.leadster_ai.page.create_assistant.assistente_name.label")}
          </Form.Label>

          <Form.Control
            type="text"
            placeholder={I18n.t("views.leadster_ai.page.create_assistant.assistente_name.placeholder")}
            isInvalid={errors.hasOwnProperty("name")}
            maxLength={50}
            {...register("name")}
          />

          <Form.Control.Feedback type="invalid">
            {_.get(errors, "name.message")}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3 none">
          <Form.Label>
            <i className="ti ti-mood-edit icon text-primary me-1" />
            {I18n.t("views.leadster_ai.page.create_assistant.assistente_type.label")}
          </Form.Label>

            <Container>
              <Row className="row-deck row-cards">
                {ASSISTANT_BEHAVIOR_OPTIONS.map(({ name, value, description }) => (
                  <Col key={value}>
                    <S.SelectorCheckbox
                      className={`card card-sm ${ getValues("type_of_assistant") === value ? "selected" : ""}`}
                      isInvalid={errors.hasOwnProperty("type_of_assistant")}
                      onClick={() => {
                        setValue("type_of_assistant", value, {shouldValidate: true});
                        MixpanelService.track("leadster_ai_behavior_assistant", {behavior: name});
                      }}
                    >
                      <Card.Body>
                        <Form.Check
                          inline
                          type="radio"
                          label={name}
                          value={value}
                          className="fw-bold"
                          {...register("type_of_assistant", { value })}
                        />

                        <div className="fs-4">{description}</div>
                      </Card.Body>
                    </S.SelectorCheckbox>
                  </Col>
                ))}
              </Row>
            </Container>
          </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            <i className="ti ti-message icon text-primary me-1" />
            {I18n.t("views.leadster_ai.page.create_assistant.assistente_default_message.label")}
          </Form.Label>

          <Form.Control
            type="text"
            placeholder={I18n.t("views.leadster_ai.page.create_assistant.assistente_default_message.placeholder")}
            isInvalid={errors.hasOwnProperty("default_message")}
            {...register("default_message")}
          />

            <Form.Control.Feedback type="invalid">
              {_.get(errors, "default_message.message")}
            </Form.Control.Feedback>
          </Form.Group>

        <div className="border-top mt-3 pt-3 text-end">
          <Button variant="primary" disabled={isLoading} type="submit">
            {I18n.t("shared.actions.save_and_advance")}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default SetAssistant;
