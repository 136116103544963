import axios from "@/packs/axios";

const fetchEvaluationConversations = async ({ scriptId, leadId, assistantId, isLike, onFinalize }) => {
  const base_url = "/ai/evaluation_conversation";
  let isLoading = true;

  if (isLike == null || isLike == undefined) {
    isLoading = false;

    return { isLoading };
  }

  try {
    await axios.post(base_url, {
      script_id: scriptId,
      lead_id: leadId,
      ai_assistant_id: assistantId,
      is_like: isLike
    });
  }
  catch (err) { }
  finally {
    isLoading = false;
    onFinalize();
  }

  return { isLoading };
};

export default fetchEvaluationConversations;
