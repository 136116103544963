import React, { useState, useEffect, useRef } from "react";

import SmartCallsModal from "../SmartCallsExample/Modal";
import PermissionsGate from '@/components/permissions/PermissionsGate';
import RichTextEditor from "@/components/shared/RichTextEditor";
import HelpPopover from "@/components/shared/HelpPopover";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';
import { can } from "@/permissions-provider";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const SmartCallsContent = ({ isMobile }) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);
  const avatar = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.previewContent.avatar);

  const [previewSmartCall, setPreviewSmartCall] = useState();
  const modalRef = useRef();

  let dispatchActionType = "SET_DESKTOP_CONVOSCRIPT_VALUE";
  let notifications = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.desktopContent.notifications);
  let notificationsEnabled = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.desktopContent.notificationsEnabled);
  let notificationsDisplayTime = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.desktopContent.notificationsDisplayTime);

  if (isMobile) {
    dispatchActionType = "SET_MOBILE_CONVOSCRIPT_VALUE";
    notifications = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.mobileContent.notifications);
    notificationsEnabled = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.mobileContent.notificationsEnabled);
    notificationsDisplayTime = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.mobileContent.notificationsDisplayTime);
  }

  notifications = _.isArray(notifications) ? notifications : [];

  useEffect(() => {
    const randomNotificationTemplate = _.get(_.sample(notifications), 'template');
    setPreviewSmartCall(randomNotificationTemplate);
    window.initJqueryPopover();
  }, []);

  const setScriptValue = (key, value) => {
    dispatch({ type: dispatchActionType, payload: { key, value } });
  };

  const handleUpdateNotification = (value, index) => {
    if (!hasPermission) return;

    const updatedNotifications = [...notifications];
    _.set(updatedNotifications, `${index}.template`, value);

    setScriptValue("notifications", updatedNotifications);
  };

  const removeNotificationById = (notificationId) => {
    if (!hasPermission) return;

    const updatedNotifications = notifications.filter((notification) => notification.id != notificationId);
    setScriptValue("notifications", updatedNotifications);
  };

  const millisToSeconds = (m) => Math.floor(m / 500) / 2;

  const openModalSmartCalls = () => {
    if (!hasPermission) return;
    $(modalRef.current).modal("show");

    MixpanelService.track('script_open_smart_call_modal')
  }

  return (
    <PermissionsGate scope={PERMISSION_SCOPE} usePlaceholderError>
      <div>
        <div className='mb-3'>
          <label className="form-label">
            {I18n.t("views.manage_flows.script_editor.smart_calls")}
            <HelpPopover
              className="ms-1"
              content={I18n.t("views.manage_flows.script_editor.smart_calls_approach_description_html")}
            />
          </label>

          <div>
            <label className="form-check form-check-inline form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={notificationsEnabled}
                disabled={!hasPermission}
                onChange={() => {
                  if (!hasPermission) return;
                  setScriptValue("notificationsEnabled", true)
                }}
              />
              <span className="form-check-label">{I18n.t("views.manage_flows.script_editor.activated")}</span>
            </label>

            <label className="form-check form-check-inline form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={!notificationsEnabled}
                disabled={!hasPermission}
                onChange={() => {
                  if (!hasPermission) return;
                  setScriptValue("notificationsEnabled", false)
                }}
              />
              <span className="form-check-label">{I18n.t("views.manage_flows.script_editor.paused")}</span>
            </label>
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label">
            {I18n.t("views.manage_flows.script_editor.time_to_vary_the_call")}{" "}
            {millisToSeconds(notificationsDisplayTime || 20000)}s
            <HelpPopover
              className="ms-1"
              content={I18n.t("views.manage_flows.script_editor.call_display_time_html")}
            />
          </label>

          <input
            type="range"
            className="form-range"
            step="500"
            min="20000"
            max="60000"
            disabled={!hasPermission}
            defaultValue={notificationsDisplayTime}
            onChange={(event) => {
              if (!hasPermission) return;
              setScriptValue("notificationsDisplayTime", event.target.value)
            }}
          />
        </div>

        <div className='mb-3'>
          <label className="form-label">
            {I18n.t("views.manage_flows.script_editor.create_different_approaches_to_your_website_visitors")}
          </label>

          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => openModalSmartCalls()}
              disabled={!hasPermission}
            >
              <i className="ti ti-plus icon"></i>
                {I18n.t("views.manage_flows.script_editor.create_smart_call")}
            </button>

            <SmartCallsModal
              isMobile={isMobile}
              updatePreviewSmartCall={setPreviewSmartCall}
              ref={modalRef}
            />

            {notifications.map((notification, index) => {
              return <div className="row gx-2 mt-3" key={notification.id}>
                <div className="col">
                  <RichTextEditor
                    value={notification.template}
                    inline={true}
                    init={{
                      menubar: false,
                      plugins: "emoticons",
                      toolbar: "bold italic underline | emoticons",
                      smart_paste: false,
                    }}
                    onEditorChange={(value, editor) => {
                      const currentTextValue = editor.getContent({ format: "text" });
                      if (currentTextValue.length <= 105) {
                        handleUpdateNotification(value, index);
                        setPreviewSmartCall(value);
                      }
                    }}
                  />
                </div>

                <div className="col-auto">
                  <button type="button" className="btn btn-icon btn-danger ms-2" onClick={(e) => removeNotificationById(notification.id)}>
                    <i className="ti ti-trash-x icon"></i>
                  </button>
                </div>
              </div>;
            })}

            {!notifications.length && (
              <div className="alert alert-warning mt-3">
                <h4 className="alert-title text-body">
                  {I18n.t("views.manage_flows.script_editor.no_smart_calls_registered")}
                </h4>
                <div className="text-muted">
                  {I18n.t("views.manage_flows.script_editor.use_the_button_above_to_add_new_calls")}
                </div>
              </div>
            )}

            <div className="mt-3">
              <label className="form-label">{I18n.t("views.manage_flows.script_editor.preview_of_your_call")}</label>

              <div style={{ position: "relative", width: "100%", height: "32px", marginTop: "48px", marginLeft: "-20px" }}>
                <div className="nld-chatbot nld-left" style={{ zIndex: 1 }}>
                    <div className="nld-avatar" style={{ position: "relative", backgroundImage: `url(${avatar})`, bottom: "32px" }}>
                      { previewSmartCall && <div className="nld-chatbot-invite-container">
                          <div className="nld-chatbot-invite-message">
                            <span className="nld-invite-message-text" dangerouslySetInnerHTML={{ __html: previewSmartCall }}></span>
                            <div className="nld-clear"></div>
                          </div>
                        </div>
                      }
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PermissionsGate>
  );
};

export default React.memo(SmartCallsContent);
