import { useState, useEffect } from "react";

import { useAssistantContext } from "@/contexts/AssistantContext";

import axios from "@/packs/axios";

const useLoadAssistants = () => {
  const { setAssistantsList, setAssistant } = useAssistantContext();
  const [isLoading, setIsLoading] = useState(true);

  const loadAssistants = () => {
    setIsLoading(true);

    axios
      .get("/ai/assistants")
      .then(({ data }) => setAssistantsList(data.assistants))
      .catch((err) => {
        swal(I18n.t("request_feedback_alert.error.title"), I18n.t("request_feedback_alert.error.message"), "error");
      })
      .then(() => setIsLoading(false));
  };

  useEffect(() => {
    loadAssistants();
    setAssistant(null)
  }, []);

  return {
    isLoading,
  };
};


export default useLoadAssistants;
