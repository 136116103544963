import React, { useCallback, useEffect } from "react";

import OAuthPopup from "./OAuthPopup";
import PermissionsGate from '@/components/permissions/PermissionsGate';

import { can } from "@/permissions-provider";

import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'integration'
});

const Step3 = props => {
  const hasPermission = can(PERMISSION_SCOPE);

  // State Machine
  const { action, state } = useStateMachine(updateAction);

  // Form
  const { register, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      integration_blueprint_id: state.data?.integration_blueprint_id
    }
  });

  const integrationBlueprintId = watch("integration_blueprint_id");

  const onSubmit = (data) => {
    action(data);

    MixpanelService.track('integrations_first_step', {
      integration_blueprint_id: data.integration_blueprint_id,
    });

    props.history.push("./step2");
  };

  // Integration Type Handler
  const setIntegrationBlueprintId = integrationBlueprintId => {
    setValue("integration_blueprint_id", integrationBlueprintId);
  };

  // Register Custom Fields
  React.useEffect(() => {
    register("name.integration_blueprint_id")
    register("name.code")
    register("name.params")
  }, [register]);

  useEffect(() => {
    window.initJqueryTooltip();
  }, []);

  const formEl = React.createRef();

  const url = window.location.href;
  const isEditPage = /edit/.test(url)

  const blueprints = props.blueprints.filter(blueprint => {
    if (blueprint.id == 5 && !isEditPage) {
      return false;
    }

    return true;
  });

  const isBlueprintCard = useCallback((blueprint) => {
    return integrationBlueprintId === blueprint.id;
  }, [integrationBlueprintId]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={formEl}>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">
            {!isEditPage ? I18n.t("views.integrations.add_new_integration") : I18n.t("views.integrations.edit_integration")}
          </h3>
        </div>

        <div className="card-body">
          <div className="row row-cards row-deck">
            {blueprints.map(blueprint => (
              <div className="col-md-4" key={blueprint.id}>
                <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon usePlaceholderError>
                  <div className={`card ${isEditPage ? (!isBlueprintCard(blueprint) ? 'card-inactive' : '') : ''}`}>
                    {isBlueprintCard(blueprint) && (
                      <div className="card-status-top bg-primary" />
                    )}
                    <div className="card-body text-center d-flex flex-column justify-content-between">
                      <div className="d-flex justify-content-center align-items-center mb-3">
                        <div className="h3 mb-0">
                          {blueprint.friendly_name}
                        </div>

                        <a className="form-help ms-2"
                          href={blueprint.link_helper}
                          target="_blank"
                          data-bs-toggle="tooltip"
                          title={I18n.t("shared.actions.click_to_learn_more")}
                        >
                          <i className="fa fa-info"></i>
                        </a>
                      </div>

                      <div>
                        <img width="72" src={blueprint.image_url} className="mb-3" />
                        <p dangerouslySetInnerHTML={{ __html: blueprint.description_html }}/>
                      </div>

                      <div className="text-center">
                        {blueprint.oauth2_integration_name ? (
                          <OAuthPopup
                            title={I18n.t("views.integrations.step03.auth_title")}
                            url={props.blueprints_oauth[blueprint.oauth2_integration_name]}
                            width={600}
                            height={600}
                            onCode={(code, params) => {
                              setIntegrationBlueprintId(blueprint.id);
                              setValue("code", code);
                              setValue("params", params);
                              handleSubmit(onSubmit)();
                            }}
                          >
                            <button
                              type="button"
                              className={`btn btn-outline-primary w-100 ${!isBlueprintCard(blueprint) && isEditPage ? 'disabled' : ''}`}
                              onClick={e => e.preventDefault()}
                            >
                              {integrationBlueprintId === blueprint.id ? I18n.t("shared.actions.continue") : I18n.t("shared.actions.choose")}
                            </button>
                          </OAuthPopup>
                        ) : (
                          <button
                            type="button"
                            className={`btn btn-outline-primary w-100 ${!isBlueprintCard(blueprint) && isEditPage ? 'disabled' : ''}`}
                            disabled={!hasPermission}
                            onClick={() => {
                              if (!isBlueprintCard(blueprint) && isEditPage) return;
                              setIntegrationBlueprintId(blueprint.id);
                              handleSubmit(onSubmit)();
                            }}
                          >
                            {integrationBlueprintId === blueprint.id ? I18n.t("shared.actions.continue") : I18n.t("shared.actions.choose")}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </PermissionsGate>
              </div>
            ))}
          </div>
        </div>
      </div>
    </form>
  );
};

export default withRouter(Step3);
