import React, {useRef} from "react";
import * as S from "./styles";

import useScriptFlow from "./hooks/useScriptFlow";
import useChatInteractionTracker from "./hooks/useChatInteractionTracker";

const ChatPreview = () => {
  const elementContainerChatRef = useRef();

  useScriptFlow();
  useChatInteractionTracker(elementContainerChatRef);

  return (<S.chatbotContainer id="nld-custom-container" ref={elementContainerChatRef} />)
};

export default ChatPreview;
