import { useState } from "react";

import GuideModal from "@/components/shared/GuideModal";
import useShowGuideStorage from "@/components/shared/GuideModal/hooks/useShowGuideStorage";

import useCreateYourAiAssistant from "@/hooks/useCreateYourAiAssistant";
import { useAssistantContext } from "@/contexts/AssistantContext";

import { useHistory } from "react-router-dom";
import parse from "html-react-parser";

import guideContents from "./guideContents";
import folderIcon from "@/../assets/images/ai_assistant/file-folder-animated.gif";
import ChatPreview from "@/components/leadster_ia/subpages/CreateYourAiAssistant/ChatPreview";

import * as S from "./styles";

const TestAssistant = () => {
  useCreateYourAiAssistant("/test");

  const history = useHistory();
  const guideStorageKey = "leadster-ai-preview";

  const { assistant } = useAssistantContext();
  const { isGuideAllowed } = useShowGuideStorage(guideStorageKey);

  const [showFinishModal, setShowFinishModal] = useState(false);

  const goToNextStep = () => {
    history.push("./activation");
  };

  const handleClickAddToFlow = () => {
    if (isGuideAllowed) {
      return setShowFinishModal(true);
    }

    goToNextStep();
  };

  return (
    <>
      {assistant.active_knowledge_bases.length ? (
        <>
          <GuideModal
            enabled={showFinishModal}
            storageKey={guideStorageKey}
            guideContents={guideContents}
            finishButtonText={I18n.t("general.actions.add")}
            finishCallback={goToNextStep}
          />

          <S.Container>
            <ChatPreview />
          </S.Container>

          <div className="border-top mt-3 pt-3 text-end">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleClickAddToFlow}
            >
              {I18n.t("views.leadster_ai.page.test_assistant.add_to_flow")}
            </button>
          </div>
        </>
      ) : (
        <S.Container>
          <S.Icon src={folderIcon} />

          <div className="fs-3 fw-bold my-3">
            {I18n.t("views.leadster_ai.page.test_assistant.text_01")}
          </div>

          <S.Paragraph className="text-center">
            {parse(I18n.t("views.leadster_ai.page.test_assistant.text_02_html"))}
          </S.Paragraph>
        </S.Container>
      )}
    </>
  );
};

export default TestAssistant;
