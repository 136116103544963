import React from "react";

import { Button, Card, OverlayTrigger, Popover } from "react-bootstrap";

import { useHistory, useRouteMatch } from "react-router-dom";
import { can } from "@/permissions-provider";

import CreateNewAssistantImg from "@/../assets/images/ai_assistant/create-new-assistant.png";

const CreateNewAssistant = () => {
  const hasPermission = can({ action: "manage", subject: "leadster_ai_plugin" });

  let { url } = useRouteMatch();
  const history = useHistory();

  const handleNormalizeUrl = (url) => {
    return url.replace(/\/$/, "");
  };

  const handleClickButton = () => {
    if (!hasPermission) return;

    history.push(`${handleNormalizeUrl(url)}/assistants`);
    MixpanelService.track("leadster_ai_new_assistant");
  };


  return (
    <Card className="border-none">
      <Card.Body>
        <Card.Img src={CreateNewAssistantImg} />

        <div className="d-grid gap-2 pt-3">
          <OverlayTrigger
            trigger={!hasPermission ? ["hover", "focus"] : false}
            overlay={
              <Popover>
                <Popover.Body>{I18n.t("views.leadster_ai.page.home.not_allowed_create")}</Popover.Body>
              </Popover>
            }
          >
            <Button
              variant="outline-primary"
              className="fs-3"
              size="lg"
              onClick={handleClickButton}
              disabled={!hasPermission}
            >
              <i className="ti ti-plus me-2" />
              {I18n.t("views.leadster_ai.page.home.create_new_assistant")}
            </Button>
          </OverlayTrigger>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CreateNewAssistant;
