import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import BarComparison from "./BarComparison"
import InfoModal from "../InfoModal"
import PermissionsGate from '@/components/permissions/PermissionsGate';

import axios from "packs/axios";
import { can } from "@/permissions-provider";
import { classBinding } from '@/utils/helpers';

import { Container, SegmentSelector } from "./styles.js";

const PERMISSION_SCOPE = Object.freeze({
  action: 'conversion',
  subject: 'metric'
});

// TODO: Remove all this component if team decide that metric doesn't return to product!
const SegmentAnalytic = ({
  defaultSegment,
  allowedSegments,
  conversionAnalytics
}) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const [selectedSegment, setSelectedSegment] = useState(defaultSegment);
  const [generalConversion, setGeneralConversion] = useState(0);
  const [googleConversion, setGoogleConversion] = useState(0);
  const [facebookConversion, setFacebookConversion] = useState(0);
  const [comparisonWithSegment, setComparisonWithSegment] = useState(0);

  useEffect(() => {
    $('#analytics-script').on('change', updateData);
    $('input[name="lead_score"]').on('change', updateData);
    $("input[name=mobile]").on('change', updateData);
    $('#date-range').on('change', updateData);

    updateData();
  }, []);

  useEffect(() => {
    if (!generalConversion) return;

    const segmentConversion = _.get(conversionAnalytics, `${selectedSegment}.all.general`, 0);

    setComparisonWithSegment((((generalConversion / segmentConversion) * 100) - 100).toFixed(2));
  }, [selectedSegment, generalConversion])

  const updateData = async () => {
    if (!hasPermission) return;

    const dateRange = $('#date-range').val();
    const splitedDateRange = dateRange.split(' - ');
    const startDate = splitedDateRange[0];
    const endDate = splitedDateRange[1];
    const scriptId = $("#analytics-script").val();
    const mobile = $("input[name=mobile]:checked").val();
    const score = $('input[name="lead_score"]:checked').val();

    const dataFilter = {
      start_date: startDate,
      end_date: endDate,
      script_id: scriptId,
      mobile,
      score,
    };

    const response = await axios.get('/dashboard-metrics/conversion', { params: dataFilter });
    const conversionToFloat = (conversion) => parseFloat(conversion.replace('%', ''));

    setGeneralConversion(conversionToFloat(_.get(response, 'data.geral_conversion_rate', '0')));
    setGoogleConversion(conversionToFloat(_.get(response, 'data.google_conversion_rate', '0')));
    setFacebookConversion(conversionToFloat(_.get(response, 'data.facebook_conversion_rate', '0')));
  };

  const onChangeSegment = (segment) => {
    if (!hasPermission) return;

    setSelectedSegment(segment);
    MixpanelService.track('dashboard_filtered_by_segment', { "Segmento": segment })
  }

  return (
    <Container>
      <div className="d-flex justify-content-start my-3 mt-md-2 mb-md-0">
        <div className="d-block d-flex flex-column flex-md-row align-items-start align-items-md-center">
          <h3 className="h1 mb-0 d-inline-block">
            {I18n.t("views.dashboard.comparison_with_segment.title")}:
          </h3>

          <PermissionsGate scope={PERMISSION_SCOPE}>
            <SegmentSelector>
              <button
                type="button"
                className="dropdown-toggle h1 mb-0 text-primary"
                id="dropdownSegment"
                data-bs-toggle="dropdown"
              >
                {I18n.t(`shared.segment.${selectedSegment}`)}
              </button>

              <div className="dropdown-menu" aria-labelledby="dropdownSegment">
                {allowedSegments.map((segment) => {
                  return (
                    <a
                      href="#"
                      className={classBinding({ "active": selectedSegment === segment }, "dropdown-item")}
                      key={segment}
                      onClick={(e) => {
                        e.preventDefault();
                        onChangeSegment(segment)}
                      }
                    >
                      {I18n.t(`shared.segment.${segment}`)}
                    </a>
                  );
                })}
              </div>
            </SegmentSelector>
          </PermissionsGate>

          <PermissionsGate scope={PERMISSION_SCOPE} hideOnError>
            <InfoModal />
          </PermissionsGate>
        </div>
        <div className="ps-2 flex-grow-1 d-none d-md-block"><hr /></div>
      </div>

      <div className="row row-deck row-cards">
        <div className="col-sm-6 col-lg-3">
          <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon usePlaceholderError>
            <div className="card">
              <div className="card-body">
                <div className="card-title">
                  {I18n.t("views.dashboard.comparison_with_segment.segment_average_conversion")}
                </div>
                <BarComparison
                  segment={selectedSegment}
                  dataIndex="all"
                  value={generalConversion}
                  conversionAnalytics={conversionAnalytics}
                />
              </div>
            </div>
          </PermissionsGate>
        </div>

        <div className="col-sm-6 col-lg-3">
          <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon usePlaceholderError>
            <div className="card">
              <div className="card-body">
                <div className="card-title">{I18n.t("views.dashboard.comparison_with_segment.comparsion_with_industry")}</div>
                <div className={`mt-4 text-center display-6 fw-medium text-${(comparisonWithSegment >= 0) ? 'success' : 'danger'}`}>
                  <i className={`me-1 ti ti-trending-${(comparisonWithSegment >= 0) ? 'up' : 'down'}`}></i>
                  {comparisonWithSegment}%
                </div>
              </div>
              <div className="card-footer border-0 bg-white px-3 py-3">
                <i className="ti ti-help text-muted icon me-1"></i>
                <a href="/optimization" className='text-muted'>{I18n.t("views.dashboard.comparison_with_segment.text_1")}</a>
              </div>
            </div>
          </PermissionsGate>
        </div>

        <div className="col-sm-6 col-lg-3">
          <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon usePlaceholderError>
            <div className="card">
              <div className="card-body">
                <div className="card-title">
                  {I18n.t("views.dashboard.comparison_with_segment.google_ads_conversion")}
                </div>
                <BarComparison
                  segment={selectedSegment}
                  dataIndex="google"
                  value={googleConversion}
                  conversionAnalytics={conversionAnalytics}
                />
              </div>
            </div>
          </PermissionsGate>
        </div>

        <div className="col-sm-6 col-lg-3">
          <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon usePlaceholderError>
            <div className="card">
              <div className="card-body">
                <div className="card-title">
                  {I18n.t("views.dashboard.comparison_with_segment.meta_ads_conversion")}
                </div>
                <BarComparison
                  segment={selectedSegment}
                  dataIndex="facebook"
                  value={facebookConversion}
                  conversionAnalytics={conversionAnalytics}
                />
              </div>
            </div>
          </PermissionsGate>
        </div>
      </div>
    </Container>
  );
};

SegmentAnalytic.propTypes = {
  defaultSegment: PropTypes.string.isRequired,
  allowedSegments: PropTypes.arrayOf(String).isRequired,
  conversionAnalytics: PropTypes.object.isRequired,
};

export default SegmentAnalytic;
