import { useState } from "react";

import PermissionsGate from "@/components/permissions/PermissionsGate";
import ScriptBySegmentModal from "../ScriptBySegmentModal";

import { can } from "@/permissions-provider";

import * as S from "./styles.js";

const PERMISSION_SCOPE = Object.freeze({ action: 'new', subject: 'script' });

const NewScriptButton = ({ isTextActive = true }) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const [showModal, setShowModal] = useState(false);

  const openSegmentScriptsModal = () => {
    if (!hasPermission) return;

    setShowModal(true);
  };

  return (
    <>
      <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon iconCentered usePopover>
        <S.CreateButton onClick={openSegmentScriptsModal} isActive={isTextActive}>
          <i className={"ti ti-plus icon " + ( isTextActive ? '' : 'mx-0' )} />
          { isTextActive && I18n.t("views.manage_flows.button_create") }
        </S.CreateButton>
      </PermissionsGate>

      <ScriptBySegmentModal
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

export default NewScriptButton;
