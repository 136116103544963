import React, { useReducer, useCallback, useEffect, useState, useRef } from "react";

import { isNodeCompatibleWith } from "../../../NodeCompat";

import Tree from "../../../Tree";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';
import { can } from "@/permissions-provider";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const TreeEditor = (props) => {
  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);
  const desktopContent = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.desktopContent);
  const mobileContent = useContextSelector(ScriptContext, ({ state }) => state.scriptVersion.mobileContent);
  const additionalData = useContextSelector(ScriptContext, ({ state }) => state.additionalData);

  const hasPermission = can(PERMISSION_SCOPE);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  // Keep a ID counter for the nodes  (use generateNodeId() to create a new node ID)
  const highestId = useRef(1)

  // Keep a queue of targeted nodes so we can "untarget"
  let targetedNodes = [];

  const [tree, setTree] = useState(null)

  useEffect(() => {
    if (!tree || tree.name !== props.name) {
      const convo = getConvoScript();
      let script = convo.script;

      setTree(buildTree(script));
    }
  }, []);

  const setScriptValue = (key, value) => {
    let dispatchActionType = "SET_DESKTOP_CONVOSCRIPT_VALUE";
    if (props.isMobile) {
      dispatchActionType = "SET_MOBILE_CONVOSCRIPT_VALUE";
    }

    dispatch({ type: dispatchActionType, payload: { key, value } });
    dispatch({ type: "SET_PREVIEW_CONVOSCRIPT_VALUE", payload: { key, value } });
  };

  const setScriptValueDelayed = _.debounce((key, value) => {
    setScriptValue(key, value)
  }, 250);

  const getConvoScript = () => {
    if (props.isMobile) {
      return mobileContent;
    }

    return desktopContent;
  };

  const generateNodeId = () => props.name + (highestId.current++).toString();

  const findBlockType = (def) => {
    if ("say" in def) {
      if ("scheduler" in def && def.scheduler) {
        return "scheduler";
      } else if ("whatsapp" in def && def.whatsapp) {
        return "whatsapp";
      } else if ("jivochat" in def && def.jivochat) {
        return "jivochat";
      } else {
        return "say";
      }
    } else if ("from" in def) {
      if ("messages" in def) {
        return "messages_selector";
      } else {
        return "selector";
      }
    } else if ("save" in def) {
      return "save";
    } else if ("if" in def) {
      return "if";
    } else if ("api" in def) {
      return "custom";
    } else {
      return null;
    }
  };

  const isSameCondition = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  const mashChildren = (a, b) => {
    const path = a.path.concat(a.id);

    return Object.assign(a, {
      children: a.children.concat(
        b.children
          .map((child) => fixPaths(child, path))
          .filter((c) => !c.placeholder)
      ),
    });
  };

  const mashIfs = (list) => {
    const tail = list.slice(1);

    if (tail.length) {
      const mashed = mashIfs(tail);

      const a = list[0];
      const b = mashed[0];

      if (a && a.type === "if" && b && b.type === "if" && isSameCondition(a.data["if"], b.data["if"])) {
        return [mashChildren(a, b)].concat(mashed.slice(1));
      } else {
        return list.slice(0, 1).concat(mashed);
      }
    } else {
      return list;
    }
  };

  const getBlockChildren = (block) => {
    if ("if" in block) {
      return Array.isArray(block.then) ? block.then : [block.then];
    } else if ("messages" in block) {
      return block.messages || [];
    }

    return [];
  };

  const addPlaceholderChild = (type, path, children) => {
    return children.length || type === "if" || type === "messages_selector"
      ? children.concat({
          id: generateNodeId(),
          path: path,
          data: {},
          children: [],
          placeholder: true,
        })
      : children;
  };

  const makeBlock = (path, block) => {
    const id = generateNodeId();
    const childrenPath = path.concat(id);
    const type = findBlockType(block);

    return {
      id: id,
      path: path,
      data: block,
      type: type,
      children: addPlaceholderChild(
        type,
        childrenPath,
        getBlockChildren(block).map((block) => makeBlock(childrenPath, block))
      ),
    };
  };

  const buildTree = (script) => {
    const id = generateNodeId();

    return {
      name: props.name,

      id: id,
      path: [],
      data: {},
      type: null,
      children: mashIfs(script.map((block) => makeBlock([id], block))),
    };
  };

  // FIXME: Inefficient data structure
  const findChild = (node, id) => {
    for (let child of node.children) {
      if (child.id === id) {
        return child;
      }
    }

    return null;
  };

  const cleanTargetedNodes = () => {
    targetedNodes.forEach((node) => {
      const treeNode = resolvePath(node.path.concat(node.id));

      if (treeNode) {
        treeNode.targeted = null;
      }
    });

    setTimeout(() => {
      $(".placeholder-drop").removeClass("targeted");
    }, 1000);
  };

  // Returns the node corresponding to a path
  // Resolving a parent:
  //   resolvePath(node.path)
  // Resolving a node:
  //   resolvePath(node.path.concat(node.id))
  const resolvePath = (path) => {
    // Shallow copy
    path = path.slice();

    // the first element SHULD be the root wich we can find for free:
    let node = tree;
    path.shift();

    while (node && path.length) {
      node = findChild(node, path.shift());
    }

    return node;
  };

  const callOnChange = (debounce = true) => {
    const script = getScript();

    if (debounce) {
      return setScriptValueDelayed("script", script);
    }

    setScriptValue("script", script);
  };

  const treeUpdate = (node, changes) => {
    const parent = resolvePath(node.path);

    parent.children = parent.children.map((child) =>
      child.id === node.id ? Object.assign(node, changes) : child
    );

    callOnChange();
  };

  const treeRemove = (node) => {
    const parent = resolvePath(node.path);

    parent.children = parent.children.filter((child) => child.id !== node.id);

    callOnChange(false);
  };

  const treeTarget = (node, below) => {
    const parent = resolvePath(node.path);

    targetedNodes = [node];

    parent.children = parent.children.filter((child) =>
      child.id === node.id
        ? Object.assign(child, { targeted: below ? "below" : "above" })
        : Object.assign(child, { targeted: null })
    );

    forceUpdate();
  };

  const fixPaths = (node, newPath) => {
    function traverse(node, path) {
      return Object.assign(node, {
        path: path,
        children: node.children.map((child) =>
          traverse(child, path.concat(node.id))
        ),
      });
    }

    return traverse(node, newPath);
  };

  const treeAdd = (node, newNode, insert) => {
    if (insert === "inside") {
      const parent = resolvePath(node.path.concat(node.id));
      const addedNode = fixPaths(newNode, node.path.concat(node.id));

      const cleanChildState = (child) => {
        return Object.assign(child, { targeted: false, dragging: false });
      }

      parent.children = parent.children.map(cleanChildState).concat(addedNode);
    } else {
      const parent = resolvePath(node.path);
      const addedNode = fixPaths(newNode, parent.path.concat(parent.id));

      const addChild = (child) => {
        child = Object.assign(child, { targeted: false, dragging: false });

        if (child.id === node.id) {
          if (insert === "above") {
            return [addedNode, child];
          } else {
            return [child, addedNode];
          }
        } else {
          return [child];
        }
      }

      parent.children = parent.children
        .map(addChild)
        .reduce((acc, v) => acc.concat(v), []);
    }

    callOnChange();
  };

  const removePlaceholders = (node) => {
    return Object.assign(
      { ...node },
      {
        children: node.children
          .filter((child) => !child.placeholder)
          .map(removePlaceholders.bind(this)),
      }
    );
  };

  const expandIfs = (node) => {
    if (node.type === "if") {
      return node.children.map((child) =>
        Object.assign(Object.assign({}, node), { children: [child] })
      );
    } else {
      return [
        Object.assign(
          { ...node },
          {
            children: node.children
              .map((child) => expandIfs(child))
              .reduce((acc, v) => acc.concat(v), []),
          }
        ),
      ];
    }
  };

  const embedChildren = (node) => {
    return Object.assign(node, {
      children: node.children.map((child) => {
        switch (child.type) {
          case "if":
            return Object.assign(
              { ...child },
              {
                data: Object.assign(
                  { ...child.data },
                  { then: { ...child.children[0].data } }
                ),
              }
            );
          case "messages_selector":
            return Object.assign(
              { ...child },
              {
                data: Object.assign(
                  { ...child.data },
                  { messages: child.children.map((c) => c.data) }
                ),
              }
            );
          default:
            return { ...child };
        }
      }),
    });
  };

  const unwrapData = (node) => {
    return node.children.map((child) => child.data);
  };

  const getScript = () => {
    const treeCopy = { ...tree };

    return unwrapData(
      embedChildren(expandIfs(removePlaceholders(treeCopy))[0])
    );
  };

  //
  // Event handling
  //
  const onDragStart = (e, node, element) => {
    if (!hasPermission) return;

    // Avoid cyclic references
    delete node.parent;

    e.dataTransfer.setData("application/json", JSON.stringify(node));
    e.dataTransfer.setDragImage(element, 0, 0);
    e.stopPropagation();

    // Chrome fix: https://github.com/react-dnd/react-dnd/issues/1085
    setTimeout(() => {
      treeUpdate(node, { dragging: true });
    }, 0);
  };

  const onDragEnd = (e, node, element) => {
    if (!hasPermission) return;

    treeUpdate(node, { dragging: false });
    cleanTargetedNodes();
  };

  const throttledOnDragOver = useCallback(
    _.throttle((e, node, domElement) => {
      if (typeof domElement.getBoundingClientRect !== "function") {
        return;
      }

      const bounds = domElement.getBoundingClientRect();
      const isBelow = e.clientY - bounds.top > bounds.height / 2;

      treeTarget(node, isBelow);
    }, 350)
  );

  const onDragOver = (e, node, domElement) => {
    if (!hasPermission) return;

    e.persist();
    e.preventDefault();
    e.stopPropagation();

    throttledOnDragOver(e, node, domElement);
  };

  const onDrop = (e, node, insert) => {
    if (!hasPermission) return;

    const dropped = JSON.parse(e.dataTransfer.getData("application/json"));
    const parent = resolvePath(node.path);

    if (_.get(node, "data.save") === _.get(dropped, "data.save", "is_same_node")) {
      return;
    }

    if (_.get(node, "data.say") === _.get(dropped, "data.say", "is_same_node")) {
      return;
    }

    if (isNodeCompatibleWith(parent, dropped.type)) {
      treeRemove(dropped);
      treeAdd(node, dropped, insert);

      e.stopPropagation();
    }
  };

  const getFullTree = () => {
    const convoScript = getConvoScript();
    return convoScript.script;
  };

  const globalKeyGet = (key) => {
    const convoScript = getConvoScript();
    return convoScript[key];
  };

  const globalKeySet = (key, value) => {
    setScriptValue(key, value);
  };

  return (
    (tree) && (
      <Tree
        id={tree.id}
        isMobile={props.isMobile}
        path={tree.path}
        placeholder={tree.placeholder}
        dragging={tree.dragging}
        data={tree.data}
        type={tree.type}
        additionalData={additionalData}
        children={tree.children}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
        onDrop={onDrop}
        treeRemove={treeRemove}
        treeUpdate={treeUpdate}
        treeAdd={treeAdd}
        getFullTree={getFullTree}
        globalKeyGet={globalKeyGet}
        globalKeySet={globalKeySet}
        makeBlock={makeBlock}
      />
    )
  );
};

export default React.memo(TreeEditor);
