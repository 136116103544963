import React, {useState} from "react";

import { Card, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";

import { useAssistantContext } from "@/contexts/AssistantContext";
import useCurrentUserStore from "@/store/currentUserStore";

import axios from "@/packs/axios";
import dayjs from "@/packs/dayjs";

const Content = ({ knowLeadgeBase }) => {
  const { locale: userLocale } = useCurrentUserStore((state) => state.currentUser);
  const { removeActiveBase } = useAssistantContext();

  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveKnowledgeBase = (baseId) => {
    setIsLoading(true);

    MixpanelService.track("leadster_ai_remove_content");

    axios
      .delete(`/ai/knowledge_bases/${baseId}`)
      .then(() => {
        toastr.success(I18n.t("shared.messages.request_feedback_alert.success.message"));
        removeActiveBase(baseId);
      })
      .catch(() => toastr.error(I18n.t("shared.messages.request_feedback_alert.error.message")))
      .then(() => setIsLoading(false));
  }

  return (
    <Card className="bg-primary-lt card-sm" >
      <Card.Body className="py-2">
        <Row gap={2} className="align-items-center">
          <Col className="fw-bold text-body cursor-default">
            <OverlayTrigger overlay={<Tooltip className="p-2">{knowLeadgeBase.base_name}</Tooltip>}>
              {knowLeadgeBase.base_type == "url" ? (
                <div>
                  <i className="ti icon me-1 ti-world-www" />
                  <a href={knowLeadgeBase.base_name} target="_blank" rel="noopener noreferrer">
                    {_.truncate(knowLeadgeBase.base_name, { length: 65 })}
                  </a>
                </div>
              ) : (
                <div>
                  <i className="ti icon me-1 ti-file-check" />
                  {_.truncate(knowLeadgeBase.base_name, { length: 65 })}
                </div>
              )}
            </OverlayTrigger>
          </Col>

          <Col xs="auto" className="ms-auto">
            <span className="me-2 text-body small fw-medium">
              {I18n.t("views.leadster_ai.page.add_content.content_list.added_at", {
                date: dayjs(knowLeadgeBase.created_at).format(I18n.t("js.formats.default.date"))
              })}
            </span>

            <span className="me-2 text-muted small fw-medium">
              {I18n.t("views.leadster_ai.page.add_content.content_list.number_characters", {
                characters: (knowLeadgeBase.character_count || 0).toLocaleString(userLocale, { minimumFractionDigits: 0 })
              })}
            </span>

            {isLoading ? (
              <div className="spinner-border spinner-border-sm" />
            ) : (
              <button
                type="button"
                className="btn btn-ghost-light btn-icon border-0"
                onClick={() => { handleRemoveKnowledgeBase(knowLeadgeBase.id) }}
              >
                <i className="ti ti-trash text-danger fs-2" />
              </button>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Content;
