import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import PermissionsGate from '@/components/permissions/PermissionsGate';
import ListEditor from "../../../ListEditor";
import RuleEditor from "../../../RuleEditor";

import { ScriptContext } from "@/contexts/ScriptContext";
import { useContextSelector } from 'use-context-selector';
import { can } from "@/permissions-provider";

import { Container, Tab } from "./styles";

const PERMISSION_SCOPE = Object.freeze({
  action: 'manage',
  subject: 'script_flow'
});

const RulesEditor = forwardRef((props, ref) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const dispatch = useContextSelector(ScriptContext, ({ dispatch }) => dispatch);
  const isDefault = useContextSelector(ScriptContext, ({ state }) => state.default);
  const ast = useContextSelector(ScriptContext, ({ state }) => state.ast);

  const listRef = useRef();
  const buttonAddListRef = useRef();

  const isLeaf = (ast) => {
    return ast.info && ast.info.leaf;
  }

  const leafToRule = (ast) => {
    return {
      kind: ast["info"]["kind"],
      rule: ast["info"]["content"],
    };
  }

  const ASTtoList = (ast, isRoot = true) => {
    let list = [];

    if (Object.keys(ast).length < 2) {
      return list;
    }

    if (isLeaf(ast)) {
      list.push(leafToRule(ast));
    } else {
      for (let arg of ast.args) {
        list = list.concat(ASTtoList(arg, false));
      }
    }

    if (isRoot) {
      return [
        list.map((item, i) => ({ ...item, id: i })),
        ast.info.type || "any",
      ];
    } else {
      return list.map((item, i) => ({ ...item, id: i }));
    }
  }

  const atomFromQueryRule = (rule) => {
    // Ordered by string length
    const operators = ["<=", ">=", ">", "<", "="];

    for (let op of operators) {
      if (rule.includes(op)) {
        const args = rule.split(op);

        if (args.length === 2) {
          return {
            op: op,
            args: [
              {
                op: "env",
                args: ["query", args[0]],
              },
              args[1],
            ],
          };
        } else {
          throw "Query rule does not have two arguments!";
        }
      }
    }
  }

  const atomFromHostRule = (rule) => {
    return {
      op: "regex",
      args: [
        { op: "url.host", args: [rule] },
        { op: "env", args: ["host"] },
      ],
    };
  }

  const isObject = (obj) => {
    const type = typeof obj;

    return (
      type === "function" || (type === "object" && !!obj && !Array.isArray(obj))
    );
  }

  const applyTemplate = (template, values) => {
    if (isObject(template)) {
      if (template.template) {
        // 1. Substitute by a entry from `values`
        return values[template.template];
      } else {
        // 2. Traverse a normal AST node
        const obj = {};

        for (let key of Object.keys(template)) {
          const value = template[key];

          if (Array.isArray(value)) {
            obj[key] = value.map((item, i, arr) =>
              applyTemplate(item, values)
            );
          } else {
            obj[key] = value;
          }
        }

        return obj;
      }
    } else {
      // 3. A terminal atom, do nothing
      return template;
    }
  }

  const buildTemplates = (rule) => {
    const parsedPath = { op: "url.path", args: [{ template: "rule" }] };
    const parsedHost = { op: "url.host_or_identity", args: [{ template: "rule" }] };

    const templates = {
      v1_match: {
        op: "and",
        args: [
          {
            op: "or",
            args: [
              {
                op: "regex",
                args: [parsedPath, { op: "env", args: ["path"] }],
              },
              {
                op: "contains",
                args: [parsedPath, { op: "env", args: ["path"] }],
              },
            ],
          },
          atomFromHostRule(rule.rule),
        ],
      },
      v1_not_match: {
        op: "and",
        args: [
          {
            op: "not",
            args: [
              {
                op: "or",
                args: [
                  {
                    op: "regex",
                    args: [parsedPath, { op: "env", args: ["path"] }],
                  },
                  {
                    op: "contains",
                    args: [parsedPath, { op: "env", args: ["path"] }],
                  },
                ],
              },
            ],
          },
          atomFromHostRule(rule.rule),
        ],
      },
      v1_domain_match: {
        op: "and",
        args: [
          {
            op: "or",
            args: [
              {
                op: "regex",
                args: [parsedHost, { op: "env", args: ["host"] }],
              },
              {
                op: "contains",
                args: [parsedHost, { op: "env", args: ["host"] }],
              },
            ],atomFromQueryRule
          },
          atomFromHostRule(rule.rule),
        ],
      },
      v2_not_domain_match: {
        op: "not",
        args: [
          {
            op: "and",
            args: [
              {
                op: "or",
                args: [
                  {
                    op: "regex",
                    args: [parsedHost, { op: "env", args: ["host"] }],
                  },
                  {
                    op: "contains",
                    args: [parsedHost, { op: "env", args: ["host"] }],
                  },
                ],
              },
              atomFromHostRule(rule.rule),
            ],
          },
        ],
      },
      v1_domain_and_path_match: {
        op: "url.host_and_path_eq",
        args: [{ op: "url.host_and_path", args: [{ template: "rule" }] }],
      },
      v2_home: {
        op: "regex",
        args: [{ op: "env", args: ["path"] }, "^$|^/$"],
      },
      v3_google_ads_match: {
        op: "regex_param",
        args: [{ op: "env", args: ["query"] }, "gclid="],
      },
      v3_facebook_ads_match: {
        op: "regex_param",
        args: [{ op: "env", args: ["query"] }, "fbclid="],
      },
      v3_linkedin_ads_match: {
        op: "regex_param",
        args: [{ op: "env", args: ["query"] }, "li_fat_id="],
      },
      v3_param_match: {
        op: "and",
        args: [
          {
            op: "or",
            args: [
              {
                op: "regex",
                args: [parsedPath, { op: "env", args: ["query"] }],
              },
              {
                op: "contains",
                args: [parsedPath, { op: "env", args: ["query"] }],
              },
            ],
          },
          atomFromHostRule(rule.rule),
        ],
      },
    };

    return templates;
  }

  // Convert a list of rules to a AST
  // Use op to combine them
  const listToAST = (list, op, type) => {
    let ast = null;

    // Start from inside out, and thus from the end of the list
    // This doesn't really matter since OR & AND have distributive props,
    // but further down the road it will make changes easier
    for (let i = list.length - 1; i >= 0; i--) {
      const rule = list[i];

      // Build the current atom
      // Below we try to emulate:

      // rule.kind
      // 1 - path matches
      //   * trie regex first
      //   * if fails, tries contains
      // 2 - path does not match
      //   * inverts 1
      // 3 - host matches
      //   * tries regex first
      //   * if error, tries contains

      const templates = buildTemplates(rule);
      const atom = applyTemplate(templates[rule.kind] || {}, rule);
      atom.info = {
        leaf: true,
        kind: rule.kind,
        content: rule.rule,
      };

      // Combine with the rest of the AST
      if (ast) {
        ast = {
          op: op,
          args: [atom, ast],
        };
      } else {
        ast = atom;
      }
    }

    ast = ast || {};

    if (ast.info) {
      ast.info.version = 3;
      ast.info.type = type;
    } else {
      ast.info = {
        version: 3,
        type: type,
      };
    }

    return ast;
  }

  const updateRulesCount = (add_value) => {
    setCountRules(countRules + add_value)
  }

  const updateDefault = (isDefault) => {
    if (!hasPermission) return;

    dispatch({ type: "SET_IS_DEFAULT", payload: isDefault });
  }

  const handleClickAddNewRole = () => {
    if (!hasPermission) return;

    listRef.current.add();
    updateRulesCount(1);
  }

  const updateRulesAst = () => {
    const typeToOperator = {
      any: "or",
      all: "and",
    };

    const ast = listToAST(
      listRef.current.currentList(),
      typeToOperator[type],
      type
    );

    const newRules = ASTtoList(ast, false);

    dispatch({ type: "SET_RULES_LIST", payload: newRules });
    dispatch({ type: "SET_AST", payload: ast });
  }

  useImperativeHandle(ref, () => ({
    addCustomListData(kind, rule = "") {
      listRef.current.addWithCustomData(kind, rule);
      updateRulesCount(1);
    }
  }));

  const [listAst, typeAst] = ASTtoList(ast);
  const rules = listAst || [];

  const [type, setType] = useState(typeAst || "any");
  const [countRules, setCountRules] = useState(listAst ? listAst.length : 0);
  const [checkAnyRule, checkAllRules] = [type == "any", type == "all"];

  useEffect(() => {
    const list = listRef ? listRef.current.state.list : [];

    if (list.length === 0) {
      buttonAddListRef.current.click();
    }

    dispatch({ type: "SET_RULES_LIST", payload: rules });
  }, []);

  useEffect(() => {
    updateRulesAst()
  }, [type]);

  return (
    <Container>
      <div className="row g-0">
        <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon usePopover>
          <Tab
            className="col-md-6"
            active={isDefault}
            borderLeft={false}
            onClick={() => updateDefault(true)}
          >
            <div className="m-3 d-flex justify-content-sm-center">
              <label className="form-check form-switch mb-0">
                <input className="form-check-input cursor-pointer"
                  type="checkbox"
                  checked={isDefault}
                  onChange={() => {}}
                />
                <span className="form-check-label cursor-pointer">
                  {I18n.t("views.manage_flows.script_editor.display_this_flow_on_all_site_pages")}
                </span>
              </label>
            </div>
          </Tab>
        </PermissionsGate>

        <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon usePopover>
          <Tab
            className="col-md-6"
            active={!isDefault}
            borderLeft={true}
            onClick={() => updateDefault(false)}
          >
            <div className="m-3 d-flex justify-content-sm-center">
              <label className="form-check form-switch mb-0">
                <input className="form-check-input cursor-pointer"
                  type="checkbox"
                  checked={!isDefault}
                  onChange={() => updateDefault(false)}
                />
                <span className="form-check-label cursor-pointer">
                  {I18n.t("views.manage_flows.script_editor.customize_pages_and_campaigns")}
                </span>
              </label>
            </div>
          </Tab>
        </PermissionsGate>
      </div>

      <div className="card-body" style={{ display: isDefault ? "none" : "block" }}>
        <div className="d-flex flex-column flex-sm-row mb-2">
          <label className="form-check form-check-inline">
            <input className="form-check-input cursor-pointer"
              type="radio"
              name="match-type"
              value="all"
              defaultChecked={checkAllRules}
              onChange={() => setType("all")}
            />

            <span className="form-check-label d-flex cursor-pointer">
            {I18n.t("views.manage_flows.script_editor.pages_that_obey_all_the_rules")}
              <div className="d-none d-md-flex justify-content-center align-items-center ms-2">
                <span className="form-help" data-bs-toggle="popover" data-bs-html="true" data-bs-trigger="hover"
                  data-bs-content= { I18n.t("views.manage_flows.script_editor.all_the_rules_popover_html")}
                >?</span>
              </div>
            </span>
          </label>

          <label className="form-check form-check-inline">
            <input className="form-check-input cursor-pointer"
              type="radio"
              name="match-type"
              value="any"
              defaultChecked={checkAnyRule}
              onChange={() => setType("any")}
            />
            <span className="form-check-label d-flex cursor-pointer">
              {I18n.t("views.manage_flows.script_editor.pages_that_obey_any_rule")}
              <div className="d-none d-md-flex justify-content-center align-items-center ms-2">
                <span className="form-help" data-bs-toggle="popover" data-bs-html="true" data-bs-trigger="hover"
                  data-bs-content={I18n.t("views.manage_flows.script_editor.any_rule_popover_html")}
                >?</span>
              </div>
            </span>
          </label>
        </div>

        <ListEditor
          ref={listRef}
          list={rules}
          component={RuleEditor}
          rulesCount={countRules}
          updateRulesCount={updateRulesCount}
          defaultElementData={{ kind: "v1_match", rule: "" }}
          dynamicElementData={type}
          onChange={updateRulesAst}
        />

        <div className="mt-3 text-center">
          {countRules < 45 && (
            <PermissionsGate scope={PERMISSION_SCOPE} useLockIcon iconCentered iconSmall usePopover>
              <button
                className="btn btn-primary"
                ref={buttonAddListRef}
                onClick={() => handleClickAddNewRole()}
              >
                <i className="ti ti-plus icon"></i>
                {I18n.t("views.manage_flows.script_editor.add_rule")}
              </button>
            </PermissionsGate>
          )}
          {countRules >= 45 && (
            <button
              className="btn btn-primary d-block mx-auto"
              disabled={true}
              data-bs-toggle="tooltip"
              title= {I18n.t("views.manage_flows.script_editor.cannot_add_new_rules")}
            >
              <i className="ti ti-plus icon"></i>
              {I18n.t("views.manage_flows.script_editor.add_rule")}
            </button>
          )}
        </div>
      </div>
    </Container>
  );
});

export default RulesEditor;
