import React, {useState} from "react";

import parse from "html-react-parser";

import Modal from "@/components/utils/Modal";
import ScheduleDemo from "./index";

import { Button } from "./styles";

const ModalScheduling = ({ dataLeadScheduling, scheduleDemo }) => {
  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => {
    setShowModal(true);
  }

  const handleModalClose = () => {
    setShowModal(false);
  }

  return (
    <>
      <div className="alert alert-important bg-primary-lt mb-0 rounded-0 py-2 text-center">
        <span>{parse(I18n.t("views.onboard.hello.scheduling_demo.alert_html"))}</span>
        <Button
          className="btn btn-primary py-1"
          type="button"
          onClick={handleModalOpen}
        >
          <i className="ti ti-message-2-star" />
          {I18n.t("views.onboard.hello.scheduling_demo.btn_talk_specialist")}
        </Button>
      </div>

      {showModal && (
        <Modal onClose={handleModalClose}>
          <ScheduleDemo dataLeadScheduling={dataLeadScheduling} scheduleDemo={scheduleDemo}/>
        </Modal>
      )}
    </>
  )
}

export default ModalScheduling;
