import React from "react";

import TidioButton from "./TidioButton";
import WhatsAppButton from "./WhatsAppButton";

import useCurrentUserStore from "@/store/currentUserStore";

const HireFeatureButton = () => {
  const { isNational } = useCurrentUserStore((state) => state.currentUser);

  const ContactButton = isNational ? WhatsAppButton : TidioButton;

  return (
    <ContactButton>
      <i className="ti ti-shopping-cart icon"></i>
      {I18n.t("views.manage_flows.scheduler_block.hire_feature")}
    </ContactButton>
  );
};

export default HireFeatureButton;
