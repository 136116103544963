import React, { useEffect, useState, useCallback } from 'react';
import axios from "packs/axios";

import LineChartCard from '../LineChartCard';
import LineChartOptionsPercentage from '../LineChartCard/LineChartOptionsPercentage';

const MainAnalytics = () => {
  const [generatedLeads, setGeneratedLeads] = useState(0);
  const [generatedLeadsChart, setGeneratedLeadsChart] = useState([]);

  const [pageViews, setPageViews] = useState(0);
  const [pageViewsChart, setPageViewsChart] = useState([]);

  const [openChat, setOpenChat] = useState(0);
  const [openChatChart, setOpenChatChart] = useState([]);

  const [completedLeads, setCompletedLeads] = useState("0%");
  const [completedLeadsChart, setCompletedLeadsChart] = useState([]);

  const [whatsapp, setWhatsapp] = useState(0);
  const [whatsappChart, setWhatsappChart] = useState([]);
  const [whatsappPercentage, setWhatsappPercentage] = useState("0%")
  const [whatsappVisible, setWhatsappVisible] = useState(localStorage.getItem('is_whatsapp') || true)

  const filterGeneral = () => {
    const dateRange = $('#date-range').val();
    const splitedDateRange = dateRange.split(' - ');
    const startDate = splitedDateRange[0];
    const endDate = splitedDateRange[1];
    const scriptId = $("#analytics-script").val();
    const mobile = $("input[name=mobile]:checked").val();
    const score = $('input[name="lead_score"]:checked').val();

    const dataFilter = {
      start_date: startDate,
      end_date: endDate,
      script_id: scriptId,
      mobile,
      score,
    };

    const dataFilterWhat = {
      start_date: startDate,
      end_date: endDate,
      date_request: {
        company_id: null
      }
    }

    if (scriptId != "all") {
      dataFilterWhat["date_request"]["script_id"] = scriptId;
    }

    return { dataFilter, dataFilterWhat }
  }

  const updateDataWhat = useCallback(async (dataFilter) => {
    await axios.post('/dashboard-metrics/field_blocks', dataFilter)
      .then((response) => {
        localStorage.removeItem('is_whatsapp');
        localStorage.setItem('is_whatsapp', response.data.is_whatsapp);
        setWhatsapp(response.data.total_actions);
        setWhatsappChart(response.data.coordinates);
        setWhatsappPercentage(response.data.total_percentage);
        setWhatsappVisible(response.data.is_whatsapp);
      })
      .catch(() => {
        setWhatsappVisible(localStorage.getItem('is_whatsapp') || true)
      })

  });

  const updateData = useCallback(async (dataFilter) => {
    const response = await axios.get('/dashboard-metrics/main', { params: dataFilter });
    const { data } = response;

    setGeneratedLeads(data.generated_leads);
    setCompletedLeads(data.completed_leads_percentage);
    setPageViews(data.page_views);
    setOpenChat(data.open_chat);

    setGeneratedLeadsChart(data.generated_leads_chart);
    setCompletedLeadsChart(data.completed_leads_chart);
    setPageViewsChart(data.page_views_chart);
    setOpenChatChart(data.open_chat_chart);
  });

  const updateGeneral = async () => {
    const { dataFilter, dataFilterWhat } = filterGeneral();
    updateData(dataFilter);
    updateDataWhat(dataFilterWhat);
  }

  useEffect(() => {
    $('#analytics-script').on('change', () => updateGeneral());
    $('input[name="lead_score"]').on('change', () => updateGeneral());
    $("input[name=mobile]").on('change', () => updateGeneral());
    $('#date-range').on('change', () => updateGeneral());
    updateGeneral()
  }, []);

  return (
    <div className="row row-deck row-cards mt-1">
      <div className="col-6 col-md-3">
        <LineChartCard
          data={generatedLeadsChart}
          value={generatedLeads}
          label={I18n.t("views.dashboard.all_access_info.generated_leads")}
        />
      </div>

      <div className="col-6 col-md-3">
        <LineChartCard
          data={completedLeadsChart}
          value={completedLeads}
          options={LineChartOptionsPercentage}
          label={I18n.t("views.dashboard.all_access_info.complete_leads")}
        />
      </div>

      {(whatsappVisible) ?(
        <div className="col-6 col-md-3">
          <LineChartCard
            data={whatsappChart}
            value={whatsapp}
            percentage={whatsappPercentage}
            label={I18n.t("views.dashboard.all_access_info.leads_for_whatsapp")}
          />
        </div>
      ) : (
        <div className="col-6 col-md-3">
          <LineChartCard
            data={pageViewsChart}
            value={pageViews}
            label={I18n.t("views.dashboard.all_access_info.pages_viewed")}
          />
        </div>
      )}

      <div className="col-6 col-md-3">
        <LineChartCard
          data={openChatChart}
          value={openChat}
          label={I18n.t("views.dashboard.all_access_info.chat_openings")}
        />
      </div>
    </div>
  );
};

export default MainAnalytics;
