import React, { useState, useCallback } from "react";

import PermissionsGate from "@/components/permissions/PermissionsGate";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { can } from "@/permissions-provider";

import axios from "packs/axios";
import swal from "sweetalert";

const PERMISSION_SCOPE = Object.freeze({
  action: "manage",
  subject: "script_flow",
});

const ScriptSwitchAvailability = ({ scriptId, isEnabled, alertDisabled }) => {
  const hasPermission = can(PERMISSION_SCOPE);

  const [enabled, setEnabled] = useState(isEnabled);

  const handleAvailability = useCallback((e) => {
    if (!hasPermission) return;

    const value = e.target.checked;
    const action = value ? "enable" : "disable";
    const url = `/scripts/${scriptId}/${action}`;
    const alertLabel = value
      ? I18n.t("manage_flow.script_editor.enabled")
      : I18n.t("manage_flow.script_editor.disable");

    MixpanelService.track("script_switch_availability", { "Habilitado?": value });

    setEnabled(value);
    swal("", `${alertLabel} ${I18n.t("manage_flow.script_editor.successfully")}`, "success");

    axios.get(url);
  }, []);

  return (
    <OverlayTrigger
      defaultShow={alertDisabled && !enabled}
      placement="bottom"
      trigger={enabled ? false : ["hover", "focus"]}
      overlay={<Tooltip>{I18n.t("manage_flow.script_editor.your_flow_is_disabled")}</Tooltip>}
    >
      <div>
        <PermissionsGate scope={PERMISSION_SCOPE} usePopover>
          <label className="form-check form-check-single form-switch ps-0">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              disabled={!hasPermission}
              checked={!!enabled}
              onChange={handleAvailability}
            />
          </label>
        </PermissionsGate>
      </div>
    </OverlayTrigger>
  );
};

export default ScriptSwitchAvailability;
