import React from "react";
import axios from "../../packs/axios";
import swal from "sweetalert";

export default class PaymentBankSlip extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    window.addEventListener("message", (event) => {
      if(event.data.kind === "finished"){

        axios.post('/payments/save_bank_slip_data', {
          magic_key: this.props.magic_key,
          chat_data: event.data.data,
          company_id: this.props.companyId,
          plan_price_id: this.props.plan["id"],
        }).then(function(response){
          swal("", I18n.t("billing.notification.payment_saved_successfully"), "success");
          
          location.href = "/billing/success"
        }).catch(function (error) {
          swal("", I18n.t("billing.notification.error_processing_payment") + " " + error.response.data.error, "error");
          return false;
        });
      }
    }, false);
  }

  render() {
    return (
      <iframe src={this.props.chatUrl} frameBorder="0" width="100%" height="100%"/>
    );
  }
}
