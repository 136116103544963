import React from "react";

import useCurrentUserStore from "@/store/currentUserStore";

import parse from "html-react-parser";

const AllowedTrialAlert = ({ onDelete }) => {
  const { freeTrialDays } = useCurrentUserStore((state) => state.currentUser.plugins.leadsterAi);

  return (
    <div className="alert alert-primary mb-3">
      <div className="d-flex align-items-center">
        <div>
          <i className="ti ti-info-circle icon alert-icon fs-1" />
        </div>

        <div>
          <div className="alert-title mb-3">
            {I18n.t("views.manage_flows.script_editor.ia_interaction.alerts.title")}
            <span className="badge bg-primary-lt ms-2">
              {I18n.t("views.manage_flows.script_editor.ia_interaction.alerts.allowed_trial.status")}
            </span>
          </div>

          <p className="mb-1 text-muted">
            {parse(I18n.t("views.manage_flows.script_editor.ia_interaction.alerts.allowed_trial.description_html", {
              days: freeTrialDays,
            }))}
          </p>

          <a
            href={I18n.t("views.manage_flows.script_editor.ia_interaction.alerts.link_know_more")}
            className="mb-3 d-block"
            arget="_blank"
            rel="noopener noreferrer"
          >
            {I18n.t("shared.actions.click_to_learn_more")}
            <i className="ti ti-external-link ms-1" />
          </a>

          <div className="btn-list">
            <a className="btn btn-primary" href="/ai/leadster" target="_blank">
              <i className="ti ti-message-chatbot icon" />
              {I18n.t("views.manage_flows.script_editor.ia_interaction.create_ai_assistant")}
            </a>

            <button type="button" className="btn btn-outline-danger" onClick={onDelete}>
              <i className="ti ti-trash icon" />
              {I18n.t("views.manage_flows.script_editor.ia_interaction.alerts.btn_remove_block")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllowedTrialAlert;
