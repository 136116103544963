import React from "react";

import HireFeatureButton from "./HireFeatureButton";

import parse from "html-react-parser";

const NotAllowedAlert = ({ onDelete }) => {
  return (
    <div className="alert alert-danger mb-3">
      <div className="d-flex align-items-center">
        <div>
          <i className="ti ti-info-circle icon alert-icon fs-1" />
        </div>

        <div>
          <div className="alert-title mb-3">
            {I18n.t("views.manage_flows.script_editor.ia_interaction.alerts.title")}
            <span className="badge bg-danger-lt ms-2">
              {I18n.t("views.manage_flows.script_editor.ia_interaction.alerts.not_allowed.status")}
            </span>
          </div>

          <p className="text-muted">
            {parse(I18n.t("views.manage_flows.script_editor.ia_interaction.alerts.not_allowed.description_html"))}
          </p>

          <div className="btn-list">
            <HireFeatureButton />

            <button type='button' className="btn btn-outline-danger" onClick={onDelete}>
              <i className="ti ti-trash icon"></i>
              {I18n.t("views.manage_flows.scheduler_block.remove_block")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotAllowedAlert;
